import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Select from "../components/form/Select";
import Search from "../components/form/Search";
import Table from "../components/elements/table/Table";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../helpers";
import toastr from "toastr";
import CmsTab from "../shared/CmsTab";
import ManagePerformancePopup from "../components/popup/ManagePerformancePopup";

import { postData } from "../services/api";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const PerformanceAlt = () => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Performances"
  );
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("ordering");
  const [sortValue, setSortValue] = useState(1);
  const [sortQuery, setSortQuery] = useState("");
  const [status, setStatus] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [performance, setPerformance] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [actionValue, setActionValue] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const othersAction = {
    type: "button",
    link: "/",
    label: t("edit"),
    icon: "fa-pen-to-square",
    isVisabled: 2,
  };
  const [enabledPopup, setenabledPopup] = useState(false);
  const [editData, setEditData] = useState({});

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    const loadPosition = async () => {
      setLoading(true);
      try {
        const result = await postData("performance/list", {
          keyword: keyword,
          limit: limit,
          offset: offset,
          sortQuery: sortQuery,
          status: status,
        });
        if (result.data) {
          setPerformance(
            result.data.map((value, key) => {
              return {
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    type: "text",
                    width: 10,
                    data: value?.alt_name ? value?.alt_name : value?.name,
                  },
                  // { _id:2, type:'text', data:value?.point },
                  {
                    _id: 3,
                    type: "text",
                    data:
                      value && value?.created_at
                        ? moment(new Date(value?.created_at)).format("ll")
                        : "N/A",
                  },
                  {
                    _id: 4,
                    type: "status",
                    statusLabel:
                      value?.status && value?.status === 1
                        ? "Active"
                        : "Deactivated",
                    statusType:
                      value?.status && value?.status === 1
                        ? "success"
                        : "warning",
                  },
                  {
                    _id: 7,
                    type: "action",
                    width: 10,
                    statusLabel:
                      value?.status && value?.status === 1
                        ? t("active")
                        : t("deactivated"),
                    statusType:
                      value?.status && value?.status === 1
                        ? "success"
                        : "warning",
                    data: [{ others: 2 }],
                    isDropdoen: false,
                  },
                ],
              };
            })
          );
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadPosition();
  }, [keyword, limit, offset, sortQuery, status, isUpdate, actionValue]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
  };

  const tableHeadData = [
    {
      _id: 1,
      name: t("name"),
      value: "name",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    // {_id:2, name:'Point', value:'point', align:'left', isSort:false, isFilter:false},
    {
      _id: 3,
      name: "Created Date",
      value: "created_at",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 6,
      name: "Status",
      value: "status",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 7,
      name: t("action"),
      value: "",
      align: "right",
      isSort: false,
      isFilter: false,
    },
  ];

  const handleOnDragEnd = async (result) => {
    const items = reorder(
      performance,
      result.source.index,
      result.destination.index
    );
    setPerformance(items);
    const res = await postData("performance/ordering", {
      items: items,
    });
    if (res.status && res.status === 200) {
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  useEffect(() => {
    if (actionValue && actionValue.button_type === "others") {
      setenabledPopup(true);
      const index = performance.findIndex(
        (item) => item._id === actionValue?.row_id
      );
      if (index !== -1) {
        setEditData({
          ...actionValue,
          name: performance[index].rowData[0].data,
        });
      } else {
        setEditData(actionValue);
      }
    }
  }, [actionValue]);

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">
          {t("edit_performance_descriptors") + " - " + user?.company_name}
        </div>
      </div>
      <div className="relative flex justify-between items-center mb-4">
        {/* <div className="relative">
            <Select 
            xPlacement={'left'} 
            dropdownButtonClass={'!bg-white'} 
            selectedValue={limit} 
            dropdownData={[
                { _id: 1000000, label: 'All Items',value: 1000000 }, 
                ...[{ _id: 10, label: '10 Items', value: 10 },
                    { _id: 20, label: '20 Items', value: 20 },
                    { _id: 30, label: '30 Items', value: 30 },
                    { _id: 50, label: '50 Items', value: 50 },
                    { _id: 100, label: '100 Items', value: 100 }]
            ]}
            getSelectedValue={(e) => setLimit(e.value)}
            />
        </div> */}
        {/* <div className="relative ml-auto flex justify-end items-center gap-3">
            <div className="relative">
            <Search 
            placeholder={'Search'} 
            setInput={ setKeyword } 
            />
            </div>
            <div className="relative">
            <Select 
            xPlacement={'right'} 
            dropdownButtonClass={'!bg-white'} 
            transitionClass={'!w-auto'}
            selectedValue={status} 
            dropdownData={[
                { _id: '', label: 'All Status' },
                { _id: 1, label: 'Active', value: 1 },
                { _id: 2, label: 'Deactivated', value: 2 },
            ]}
            getSelectedValue={(e) => setStatus(e.value)}
            />
            </div>
        </div> */}
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="w-full bg-white rounded-md shadow overflow-hidden">
          <Table
            tableData={performance}
            tableHeadData={tableHeadData}
            totalRecord={totalDataCount}
            loading={loading}
            getSortValue={setSortValue}
            getSortKey={setSortKey}
            //editUrl={'/edit-performance'}
            tableImage={false}
            isStatusUpdate={false}
            isDeleteEnabled={false}
            isDraggable={false}
            getActionValue={(obj) => setActionValue(obj)}
            othersAction={othersAction}
          />
        </div>
      </DragDropContext>
      {noOfPage > 1 && (
        <div className="flex justify-center sm:justify-end my-4">
          <ReactPaginate
            breakLabel="..."
            breakClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            nextLabel={<i className="fa-regular fa-fw fa-chevron-right"></i>}
            nextClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={noOfPage}
            containerClassName="flex justify-center items-center text-slate-80 text-sm font-medium mt-8 lg:mt-0 space-x-2"
            activeClassName="hover:bg-teal-500 bg-teal-500 text-white"
            pageLinkClassName="flex justify-center items-center w-full h-full"
            previousLabel={<i className="fa-regular fa-fw fa-chevron-left"></i>}
            pageClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            previousClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            renderOnZeroPageCount={null}
          />
        </div>
      )}
      {enabledPopup && (
        <ManagePerformancePopup
          isOpen={enabledPopup}
          setIsOpen={(val) => setenabledPopup(val)}
          heading={t("manage_performance")}
          data={editData}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></ManagePerformancePopup>
      )}
    </>
  );
};

export default PerformanceAlt;
