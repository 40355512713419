import React,{useState,useEffect} from 'react';
import CompanyItem from "../../../Items/CompanyItem";
import Caltech from "../../../../assets/image/CEFCUlogo.svg"
import Useagle from "../../../../assets/image/useagle.svg"
import Merrymackvalley from "../../../../assets/image/merrymackvalley.svg"
import SSU from "../../../../assets/image/SCULogo.svg"
import Gecu from "../../../../assets/image/gecu.svg"
import Searching from '../../../Searching';
import NoDataFound from '../../../NoDataFound';

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "animate.css";

import { postData } from '../../../../services/api';
import { useTranslation } from "react-i18next";


const CompanySection = () => {
  const { i18n,t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [clients, setClientsData] = useState([]);

  useEffect(() => {   
    const clientsList = async () => {
      setLoading(true);
      try {
        const result = await postData("client/list", {
          status: 1,
          sortQuery:{ordering:1}
        });
        if (result.data) {
          setClientsData(result.data);
          // setClientsData(
          //   result.data.map((item,index)=>{
          //     return {
          //       image : item?.image
          //     }
          //   })
          // );
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    clientsList();
  }, []);
  const CompanyData = {
    title: t('Trusted by'),
    Company : clients
  }
  return (
    <section className="relative w-full py-10 bg-dark-teal-500" id="company-section">
      <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl  mx-auto">
        <div className="max-w-3xl w-full mx-auto text-center mb-5 ">
          <h2 className=" capitalize text-white  !leading-tight text-2xl xs:text-3xl lg:text-5xl xl:text-6xl  font-extrabold font-PlusJakartaSans" dangerouslySetInnerHTML={{__html:CompanyData.title}}></h2>
        </div>
      </div>  
        {/* <div className="w-full scroll-smooth scrollbar mb-4">
            {
              (clients.length>0)? (
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3">
                  {
                    clients.map((item,index) => {
                      return (
                        <a href={item?.link} target='_blank' rel="noopener noreferrer" key={index}>
                          <div className="relative w-full h-full bg-white border border-gray-200 rounded-lg shadow overflow-hidden">
                            <div className="flex flex-col items-center py-5 px-4 space-y-4">
                              <div className="w-16 h-16 overflow-hidden bg-slate-100 rounded-full relative flex items-center justify-center border border-white font-bold">
                                <img className="rounded-t-lg" src={item?.image} alt="" />
                              </div>
                              <div className="text-center">
                                <h5 className="text-sm md:text-base lg:text-lg font-bold text-gray-900 capitalize !leading-none">{item?.name}</h5>
                              </div>
                            </div>
                          </div>
                        </a>
                      );
                    })
                  }
                </div>
              ):(
                <div className="relative bg-white shadow rounded">
                  <div className="py-8 px-4 w-full">{loading ? <Searching label={t("searching")} /> : <NoDataFound label={t("no_board_member_found")} />}</div>
                </div>
              )
            }
          </div> */}
        <div className="relative  py-2 px-2 company">
            <Swiper
                spaceBetween={5}
                // slidesPerView={7}
                loop={true}
                // navigation={false}
                modules={[Navigation, Autoplay, EffectFade]}
                speed={5000}
                autoplay={{
                  delay: 0,
                  disableOnInteraction: false,
                }}
                loopAdditionalSlides={4}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  1280: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                  },
                  
                  1500: {
                    slidesPerView: 6,
                    spaceBetween: 10,
                  },
                }}
                className="w-full"
              >
                {CompanyData.Company.map((item) => {
                  return(
                    <SwiperSlide key={item?._id}>
                        <CompanyItem data={item}/>
                    </SwiperSlide>
                  )
                  })
                }
                
            </Swiper>
        </div>
      
  </section>
  );
};

export default CompanySection;