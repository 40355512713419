import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import ImagePopup from "../popup/ImagePopup";
import noLogo from "../../assets/image/images.jpeg";

const Image = ({
  src,
  width,
  height,
  alt,
  effect,
  classes,
  popupSize,
  id = "",
  isLogo = false,
  isPopup = true,
}) => {
  const [image, setImage] = useState("");
  // For Image Popup
  const [enabledImagePopup, setEnabledImagePopup] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  useEffect(() => {
    setImage(src);
  }, [src]);

  return (
    <>
      <LazyLoadImage
        src={isLogo ? noLogo : !image ? "/male-placeholder-image.jpeg" : image}
        width={width}
        height={height}
        alt={alt}
        effect={effect}
        className={"w-full h-full " + classes}
        id={id}
        onError={() => setImage("/male-placeholder-image.jpeg")}
        onClick={() => {
          if (isPopup) {
            setEnabledImagePopup(true);
            setCurrentImage(!image ? "/male-placeholder-image.jpeg" : image);
          }
        }}
      />
      {id === "header-logo" ? null : (
        <ImagePopup
          isOpen={enabledImagePopup}
          size={popupSize}
          setIsOpen={(val) => setEnabledImagePopup(val)}
          image={currentImage}
          getActionValue={(obj) => {
            setEnabledImagePopup(false);
            setCurrentImage("");
          }}
        />
      )}
    </>
  );
};

export default Image;
