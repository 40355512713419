import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
  Font,
} from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";
import moment from "moment/moment";
import { sumTotal } from "../../helpers";

import { useTranslation } from "react-i18next";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const OptionRatingHistoryReport = ({
  history,
  reatingOptions,
  title,
  headingTitle,
  user,
  companyLogo,
}) => {
  const { i18n, t } = useTranslation();
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: "RobotoRegular",
      lineHeight: 1.2,
      fontSize: 9,
      color: "#334155",
    },
    tableHeading: {
      fontSize: 12,
      width: "20%",
      maxWidth: "100%",
      padding: 8,
      backgroundColor: "#0f172a",
      color: "#ffffff",
    },
    tableData: {
      fontSize: 10,
      width: "20%",
      maxWidth: "100%",
      padding: 8,
    },

    image: {
      width: 150,
      height: 30,
      objectFit: "contain",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tableOtherData: {
      fontSize: 10,
      color: "#334155",
    },
    imageSignature: {
      width: 100,
      height: 60,
      objectFit: "contain",
    },
    section: { textAlign: "center" },
    profileTableHeading: {
      fontSize: 8,
      width: "14%",
      paddingHorizontal: 4,
      paddingVertical: 8,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    profileTableHeadingText: {
      width: "100%",
      fontFamily: "RobotoRegular",
      fontSize: 8,
      lineHeight: 1,
    },
    profileSubText: {
      fontSize: 8,
      marginTop: 2,
    },
    profileTableData: {
      fontSize: 9,
      width: "14%",
      padding: 4,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    profileTableText: {
      fontSize: 7,
    },
    tablePart: {
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",
    },
    developmentTableHeading: {
      fontSize: 8,
      width: "33.33%",
      paddingHorizontal: 8,
      paddingVertical: 8,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    developmentTableData: {
      fontSize: 9,
      width: "33.33%",
      padding: 4,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    developmentTableText: {
      fontSize: 7,
    },
    developmentTableHeadingText: {
      width: "100%",
      fontFamily: "RobotoRegular",
      fontSize: 10,
      lineHeight: 1,
    },
    readinessTableHeading: {
      fontSize: 10,
      width: "25%",
      padding: "8px 4px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    readinessTableHeadingText: {
      width: "100%",
      fontFamily: "RobotoRegular",
      fontSize: 10,
      lineHeight: 1,
    },
    readinessTableData: {
      fontSize: 10,
      width: "25%",
      padding: 4,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    readinessTableText: {
      fontSize: 9,
    },
    conclusionTableHeading: {
      fontSize: 12,
      width: "20%",
      padding: 5,
    },
    conclusionTableData: {
      fontSize: 10,
      width: "20%",
      fontSize: 10,
      padding: 5,
    },
    conclusionTableOtherData: {
      fontSize: 10,
    },
  });

  return (
    // <Document>
    <>
      <Page
        size="A4"
        orientation="landscape"
        style={[
          styles.page,
          {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          },
        ]}
      >
        <View style={[styles.section, { color: "white" }]}>
          <Text>Cover Page</Text>
        </View>
        <View
          style={{
            paddingBottom: 20,
            width: "100%",
            height: "90%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <View
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              width: 120,
              height: 24,
            }}
          >
            <Image style={{ objectFit: "contain" }} src={logo} />
          </View>
          <View
            style={{
              paddingBottom: 20,
              margin: "0 auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <View
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                fontSize: 16,

                // alignItems:"center",
                // position:'relative',
                // width:'100%',
              }}
            >
              <Image
                style={[
                  {
                    width: "400",
                    height: "40",
                    objectFit: "contain",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                ]}
                src={companyLogo}
                // src='https://successionnow.iosx.in/logo-succession-now.png'
              />
            </View>
            <View
              style={[
                styles.tableOther,
                { display: "flex", flexDirection: "row", marginTop: 15 },
              ]}
            >
              <Text
                style={[
                  styles.tableOtherData,
                  {
                    display: "flex",
                    flexDirection: "row",
                    fontFamily: "RobotoBold",
                    fontSize: 20,
                    textTransform: "capitalize",
                  },
                ]}
              >
                {user?.company_name}
              </Text>
            </View>
            <View
              style={[
                styles.tableOther,
                { display: "flex", flexDirection: "row", marginTop: 15 },
              ]}
            >
              <Text
                style={[
                  styles.tableOtherData,
                  {
                    display: "flex",
                    flexDirection: "row",
                    fontFamily: "RobotoMedium",
                    fontSize: 16,
                    textTransform: "capitalize",
                  },
                ]}
              >
                {title}
              </Text>
            </View>
          </View>
          <View
            style={[
              {
                position: "absolute",
                bottom: "0",
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                justifyContent: "center",
                marginTop: "auto",
              },
            ]}
          >
            <View
              style={[
                styles.tableOther,
                {
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 5,
                  textAlign: "center",
                  justifyContent: "center",
                  marginRight: 12,
                },
              ]}
            >
              <Text
                style={[
                  {
                    display: "flex",
                    flexDirection: "row",
                    fontFamily: "RobotoMedium",
                    fontSize: 10,
                    textAlign: "center",
                    fontSize: 10,
                  },
                ]}
              >
                Created by :
              </Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>
                {history && history.length > 0
                  ? history[0].created_info?.name
                  : ""}
              </Text>
            </View>
            <View
              style={[
                styles.tableOther,
                { display: "flex", flexDirection: "row", marginTop: 5 },
              ]}
            >
              <Text
                style={[
                  styles.tableOtherData,
                  {
                    display: "flex",
                    flexDirection: "row",
                    fontFamily: "RobotoMedium",
                    fontSize: 10,
                  },
                ]}
              >
                Date:
              </Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>
                {moment(new Date()).format("MM/DD/YYYY")}
              </Text>
            </View>
          </View>
        </View>
      </Page>
      {history &&
        history.length > 0 &&
        history.map((item, index) => {
          return (
            <Page
              size="A4"
              orientation="landscape"
              style={styles.page}
              key={index}
            >
              <View
                style={{
                  border: "1px solid #e2e8f0",
                  borderRadius: 6,
                  overflow: "hidden",
                  marginBottom: 30,
                }}
              >
                <View
                  style={[
                    styles.tableOther,
                    {
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      columnGap: 20,
                      padding: "10px 10px",
                      backgroundColor: "#f2f2f2",
                    },
                  ]}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "70%",
                    }}
                  >
                    <Text
                      style={[
                        styles.tableOtherData,
                        { fontFamily: "RobotoMedium", fontSize: 12 },
                      ]}
                    >
                      {item?.name}
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "30%",
                      gap: 5,
                    }}
                  >
                    <Text
                      style={[
                        styles.tableOtherData,
                        { fontFamily: "RobotoMedium", fontSize: 12 },
                      ]}
                    >
                      &nbsp;
                    </Text>
                    <Text
                      style={[
                        styles.tableOtherData,
                        {
                          fontFamily: "RobotoRegular",
                          fontSize: 12,
                          color: "#000",
                        },
                      ]}
                    >
                      {item && item?.created_at
                        ? moment(new Date(item?.created_at)).format("lll")
                        : "N/A"}
                    </Text>
                  </View>
                </View>
                {reatingOptions.length > 0 &&
                  reatingOptions.map((items, index) => {
                    let ratingRowAnswers = items?.question?.answers;
                    const optionRating =
                      item?.ratingOptions &&
                      item?.ratingOptions.filter(
                        (row) => row.questionId === items?.question?._id
                      );
                    let totalRating = 0;
                    let ansLabel = "";
                    let comment = "";
                    let _ans = [];
                    if (optionRating && optionRating.length > 0) {
                      totalRating = optionRating[0]?.rating;
                      ansLabel = optionRating[0]?.answerLabel;
                      comment = optionRating[0]?.comments;
                      _ans = optionRating[0]?.ids;
                    }
                    if (ratingRowAnswers && ratingRowAnswers.length > 0) {
                      ratingRowAnswers.forEach((obj) => {
                        if (obj?.company_id === user?._id) {
                          _ans.push(obj?._id);
                        }
                      });
                    }

                    return (
                      <View
                        style={{
                          borderTop: "1px solid #e2e8f0",
                          paddingHorizontal: 3,
                        }}
                      >
                        <View
                          key={index}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <View
                            style={[
                              styles.tableData,
                              {
                                width: "70%",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text
                              style={[
                                {
                                  whiteSpace: "nowrap",
                                  fontWeight: 500,
                                  width: "100%",
                                  fontSize: 10,
                                  lineHeight: 1,
                                  marginBottom: 3,
                                },
                              ]}
                            >
                              {Number(index + 1)}.{" " + items?.question?.name}
                            </Text>
                            {_ans && _ans.length > 0 && (
                              <View
                                style={{
                                  position: "relative",
                                  paddingLeft: 10,
                                  marginTop: 4,
                                }}
                              >
                                {[...new Set(_ans)].map((an, i) => {
                                  let rowData = ratingRowAnswers.filter(
                                    (row) => row._id === an
                                  );
                                  if (rowData && rowData.length > 0) {
                                    return (
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          gap: 2,
                                          marginBottom: 5,
                                        }}
                                      >
                                        <View
                                          style={{
                                            width: 4,
                                            height: 4,
                                            borderRadius: "50%",
                                            backgroundColor:
                                              "rgb(71,85,105,0.5)",
                                          }}
                                        ></View>
                                        <Text
                                          style={{
                                            color: "rgb(71,85,105,1)",
                                            fontSize: 9,
                                            lineHeight: 1,
                                          }}
                                        >
                                          {rowData[0]?.description}
                                        </Text>
                                      </View>
                                    );
                                  }
                                })}
                              </View>
                            )}
                            {comment && (
                              <Text
                                style={[
                                  {
                                    whiteSpace: "nowrap",
                                    fontWeight: 500,
                                    width: "100%",
                                    fontSize: 10,
                                    lineHeight: 1,
                                    paddingLeft: 10,
                                    color: "#64748b",
                                  },
                                ]}
                              >
                                {comment}
                              </Text>
                            )}
                          </View>

                          <View
                            key={index}
                            style={[
                              styles.tableData,
                              {
                                width: "30%",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text style={{ fontSize: 9 }}>
                              {(totalRating > 0 ? totalRating : "") +
                                (ansLabel ? " (" + ansLabel + ")" : "")}
                            </Text>
                          </View>
                        </View>
                      </View>
                    );
                  })}
              </View>
            </Page>
          );
        })}
      {/* <Page size="A4" orientation="landscape" style={styles.page}>
        <View
          style={{
            border: "1px solid #e2e8f0",
            borderRadius: 6,
            overflow: "hidden",
            marginBottom: 30,
          }}
        >
          {history &&
            history.length > 0 &&
            history.map((item, index) => {
              return (
                <>
                  <View
                    style={[
                      styles.tableOther,
                      {
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        columnGap: 20,
                        padding: "10px 10px",
                        backgroundColor: "#f2f2f2",
                      },
                    ]}
                    key={index}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "70%",
                      }}
                    >
                      <Text
                        style={[
                          styles.tableOtherData,
                          { fontFamily: "RobotoMedium", fontSize: 12 },
                        ]}
                      >
                        {item?.name}
                      </Text>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "30%",
                        gap: 5,
                      }}
                    >
                      <Text
                        style={[
                          styles.tableOtherData,
                          { fontFamily: "RobotoMedium", fontSize: 12 },
                        ]}
                      >
                        &nbsp;
                      </Text>
                      <Text
                        style={[
                          styles.tableOtherData,
                          {
                            fontFamily: "RobotoRegular",
                            fontSize: 12,
                            color: "#000",
                          },
                        ]}
                      >
                        {item && item?.created_at
                          ? moment(new Date(item?.created_at)).format("lll")
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </>
              );
            })}
        </View>
      </Page> */}
    </>
    // </Document>
  );
};

export default OptionRatingHistoryReport;
