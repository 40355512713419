import React,{useEffect,useRef, useState} from 'react';
import { Menu, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { bottom } from "@popperjs/core";
import { Link } from 'react-router-dom';
import Button from '../form/Button';
import DeletePopup from '../DeletePopup';

const ActionDiv = ({
  xPlacement,
  rowId,
  // userIds,
  editUrl,
  isStatusUpdate,
  isDeleteEnabled,
  // isStatusLabel,
  isStatusType,
  getActionValue = () => { },
  //isUser,
  //detailsUrl,
  //othersAction = () => { },
  data,
  //loginAs,
  extraData = [],
  ...prop
}) => {
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const [enabledDeletePopup, setenabledDeletePopup] = useState(false);
  const [currentId, setCurrentId] = useState('');
  const [extraArray,setExtraArray] = useState([]);

  const placements = {
    left: "bottom-start",
    right: "bottom-end",
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const dropdownData = [
    editUrl ? {
      _id: 1,
      label: 'Edit',
      icon: 'fa-pen-to-square',
      link: editUrl + '/' + rowId,
      type: 'link',
      standout: false,
      value: rowId,
      buttonType: '',
      ordering : 1
    } : '',    
    isStatusUpdate ?
      {
        _id: 5,
        label: (isStatusType === 'success') ? 'Deactivate' : 'Activate',
        icon: (isStatusType === 'success') ? 'fa-circle-exclamation' : 'fa-circle-check',
        link: '/',
        type: 'button',
        standout: false,
        value: rowId,
        buttonType: 'status',
        ordering : 9
      } : '',
    isDeleteEnabled ?
      {
        _id: 6,
        label: 'Delete',
        icon: 'fa-trash-can',
        link: '/',
        type: 'button',
        standout: true,
        value: rowId,
        buttonType: 'delete',
        ordering : 10
      } : ''
  ]
  useEffect(()=>{
    if(extraData && extraData.length>0){
      setExtraArray(
       extraData.map((item)=>{
          let vison = [];
          if(Array.isArray(item.isVisabled)){
            vison = data.filter(itm => item.isVisabled.includes(itm[item.buttonType]));
          }else{
            vison = data.filter(itm => itm[item.buttonType] === item.isVisabled);
          }        
          return {
            ...item,
            value : rowId,
            isVisabled : (vison.length>0)?true:false
          }
        }).filter(item => item.isVisabled)
      )
    }
  },[extraData])
  const fullDropdownArray = [...dropdownData,...extraArray].filter((item) => item !=='');

  
  

  const updateFunction = async (button_type, row_id, current_status) => {
    if (button_type === 'delete') {
      setenabledDeletePopup(true);
      setCurrentId(row_id)
    } else if (button_type === 'others') {
      getActionValue({
        button_type: button_type,
        row_id: row_id,
        current_status: current_status
      })
    } else {
      getActionValue({
        button_type: button_type,
        row_id: row_id,
        current_status: current_status
      })
    }
  }



  return (
    <>
      <Menu as="div">
        {({ open }) => (
          <>
            <Menu.Button ref={setTargetElement} className="flex items-center">
              <div className="relative w-full h-10 flex items-center justify-center focus:ring-0 transition-all duration-200">
                <div className="text-base text-slate-400 w-6 h-6 flex items-center justify-center">
                  <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
                </div>
              </div>
            </Menu.Button>
            <Transition
              show={open}
              leave="transition duration-75" leaveFrom="opacity-100" leaveTo="opacity-0" className="z-50"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}
            >
              <Menu.Items className="w-24 origin-right bg-white border-0 divide-y divide-slate-100 rounded-md shadow ring-0 ">
                <div className="py-1 max-h-80 divide-y divide-slate-100 overflow-auto scroll-smooth scrollbar">
                  {fullDropdownArray.sort((a, b) => a.ordering - b.ordering).map((item, index) => (
                    <React.Fragment key={index}>
                      {item.type === 'link' ?
                        <Menu.Item as="div">
                          <Link to={item.link} className={"flex items-center py-2 px-3 gap-1 font-medium transition-all duration-200 " + (item.standout ? "text-rose-500 hover:bg-rose-100" : "text-slate-600 hover:bg-slate-100")}>
                            <div className="w-5 h-5 flex items-center justify-center text-base">
                              <i className={"fa-regular fa-fw " + (item.icon)}></i>
                            </div>
                            <div className="text-xs">{item.label}</div>
                          </Link>
                        </Menu.Item> : ''
                      }

                      {item.type === 'button' ?
                        <Menu.Item as="div">
                          <Button
                            buttonLabel={item?.label}
                            buttonClasses={`!w-full !flex !items-center !justify-start !py-2 !px-3 !gap-1 !rounded-none !text-base !font-medium !bg-white ${item.standout ? '!text-rose-500 hover:!bg-rose-100' : '!text-slate-600 hover:!bg-slate-100'}`}
                            buttonType={'button'}
                            buttonIcon={
                              `${(item.buttonType && item.buttonType === 'status') ? 'fa-regular ' + item?.icon : ''}
                               ${(item.buttonType && item.buttonType === 'delete') ? 'fa-regular ' + item?.icon : ''}
                               ${(item.buttonType && item.buttonType) ? 'fa-regular ' + item?.icon : ''}`
                            }
                            buttonIconPosition={'left'}
                            buttonHasLink={false}
                            buttonLabelClasses={'!text-xs !font-semibold'}
                            functions={() => updateFunction(item?.buttonType, item?.value, (isStatusType === 'success') ? 2 : 1)}
                          // buttonDisabled={
                          //   `${(item.buttonType && item.buttonType === 'status')?isStatus:''} 
                          //    ${(item.buttonType && item.buttonType === 'delete')?isDelete:''}`
                          // }
                          />
                        </Menu.Item> : ''
                      }
                    </React.Fragment>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      {
        enabledDeletePopup &&
        <DeletePopup
          isOpen={enabledDeletePopup}
          setIsOpen={(val) => setenabledDeletePopup(val)}
          currentId={currentId}
          getActionValue={(obj) => {
            getActionValue(obj)
            setenabledDeletePopup(false);
            setCurrentId('')
          }}
        />
      }
    </>
  );
};

export default ActionDiv;