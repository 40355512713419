import React from "react";

const PositionCompetenciesSkelton = () => {
  return (
      <div className="relative p-4 ">
        <div className="flex justify-between items-center px-5 py-2  cursor-pointer  bg-slate-300 rounded-md shimmer">
          <div className="w-3/4 h-8 bg-slate-100  rounded"></div>
          <div className="w-10 h-10 bg-slate-100  rounded-full"></div>
        </div>
        <div className="flex justify-between items-center px-5 py-2  cursor-pointer  bg-slate-300 mt-4 rounded-md shimmer">
          <div className="w-3/4 h-8 bg-slate-100  rounded"></div>
          <div className="w-10 h-10 bg-slate-100  rounded-full"></div>
        </div>
        <div className="flex justify-between items-center px-5 py-2  cursor-pointer  bg-slate-300 mt-4 rounded-md shimmer">
          <div className="w-3/4 h-8 bg-slate-100  rounded"></div>
          <div className="w-10 h-10 bg-slate-100  rounded-full"></div>
        </div>
        <div className="flex justify-between items-center px-5 py-2  cursor-pointer  bg-slate-300 mt-4 rounded-md shimmer">
          <div className="w-3/4 h-8 bg-slate-100  rounded"></div>
          <div className="w-10 h-10 bg-slate-100  rounded-full"></div>
        </div>
        <div className="flex justify-between items-center px-5 py-2  cursor-pointer  bg-slate-300 mt-4 rounded-md shimmer">
          <div className="w-3/4 h-8 bg-slate-100  rounded"></div>
          <div className="w-10 h-10 bg-slate-100  rounded-full"></div>
        </div>
        <div className="flex justify-between items-center px-5 py-2  cursor-pointer  bg-slate-300 mt-4 rounded-md shimmer">
          <div className="w-3/4 h-8 bg-slate-100  rounded"></div>
          <div className="w-10 h-10 bg-slate-100  rounded-full"></div>
        </div>
        <div className="flex justify-between items-center px-5 py-2  cursor-pointer bg-slate-300 mt-4 rounded-md shimmer">
          <div className="w-3/4 h-8 bg-slate-100  rounded"></div>
          <div className="w-10 h-10 bg-slate-100  rounded-full"></div>
        </div>
      </div>
  );
};

export default PositionCompetenciesSkelton;
