import React, { useState, useEffect } from "react";
// import { setTitle } from "../../helpers/MetaTag";
import { setTitle } from "../../helpers/MetaTag";
import Button from "../../components/form/Button";
import Textarea from "../../components/form/Textarea";
import Input from "../../components/form/Input";
import Select from "../../components/form/Select";
import Image from "../../components/elements/Image";
import Checkbox from "../../components/form/Checkbox";
import ButtonFile from "../../components/form/ButtonFile";

import toastr from "toastr";
import { useNavigate } from "react-router";

import { allowedImageExtensions, languages } from "../../helpers";
import { API_URL } from "../../config/host";
import { postData } from "../../services/api";
import { useTranslation } from "react-i18next";

const SiteSettings = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Site Settings");
  const { i18n, t } = useTranslation();
  const allLang = languages();
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [altPhone, setAltPhone] = useState("");
  const [address, setAddress] = useState("");
  const [about, setAbout] = useState({});
  const [logo, setImage] = useState("");

  const [showLogo, setShowLogo] = useState("");

  const [isManage, setIsManage] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [cname, setCname] = useState("");
  const [CRight, setCRight] = useState("");
  const [DemoRequestSubTitle, setDemoRequestSubTitle] = useState({});
  const [secondThoughtsTitle, setSecondThoughtsTitle] = useState({});
  const [secondThoughtsDescription, setSecondThoughtsDescription] = useState({});
  const [businessFeatureDescription, seBusinessFeatureDescription] = useState({});
  const [leadershipDescription, setLeadershipDescription] = useState({});
  const [leadershipTitle, setLeadershipTitle] = useState({});
  const [tax, setTax] = useState("");
  const [taxType, setTaxType] = useState("");

  const [enabledHowItWorks, setEnabledHowItWorks] = useState(false);
  const [enabledResource, setEnabledResource] = useState(false);
  //const [enabledPlanTutorial,setPlanTutorial] =  useState(false);

  useEffect(() => {
    const siteDetails = async () => {
      try {
        const result = await postData("setting/details", {});
        if (result.data) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    siteDetails();
  }, []);

  useEffect(() => {
    setId(details?._id);
    setName(details?.name);
    setEmail(details?.email);
    setMobile(details?.phone);
    setCRight(details?.copyright);
    //setImage(details?.logo)
    setAltPhone(details?.alt_phone);
    setAddress(details?.address);
    setAbout(details?.about);
    setDemoRequestSubTitle(details?.demo_request_sub_title);
    if (details?.logo) {
      setShowLogo(API_URL + details?.logo);
    }
    setTax(details?.tax);
    setTaxType(details?.tax_type);
    setEnabledHowItWorks(details?.enabled_how_it_work);
    setEnabledResource(details?.enabled_resource);
    setSecondThoughtsTitle(details?.second_thoughts_title);
    setSecondThoughtsDescription(details?.second_thoughts_description);
    seBusinessFeatureDescription(details?.future_business_description);
    setLeadershipTitle(details?.leadership_title);
    setLeadershipDescription(details?.leadership_description);
    //setPlanTutorial(details?.enabled_plan_tutorial)
  }, [details]);

  // On Submit edit && Update
  const onSubmit = async (e) => {
    e.preventDefault();
    setIsManage(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("phone", mobile);
    formData.append("email", email);
    formData.append("alt_phone", altPhone);
    formData.append("address", address);
    formData.append("copyright", CRight);
    formData.append("tax", tax);
    formData.append("tax_type", taxType);
    formData.append("enabled_how_it_work", enabledHowItWorks);
    formData.append("enabled_resource", enabledResource);
    for (const key in about) {
      if (Object.hasOwnProperty.call(about, key)) {
        formData.append("about[" + key + "]", about[key]);
      }
    }
    for (const key2 in DemoRequestSubTitle) {
      if (Object.hasOwnProperty.call(DemoRequestSubTitle, key2)) {
        formData.append("demo_request_sub_title[" + key2 + "]", DemoRequestSubTitle[key2]);
      }
    }
    for (const key3 in secondThoughtsTitle) {
      if (Object.hasOwnProperty.call(secondThoughtsTitle, key3)) {
        formData.append("second_thoughts_title[" + key3 + "]", secondThoughtsTitle[key3]);
      }
    }
    for (const key4 in secondThoughtsDescription) {
      if (Object.hasOwnProperty.call(secondThoughtsDescription, key4)) {
        formData.append("second_thoughts_description[" + key4 + "]", secondThoughtsDescription[key4]);
      }
    }
    for (const key5 in businessFeatureDescription) {
      if (Object.hasOwnProperty.call(businessFeatureDescription, key5)) {
        formData.append("future_business_description[" + key5 + "]", businessFeatureDescription[key5]);
      }
    }
    for (const key6 in leadershipTitle) {
      if (Object.hasOwnProperty.call(leadershipTitle, key6)) {
        formData.append("leadership_title[" + key6 + "]", leadershipTitle[key6]);
      }
    }
    for (const key7 in leadershipDescription) {
      if (Object.hasOwnProperty.call(leadershipDescription, key7)) {
        formData.append("leadership_description[" + key7 + "]", leadershipDescription[key7]);
      }
    }
    formData.append("logo", logo);
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "/setting/update";
        formData.append("id", id);
        payload = formData;
      } else {
        path = "/setting/create";
        payload = formData;
      }
      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsManage(false);
        return navigate("/site-settings");
      } else {
        toastr.error(result.message);
        setIsManage(false);
      }
    } catch (err) {
      console.log(err.message);
      setIsManage(false);
    }
  };

  // Cancel Button
  const onCancel = async (e) => {
    setIsCancel(true);
    if (id) {
      setName(details?.name);
      setEmail(details?.email);
      setMobile(details?.mobile);
    } else {
      setName("");
      setEmail("");
      setMobile("");
    }
    setIsCancel(false);
  };

  const onImgChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];
      if (![...allowedImageExtensions()].includes(ext)) {
        toastr.error("Please upload a valid logo");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        setImage(e.target.files[0]);
      }
    }
  };

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex justify-between items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4">
          <div className="text-2xl font-bold">{id ? t("edit_site_settings") : t("add_site_settings")}</div>
      </div>

      <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-3 border-r border-slate-200">
            <div className="p-2 2xl:p-4">
              <div className="py-6 px-6 flex flex-col space-y-4 relative">
                <div className="relative group w-full aspect-1 border-2  mx-auto overflow-hidden rounded-full border-dark-teal-500">\
                  <Image src={showLogo} alt={"Admin"} width={"100%"} height={"100%"} effect={"blur"} classes={"object-contain"} id={"image1"} />
                </div>
              </div>
              <div className="divide-x divide-slate-200 flex items-center justify-center rounded-b border-t border-slate-200 pt-5">
                <ButtonFile buttonType={"button"} buttonIcon={"fa-light fa-image"} buttonIconPosition={"left"} buttonLabel={t("upload_logo")}  functions={onImgChange} accepts={"image/*"} />
              </div>
            </div>  
          </div>
          <div className="col-span-12 lg:col-span-9 flex flex-col">
                <div className="space-y-4">
                      <div className="relative w-full">
                        <Input label={t("site_name")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_site_name")} inputValue={name} setInput={setName} />
                      </div>
                      <div className="grid sm:grid-cols-3 gap-4">
                        <div className="relative w-full">
                          <Input label={t("email")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("email")} inputValue={email} setInput={setEmail} />
                        </div>
                        <div className="relative w-full">
                          <Input label={t("mobile_no")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_mobile_no")} inputValue={mobile} setInput={setMobile} />
                        </div>
                        <div className="relative w-full">
                          <Input label={t("mobile_no_in")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_mobile_no")} inputValue={altPhone} setInput={setAltPhone} />
                        </div>
                      </div>
                      <div className="flex items-start gap-4">
                        <div className="relative w-full">
                          <Input label={t("address")} labelClasses={"!text-xs "} inputType={"text"} inputPlaceholder={t("enter_address")} inputValue={address} setInput={setAddress} />
                        </div>
                      </div>
                      <div className="flex items-start gap-4">
                        <div className="relative w-full">
                          <Input label={t("copyright")} labelClasses={"!text-xs "} inputType={"text"} inputPlaceholder={t("give_copyright")} inputValue={CRight} setInput={setCRight} />
                        </div>
                      </div>
                      <ul className="flex flex-wrap text-sm font-medium text-center border-b-4 border-slate-100 gap-4" id="default-tab" data-tabs-toggle="#default-tab-content" role="tablist">
                        {allLang &&
                          allLang.length > 0 &&
                          allLang.map((item, index) => (
                            <li className="" role="presentation" key={index}>
                              <button className={"inline-block py-2 border-b-4 rounded-t-lg transition-all duration-200 -mb-1 " + (index === activeTab ? "text-teal-500 hover:text-teal-500 border-teal-500" : "text-slate-300 border-slate-100 hover:text-slate-400 hover:border-slate-300")} id={"tab-" + item?.value} data-tabs-target={"#" + item?.value} type="button" role="tab" aria-controls={item?.value} aria-selected="false" onClick={() => setActiveTab(index)}>
                                {item?.label}
                              </button>
                            </li>
                          ))}
                      </ul>
                      <div id="default-tab-content">
                        {allLang &&
                          allLang.length > 0 &&
                          allLang.map((item, index) => (
                            <div className={index === activeTab ? "show" : "hidden"} id={item?.value} role="tabpanel" aria-labelledby={"tab-" + item?.value} key={index}>
                              <div className="flex items-start gap-4 mb-3">
                                <div className="relative w-full">
                                  <Textarea
                                    label={t("about_succession")}
                                    labelClasses={"!text-xs"}
                                    inputValue={about?.[item?.value]}
                                    inputPlaceholder={t("tell_something_about_succession")}
                                    setTextarea={(val) =>
                                      setAbout({
                                        ...about,
                                        [item?.value]: val,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="flex items-start gap-4">
                                <div className="relative w-full">
                                  <Input
                                    label={t("demo_request_sub_title")}
                                    labelClasses={"!text-xs "}
                                    inputType={"text"}
                                    inputPlaceholder={t("enter_demo_request_sub_title")}
                                    inputValue={DemoRequestSubTitle?.[item?.value]}
                                    setInput={(val) =>
                                      setDemoRequestSubTitle({
                                        ...DemoRequestSubTitle,
                                        [item?.value]: val,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <hr className="mt-3 mb-3" />
                              <div className="font-medium text-slate-500 !text-xs mb-3">{t("second_thoughts_dont_worry")}</div>
                              <div className="flex items-start gap-4 mb-3">
                                <div className="relative w-full">
                                  <Input
                                    label={t("title")}
                                    labelClasses={"!text-xs "}
                                    inputType={"text"}
                                    inputPlaceholder={t("enter_title")}
                                    inputValue={secondThoughtsTitle?.[item?.value]}
                                    setInput={(val) =>
                                      setSecondThoughtsTitle({
                                        ...secondThoughtsTitle,
                                        [item?.value]: val,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="flex items-start gap-4 mb-3">
                                <div className="relative w-full">
                                  <Textarea
                                    label={t("description")}
                                    labelClasses={"!text-xs"}
                                    inputValue={secondThoughtsDescription?.[item?.value]}
                                    inputPlaceholder={t("enter_details")}
                                    setTextarea={(val) =>
                                      setSecondThoughtsDescription({
                                        ...secondThoughtsDescription,
                                        [item?.value]: val,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <hr className="mt-3 mb-3" />
                              <div className="font-medium text-slate-500 !text-xs mb-3">{t("secure_your_business_future")}</div>
                              <div className="flex items-start gap-4 mb-3">
                                <div className="relative w-full">
                                  <Textarea
                                    label={t("description")}
                                    labelClasses={"!text-xs"}
                                    inputValue={businessFeatureDescription?.[item?.value]}
                                    inputPlaceholder={t("enter_details")}
                                    setTextarea={(val) =>
                                      seBusinessFeatureDescription({
                                        ...businessFeatureDescription,
                                        [item?.value]: val,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <hr className="mt-3 mb-3" />
                              <div className="font-medium text-slate-500 !text-xs mb-3">{t("leadership_plan")}</div>
                              <div className="flex items-start gap-4 mb-3">
                                <div className="relative w-full">
                                  <Input
                                    label={t("title")}
                                    labelClasses={"!text-xs "}
                                    inputType={"text"}
                                    inputPlaceholder={t("enter_title")}
                                    inputValue={leadershipTitle?.[item?.value]}
                                    setInput={(val) =>
                                      setLeadershipTitle({
                                        ...leadershipTitle,
                                        [item?.value]: val,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="flex items-start gap-4 mb-3">
                                <div className="relative w-full">
                                  <Textarea
                                    label={t("description")}
                                    labelClasses={"!text-xs"}
                                    inputValue={leadershipDescription?.[item?.value]}
                                    inputPlaceholder={t("enter_details")}
                                    setTextarea={(val) =>
                                      setLeadershipDescription({
                                        ...leadershipDescription,
                                        [item?.value]: val,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      <hr />
                      <div className="font-medium text-slate-500 !text-xs">{t("tax_charge")}</div>
                      <div className="flex items-start gap-4">
                        <div className="relative w-full">
                          <Input label={t("utah_rate")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("utah_rate")} inputValue={tax} setInput={setTax} />
                        </div>
                        <div className="relative w-full">
                          <Select
                            label={t("tax_type")}
                            labelClasses={"!text-xs"}
                            xPlacement={"left"}
                            selectedValue={taxType}
                            dropdownClass={"!w-full"}
                            dropdownData={[
                              { _id: " ", label: t("select_type"), value: "" },
                              // { _id: 1, label: 'Fixed', value: 1 },
                              { _id: 2, label: t("percentage"), value: 2 },
                            ]}
                            getSelectedValue={(e) => setTaxType(e._id)}
                          />
                        </div>
                      </div>
                      <div className="font-medium text-slate-500 !text-xs">{t("enabled_section")}</div>
                      <div className="flex items-start gap-4">
                        <div className="">
                          <Checkbox checkboxName={"enabled_how_it_work"} isChecked={enabledHowItWorks} checkboxLabel={t("how_it_works")} checkboxValue={enabledHowItWorks} getCheckboxValue={(id, event) => setEnabledHowItWorks(event.target.checked)} />
                        </div>
                        <div className="">
                          <Checkbox checkboxName={"enabled_resource"} isChecked={enabledResource} checkboxLabel={t("articles")} checkboxValue={enabledResource} getCheckboxValue={(id, event) => setEnabledResource(event.target.checked)} />
                        </div>
                        {/* <div className="">
                            <Checkbox
                            checkboxName={'enabled_plan_tutorial'}
                            isChecked={enabledPlanTutorial}
                            checkboxLabel={'Succession Planning Tutorial'}
                            checkboxValue={enabledPlanTutorial}
                            getCheckboxValue={(id,event) => setPlanTutorial(event.target.checked) }
                            />
                          </div>  */}
                      </div>
                </div>
              
                <div className="flex justify-end gap-4 mt-5">
                  {/* <Button 
                    buttonLabelClasses=''
                    buttonType={'button'} 
                    buttonIcon={(isCancel)?'fa-light fa-spinner fa-spin':'fa-light fa-times'} 
                    buttonIconPosition={'left'} 
                    buttonLabel={'Cancel'} 
                    buttonClasses={'!bg-slate-200 !text-slate-600 border border-slate-300'} 
                    functions={onCancel}
                
                    buttonHasLink={false}
                    buttonDisabled={isCancel}
                    /> */}
                  <Button buttonClasses="" buttonLabelClasses="" buttonType={"button"} buttonIcon={isManage ? "fa-light fa-spinner fa-spin" : "fa-light fa-floppy-disk"} buttonIconPosition={"left"} buttonLabel={t("save")} functions={onSubmit} buttonHasLink={false} buttonDisabled={isManage} />
                </div>
          </div>
      </div>
    </div>
  );
};

export default SiteSettings;
