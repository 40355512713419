import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import Input from "../form/Input";
import Select from "../form/Select";
import DropdownCheckbox from "../form/DropdownCheckbox";
import { useSelector } from "react-redux";

import { postData } from "../../services/api";
import toastr from "toastr";

import { useTranslation } from "react-i18next";

const InvitationPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  getActionValue = () => {},
  type,
  completedUsers,
}) => {
  const { i18n, t } = useTranslation();
  const user = useSelector((state) => state.auth?.user);
  const role = localStorage.getItem("role");
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    async function employeeData() {
      try {
        const result = await postData("assign/list", {
          sortQuery: { created_at: -1 },
          status: 1,
          is_invited: 1,
        });
        if (result.data) {
          const updatedResult =
            result?.data?.length > 0 &&
            result?.data?.map((user) => {
              const isChecked = completedUsers.some(
                (completed) => completed?.parent_id === user._id
              );
              return {
                ...user,
                isChecked: isChecked ? true : false,
              };
            });
          setEmployees(
            updatedResult?.length > 0 &&
              updatedResult?.map((items) => {
                let firstName = "";
                let lastName = "";
                if (!items?.first_name) {
                  let nameParts = items?.name?.split(/\s+/);
                  firstName = nameParts?.[0] || "";
                  lastName = nameParts?.slice(1).join(" ") || "";
                } else {
                  firstName = items?.first_name;
                  lastName = items?.last_name;
                }
                return {
                  _id: items?._id,
                  label: firstName + " " + lastName + " - " + items?.email,
                  value: items?.email,
                  position: items?.designation,
                  first_name: firstName,
                  last_name: lastName,
                  isEnabled: items?.isChecked,
                  isDisabled: items?.isChecked,
                };
              })
          );
        } else {
          console.log("Employee list message", result.message);
        }
      } catch (error) {
        console.log("Error employee list catch", error.message);
      }
    }
    employeeData();
  }, []);

  const onSubmit = async () => {
    const payloadData = employees?.filter(
      (item) => !item?.isDisabled && item?.isEnabled
    );
    try {
      setLoading(true);
      const result = await postData("executive/create", {
        employees: payloadData,
        sendFor: "new",
        // first_name : firstName,
        // last_name : lastName,
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        getActionValue({
          button_type: "edit",
          row_id: data?._id,
          current_status: "",
        });
        setIsOpen(false);
        setLoading(false);
      } else {
        toastr.error(result.message);
        setLoading(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   setId(data?._id);
  // }, [data]);

  const employeeSelect = async (id, checked) => {
    if (id && id === "all") {
      setEmployees(
        employees.map((emp) => {
          if (checked) {
            return { ...emp, isEnabled: checked };
          } else {
            if (emp.isDisabled) {
              return { ...emp, isEnabled: true };
            } else {
              return { ...emp, isEnabled: checked };
            }
          }
        })
      );
    } else {
      setEmployees(
        employees.map((emp) => {
          if (emp._id === id) {
            return { ...emp, isEnabled: checked };
          } else {
            return emp;
          }
        })
      );
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    {t("add_executive")}
                  </Dialog.Title>
                  <div className="absolute top-2 right-2 z-10">
                    <Button
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={""}
                      buttonClasses={
                        "!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"
                      }
                      functions={() => {
                        setIsOpen(false);
                      }}
                      buttonHasLink={false}
                    />
                  </div>
                  <div className="relative mt-4">
                    <div className="relative mb-3">
                      <DropdownCheckbox
                        data={employees}
                        functions={employeeSelect}
                        isTooltip={true}
                      />
                      {/* <Select 
                            label={t('company_employees')}
                            labelClasses={'!text-xs'}
                            xPlacement={'left'} 
                            selectedValue={''} 
                            dropdownClass={'!w-full'} 
                            search={true}
                            dropdownData={
                            [
                                { _id: "", label: t('select'),value:''} ,
                                ...employees.map((item) => (
                                    { 
                                      _id: item?._id, 
                                      label: item?.first_name+' '+item?.last_name+' - '+item?.email, 
                                      value: item?.email,
                                      position: item?.designation,
                                      first_name: item?.first_name,
                                      last_name: item?.last_name
                                    }
                                )),
                            ].filter((item)=> item)
                            }
                            getSelectedValue={(e) => {
                              setId(e?._id);               
                              setFirstName(e?.first_name);
                              setLastName(e?.last_name);
                              setDesignation(e?.position);
                            }}
                        /> */}
                    </div>
                  </div>
                  <div className="relative flex justify-center mb-3 mt-6 gap-4">
                    <Button
                      buttonClasses="!bg-teal-500"
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={
                        loading
                          ? "fa-light fa-spinner fa-spin"
                          : "fa-light fa-check"
                      }
                      buttonIconPosition={"left"}
                      buttonLabel={t("send_invitation")}
                      functions={onSubmit}
                      buttonHasLink={false}
                    />
                    <Button
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={t("cancel")}
                      buttonClasses={
                        "bg-white border border-dark-teal-500 !text-dark-teal-500 ml-2"
                      }
                      buttonLabelClasses="text-dark-teal-500 "
                      functions={closeModal}
                      buttonHasLink={false}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default InvitationPopup;
