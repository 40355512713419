import { API_URL } from "../config/host";
import { jwtDecode } from 'jwt-decode';

export const isTokenExpired = (token) => {
    if (!token) return true;  
    const decoded = jwtDecode(token);
    const now = Date.now() / 1000; // Current time in seconds      
    return decoded.exp < now;
};
  

export function allowedExtensions(){
    const ext = ['jpg', 'jpeg', 'png','pdf']
    return ext;
}

export function allowedImageExtensions(){
    const ext = ["jpg", "jpeg", "png","svg",'gif','webp'];
    return ext;
}

export function dayRemaining(date){
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date();
    const secondDate = new Date(date);
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    return diffDays + ((diffDays >1)?' days':' day');
}

export function sumTotal(data,field,orFiled=''){
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
        if(orFiled && data[i][orFiled]>0){
            sum = sum + parseFloat(data[i][orFiled] ? data[i][orFiled] : 0);
        }else{
            sum = sum + parseFloat(data[i][field] ? data[i][field] : 0);
        }
    }
    return sum;
};

export function doesFileExist(file){
    let fullPath = API_URL+file;
    var xhr = new XMLHttpRequest();
    xhr.open('HEAD', fullPath, false);
    xhr.send();
    if (xhr.readyState == 4 && xhr.status == 404 ) {   
        if(window.location.pathname !== '/profiles'){
            return {status: 400,path:'./company.png'};
        }else{
            return {status: 400,path:'./male-placeholder-image.jpeg'};
        }
    } else {
        return {status: 200,path:fullPath};
    }
}

export const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
};

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export const tinymceApiKey = () =>{
    const key = 'uemqg8efft8kgaho6qxmnav0yzbr7s6n00wmyfrh5b7gyxvl'//'hu65guu8gs1a6iw36ksmd9uo0cd0rtxjvlacpdz5w2igzf49'
    return key;
}

export const SuccessorLimitMessage = 'Sorry, there is a maximum of 5 successors.  Please remove an existing successor before you can add another one.';
export const SuccessonPlanLimitMessage = (number = 5) => {
    return 'Sorry, there is a maximum of '+number+' succession plan. Please remove an existing succession plan before you can add another one.';
}
export const NextStepMessage = 'Sorry, all drop downs must be completed before you can continue to the next step';

export const groupBy = (arr, property) => {
    return arr.reduce(function (memo, x) {
        if (!memo[x[property]]) { memo[x[property]] = []; }
        memo[x[property]].push(x);
        return memo;
    }, {});
};

export const emailDomain = (email) => {
    const emailDomain = email.split('@')[1];
    return emailDomain;
}

export const currencyFormat = (num) => {
    return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const arrayToString = (array) => {
    let data = [];
    for (let i = 0; i < array.length; i++) {
        let row = array[i].toLowerCase().replace(/_/g, ' ');
        data.push(row.charAt(0).toUpperCase() + row.slice(1));
    }
    return data.join();
}

export function languages(){
    const lang = [
        { _id:1,label:"English",value:'en'},
        { _id:2,label:"French",value:'fr'}
    ];
    return lang;
}

export function stringToWord(str) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
}

export function monthYear() {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let fullArray = [];
    for (let i = new Date().getFullYear() ; i < 2070; i++) {
        months.forEach((element) => {
            fullArray.push(element + " " + i)
        });            
    }    
    return fullArray; 
}

export const initialsValue = (str = "") => {
    const strArr = str?.split(" ");
    const initials =
      (strArr &&
        Array.isArray(strArr) &&
        strArr[0]
          .slice(0, 1)
          .concat(strArr?.length > 1 ? strArr.at(-1).slice(0, 1) : "")
          .toUpperCase()) ||
      [];
    return initials;
  };
