import React from "react";
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import RobotoLight from '../../assets/font/Roboto-Light.ttf';
import RobotoRegular from '../../assets/font/Roboto-Regular.ttf';
import RobotoMedium from '../../assets/font/Roboto-Medium.ttf';
import RobotoBold from '../../assets/font/Roboto-Bold.ttf';
import moment from 'moment/moment';

Font.register({ family: 'RobotoLight', src: RobotoLight });
Font.register({ family: 'RobotoRegular', src: RobotoRegular });
Font.register({ family: 'RobotoMedium', src: RobotoMedium });
Font.register({ family: 'RobotoBold', src: RobotoBold });


const ScorecardIndividualReport = ({successors, heading, user,companyLogo}) => {
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: 'RobotoRegular',
      lineHeight: 1.2,
      fontSize: 9,
      color:'#334155'
    },
    tableHeading: {
      fontSize: 12,
      width: "20%",
      maxWidth: '100%',
      padding: 8,

    },
    tableData: {
      fontSize: 10,
      width: "20%",
      maxWidth: '100%',
      padding: 8,
    },

    image: {
      width: 200,
      height: 40,
      objectFit: "contain",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tableOtherData: {
      fontSize: 10
    },
    imageSignature: {
      width: 100,
      height: 60,
      objectFit: "contain",
    },
    section: { textAlign: "center" },
  });

  return (
    <Document>
      <Page size="A4" orientation='landscape' style={styles.page}>
        <View style={[styles.section, { color: "white" }]}>
          <Text>Section #1</Text>
        </View>
        <View style={{ paddingBottom: 20, width: "100%", margin: "0 auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <View
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontSize: 16,
              alignItems:"center",
              position:'relative',
              width:'100%',
            }}
          >
            <Image
              style={styles.image}
              src={companyLogo}
            />
            <View style={{position:'absolute', right:0, top:0,}}>
              <Image
                style={{width: 120, height: 24, objectFit: "contain",}}
                src={logo}
              />
            </View>
          </View>
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoBold", fontSize: 20 , textTransform:'capitalize'}]}>Company Name: {user?.company_name}</Text>
          </View>
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 16, textTransform: "capitalize" }]}>
              Succession Plan for {user?.title}
            </Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center" }]}>
            <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center", marginRight: 12 }]}>
              <Text style={[{ display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 10, textAlign: "center", fontSize: 10 }]}>Created by :</Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>{user?.name} ({user?.title})</Text>
            </View>
            <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 5 }]}>
              <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 10 }]}>Date:</Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>{moment(new Date()).format("MM/DD/YYYY")}</Text>
            </View>
          </View>
        </View>
        {successors && successors.length > 0 &&
        successors.map((row,key) => {
        return (
          <>
          {row.successors_details &&
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", columnGap:20, marginTop: 5,  marginBottom:5, }]} key={key}>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 12 }]}>{row.position} : {row.name}</Text>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 12 }]}>Retirement Window : {(row?.retirement)?row?.retirement?.replace(/(Eliminates this candidate)/gi,'').replace(/[{()}]/g, ''):''}</Text>
          </View>
          }
          {row.successors_details && row.successors_details.length > 0 &&
          <View style={{ border: "1px solid #e2e8f0", borderRadius: 6, overflow: "hidden", marginBottom:30 }} key={key}>
            <View style={{ display: "flex", flexDirection: "row", backgroundColor: "#0f172a", color:'#ffffff', overflow: "hidden" }}>
              <Text style={[styles.tableHeading, { fontFamily: 'RobotoMedium', fontSize: 10 }]}>&nbsp;</Text>
              {row.successors_details.map((item,index) => (
                <View key={index} style={[styles.tableHeading, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                  <Text style={{ width: "100%", fontFamily: 'RobotoMedium', fontSize: 10 }}>{item.name}</Text>
                  <Text style={{ fontSize: 8, marginTop: 2 }}>{item?.position_details? item?.position_details[0]?.short_name: "N/A"}</Text>
                </View>
              ))}
            </View>          
            { heading.map((items, index) => (
              <View key={index} style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0" }}>
                <View style={[styles.tableData, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                  <Text style={[{ fontWeight: 500, width: '100%', fontSize: 10, lineHeight: 1 }]}>{items.name}</Text>
                  <Text style={[{  fontSize: 8, fontFamily: 'RobotoMedium', marginTop: 2, color: 'red' }]}>{items.option}</Text>
                </View>

                {row.successors_details.map((item,index2) => (
                  <View key={index+'_'+index2} style={[styles.tableData, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                    {index === 0 &&
                      <Text style={{ fontSize: 9,textAlign: "center" }}>{item?.overall_weighted_score
                      ? item?.overall_weighted_score
                      : 0}</Text>                     
                    }
                    {index === 1 &&
                     <Text style={{ fontSize: 9,textAlign: "center" }}>{item?.experience_details &&
                      item?.experience_details.length > 0
                        ? item?.experience_details[0]?.name
                        : "N/A"}                      
                      </Text>                      
                    }
                    {index === 2 &&
                      <Text style={{ fontSize: 9,textAlign: "center" }}>
                      {item?.overall_score ? item?.overall_score : 0}
                      </Text>                      
                    }
                    {index === 3 &&
                      <Text style={{ fontSize: 9,textAlign: "center" }}>
                      {item?.performance_details &&
                        item?.performance_details.length > 0
                          ? item?.performance_details[0]?.name+' '+((item?.performance_details[0].number)?'('+item?.performance_details[0].number+')':'')
                          : "N/A"}
                      </Text>                     
                    }
                    {index === 4 &&
                      <Text style={{ fontSize: 9,textAlign: "center" }}>
                      {item?.education_details &&
                        item?.education_details.length > 0
                          ? item?.education_details[0]?.name
                          : "N/A"}
                      </Text>
                      
                    }
                    {index === 5 &&
                      <Text style={{ fontSize: 9,textAlign: "center" }}>
                      {item?.cu_details &&
                        item?.cu_details.length > 0
                          ? item?.cu_details[0]?.name+' '+((item?.cu_details[0].number)?'('+item?.cu_details[0].number+')':'')
                          : "N/A"}
                      </Text>
                     
                    }
                    {index === 6 &&
                       ((item.desire_details.length > 0 && item?.desire_details[0].isPass)?(
                        <Text style={{ fontSize: 9,textAlign: "center" }}>{(Array.isArray(item?.desire_details) && item?.desire_details.length > 0)?
                        (item?.desire_details[0]?.isPass)?'Pass: ':'Fail: ': ""} 
                          {item?.desire_details && item?.desire_details.length > 0
                            ? item?.desire_details[0]?.name
                            : "N/A"}</Text>
                      ):(
                        <Text style={{ fontSize: 9, color: 'red',textAlign: "center" }}>{(Array.isArray(item?.desire_details) && item?.desire_details.length > 0)?
                        (item?.desire_details[0]?.isPass)?'Pass: ':'Fail: ': ""} 
                          {item?.desire_details && item?.desire_details.length > 0
                            ? item?.desire_details[0]?.name
                            : "N/A"}</Text>
                      ))
                    }
                    {index === 7 &&
                       ((item?.loyalty_details.length > 0 && item?.loyalty_details[0].isPass)?(
                        <Text style={{ fontSize: 9,textAlign: "center" }}>{(Array.isArray(item?.loyalty_details) && item?.loyalty_details.length > 0)?
                      (item?.loyalty_details[0]?.isPass)?'Pass: ':'Fail: ': ""} 
                      {item?.loyalty_details && item?.loyalty_details.length > 0
                          ? item?.loyalty_details[0]?.name
                          : "N/A"}</Text>
                      ):(
                        <Text style={{ fontSize: 9, color: 'red',textAlign: "center" }}>{(Array.isArray(item?.loyalty_details) && item?.loyalty_details.length > 0)?
                      (item?.loyalty_details[0]?.isPass)?'Pass: ':'Fail: ': ""} 
                      {item?.loyalty_details && item?.loyalty_details.length > 0
                          ? item?.loyalty_details[0]?.name
                          : "N/A"}</Text>
                      ))
                    }
                    {index === 8 &&
                      ((item.retirement_details.length > 0 && item?.retirement_details[0].isPass)?(
                        <Text style={{ fontSize: 9,textAlign: "center" }}>{(Array.isArray(item?.retirement_details) && item?.retirement_details.length > 0)?
                      (item?.retirement_details[0]?.isPass)?'Pass: ':'Fail: ': ""}
                        {item?.retirement_details &&
                        item?.retirement_details.length > 0
                          ? item?.retirement_details[0]?.name
                          : "N/A"}</Text>
                      ):(
                        <Text style={{ fontSize: 9, color: 'red',textAlign: "center" }}>{(Array.isArray(item?.retirement_details) && item?.retirement_details.length > 0)?
                      (item?.retirement_details[0]?.isPass)?'Pass: ':'Fail: ': ""}
                        {item?.retirement_details &&
                        item?.retirement_details.length > 0
                          ? item?.retirement_details[0]?.name
                          : "N/A"}</Text>
                      ))
                    }
                    {index === 9 &&
                      <Text style={{ fontSize: 9 }}>{ Number(item?.overallWeightScore) ? Number(item?.overallWeightScore).toFixed(2) : 0.00 }</Text>
                    }
                    {index === 10 &&
                      <Text style={{ fontSize: 9 }}>
                        {item?.timeframe_details && item?.timeframe_details.length > 0 &&
                          item?.timeframe_details[0]?.name
                        }{"\n"}
                        {item?.competencyfits_details && item?.competencyfits_details.length > 0 &&
                          item?.competencyfits_details[0]?.name
                        }{"\n"}
                        {item?.advancement_additional_comments &&
                          item?.advancement_additional_comments
                        }
                      </Text>
                    }
                  </View>
                ))}

              </View>
            )) }

          </View>
          }
          </>
        )
        })
        }
      </Page>
    </Document>
  );
};

export default ScorecardIndividualReport;
