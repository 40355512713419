import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import Input from "../form/Input";
import Select from "../form/Select";

import { postData } from "../../services/api";
import toastr from "toastr";
import { useTranslation } from "react-i18next";

const ProfilePopup = ({ isOpen, setIsOpen = () => {}, data, getActionValue = () => {}, message, heading }) => {
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [designation, setDesignation] = useState("");
  const [selectRetirementWindow, setSelectRetirementWindow] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [profileTitle, setProfileTitle] = useState(null);
  const [loading, setLoading] = useState(false);

  const [position, setPosition] = useState([]);
  const [retirementWindow, setRetirementWindow] = useState([]);

  const closeModal = () => {
    setIsOpen(false);
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const result = await postData("profile/update", {
        name: name,
        phone: phone,
        designation: designation !== "" ? designation : null,
        retirement_window: selectRetirementWindow ? selectRetirementWindow : null,
        title: profileTitle,
        role: role,
        company_name: companyName,
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        getActionValue({ button_type: "edit", row_id: data?._id, current_status: "" });
        setIsOpen(false);
        setLoading(false);
      } else {
        toastr.error(result.message);
        setLoading(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    async function positionData() {
      try {
        const result = await postData("position/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setPosition(result.data);
        } else {
          console.log("Position list message", result.message);
        }
      } catch (error) {
        console.log("Error position list catch", error.message);
      }
    }
    positionData();
    async function retirementWindowData() {
      try {
        const result = await postData("experience/list", {
          sortQuery: { created_at: 1 },
          type: 3,
          status: 1,
          is_visabled: 1,
        });
        if (result.data) {
          setRetirementWindow(result.data);
        } else {
          console.log("Retirement window list message", result.message);
        }
      } catch (error) {
        console.log("Error retirement window list catch", error.message);
      }
    }
    retirementWindowData();
  }, []);

  useEffect(() => {
    setName(data?.name);
    setEmail(data?.email);
    setPhone(data?.phone);
    setDesignation(data?.designation);
    setSelectRetirementWindow(data?.retirement_window);
    setProfileTitle(data?.title);
    setCompanyName(data?.company_name);
  }, [data]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 text-center">
                    {t("please_confirm_your_information")}
                  </Dialog.Title>
                  <div className="relative mt-4">
                    <p className="text-sm text-gray-500 text-center mb-2">
                      {t("welcome_to_successionNow")} {name}. {t("please_confirm_the_below_information_to_continue")}
                    </p>
                    <div className="relative mb-3">
                      <Input label={t("your_full_name")} requiredDisplay={true} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_full_name")} inputValue={name} setInput={setName} />
                    </div>
                    <div className="grid sm:grid-cols-2 gap-3">
                      <div className="relative mb-3">
                        <Input label={t("work_email")} requiredDisplay={true} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("work_email_address")} inputValue={email} setInput={setEmail} isDisabled={true} />
                      </div>
                      <div className="relative mb-3">
                        <Input label={t("your_phone_number")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_phone_number")} inputValue={phone} setInput={setPhone} />
                      </div>
                    </div>
                    <div className="grid sm:grid-cols-2 gap-3">
                      <div className="relative mb-3">
                        <Select label={t("your_current_position")} requiredDisplay={true} labelClasses={"!text-xs"} xPlacement={"left"} selectedValue={designation} dropdownClass={"!w-60"} dropdownData={[{ _id: "", label: t("select_position"), value: "" }, ...position.map((item) => ({ _id: item?._id, label: item?.name, value: item?._id }))]} getSelectedValue={(e) => setDesignation(e._id)} />
                      </div>

                      <div className="relative mb-3">
                        <Select
                          label={t("your_retirement_window")}
                          requiredDisplay={true}
                          labelClasses={"!text-xs"}
                          xPlacement={"left"}
                          selectedValue={selectRetirementWindow}
                          dropdownClass={"!w-60"}
                          dropdownData={[
                            { _id: "", label: t("select_retirement_window"), value: "" },
                            ...retirementWindow.map((item) => {
                              return {
                                _id: item?._id,
                                label: item?.name.replace(/(Eliminates this candidate)/gi, "").replace(/[{()}]/g, ""),
                                value: item?._id,
                              };
                            }),
                          ]}
                          getSelectedValue={(e) => setSelectRetirementWindow(e._id)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="relative mb-3 mt-6">
                    <Button buttonClasses="!bg-teal-500 !mx-auto" buttonLabelClasses="" buttonType={"button"} buttonIcon={loading ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"} buttonIconPosition={"left"} buttonLabel={t("confirm")} functions={onSubmit} buttonHasLink={false} buttonDisabled={loading} />
                    {/* <Button 
                        buttonLabelClasses=''
                        buttonType={'button'} 
                        buttonIcon={'fa-light fa-times'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={'Cancel'} 
                        buttonClasses={'!bg-slate-200 !text-slate-600 border border-slate-300'} 
                        functions={closeModal}
                        buttonHasLink={false}
                        /> */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ProfilePopup;
