import React,{useState,useEffect} from 'react';
import LeadershipItem from "../../../Items/LeadershipItem"
import Time from "../../../../assets/icon/time.webp"
import Mistake from "../../../../assets/icon/mistakes.webp"
import Knowledge from "../../../../assets/icon/knowledge.webp"
import Fees from "../../../../assets/icon/fees.webp"
import Cost from "../../../../assets/icon/cost.webp"
import Relationship from "../../../../assets/icon/relationship.webp"

import { postData } from '../../../../services/api';
import { useTranslation } from "react-i18next";

const LeadershipSection = ({title,description}) => {
  const { i18n,t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(1);
  const [sortQuery, setSortQuery] = useState("");
  const [planData, setPlanData] = useState([]);

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    const loadList = async () => {
      setLoading(true);
      const result = await postData("/leadership/list", {
        sortQuery: sortQuery
      });
      if (result.data) {
        setPlanData(
          result.data.map((item,index)=>{
            return {
              image:item?.image,
              title:item?.name?.[i18n?.language],
              description:"<p>"+item?.description?.[i18n?.language]+"</p>"
            }
          })
        );
        setLoading(false);
      }
    };
    loadList();
  }, []);
  const LeadershipData = {
    title:title,  
    description:description,
    Leadership:planData
    // [
    //   {
    //     image:Time,
    //     title:"Drain On Management Time ",
    //     description:"<p>Minimizing the time spent on recruitment, interviews, and transition challenges, SuccessionNow saves your management team valuable time to focus on their strategic initiatives.</p>",
    //   },
    //   {
    //     image:Mistake,
    //     title:"New Hire Mistakes",
    //     description:"<p>New hires are much more likely to make mistakes that can lead to major consequences as they learn their new position. Avoid these costly errors with a leadership development plan using SuccessionNow.</p>",
    //   },
    //   {
    //     image:Knowledge,
    //     title:"Lost Knowledge",
    //     description:"<p>Departing employees can take valuable company-specific knowledge and experience that can be lost for good. Having a succession plan builds in a backup so that knowledge is passed on.</p>",
    //   },
    //   {
    //     image:Fees,
    //     title:"Recruitment Fees",
    //     description:"<p>Agency fees and hiring incentives can add up to a significant amount. SuccessionNow enables you to efficiently manage your hiring process in-house, saving on external recruitment costs.</p>",
    //   },
    //   {
    //     image:Cost,
    //     title:"Opportunity Costs",
    //     description:"<p>Missed opportunities take their toll as time and focus have to be turned to hiring, onboarding and training a new hire rather than business development.</p>",
    //   },
    //   {
    //     image:Relationship,
    //     title:"Client Relationships",
    //     description:"<p>Having a succession plan in place that include key client introductions can keep a relationship with those clients strong when a transition is required.</p>",
    //   },
    // ]
  }
  return (
    <section className="relative w-full py-10 bg-white" id="leadership-section">
      <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl  mx-auto">
        
          <div className="max-w-3xl w-full mx-auto text-center mb-10 xl:mb-20 space-y-3 lg:space-y-10 xl:space-y-16">
                <h2 className="capitalize text-slate-950  !leading-tight text-2xl xs:text-3xl lg:text-5xl xl:text-6xl  font-extrabold font-PlusJakartaSans" dangerouslySetInnerHTML={{__html:LeadershipData.title}}></h2>
                <div className="text-sm lg:text-base text-slate-700" dangerouslySetInnerHTML={{__html:LeadershipData.description}}></div>
          </div>
          <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-5 lg:gap-y-10 xl:gap-y-14 2xl:gap-y-16">
                  {LeadershipData.Leadership.map((item, index) => (
                      <LeadershipItem key={index} data={item}/>
                  ))}
          </div>
      </div>
  </section>
  );
};

export default LeadershipSection;