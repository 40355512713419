import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import Input from "../form/Input";
import Select from "../form/Select";
import { useSelector } from "react-redux";

import { postData } from "../../services/api";
import toastr from "toastr";

import OtherPositionPopup from "./OtherPositionPopup";
import { useTranslation } from "react-i18next";
import { SuccessonPlanLimitMessage } from "../../helpers";
import { useDispatch } from "react-redux";
import { authLogin, authLogout } from "../../store/authSlice";
import { useNavigate } from "react-router-dom";

const AdditionalPlanPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  getActionValue = () => {},
  type,
}) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth?.user);
  const role = localStorage.getItem("role");
  const [types, setTypes] = useState(1);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [selectRetirementWindow, setSelectRetirementWindow] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState([]);
  const [retirementWindow, setRetirementWindow] = useState([]);

  const [enabledOtherPositionPopup, setEnabledOtherPositionPopup] =
    useState(false);
  const [actionValue, setActionValue] = useState({});

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    async function positionData() {
      try {
        const result = await postData("position/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setPosition(result.data.sort((a, b) => a.name.localeCompare(b.name)));
        } else {
          console.log("Position list message", result.message);
        }
      } catch (error) {
        console.log("Error position list catch", error.message);
      }
    }
    positionData();

    async function retirementWindowData() {
      try {
        const result = await postData("experience/list", {
          sortQuery: { ordering: 1 },
          type: 3,
          status: 1,
        });
        if (result.data) {
          setRetirementWindow(result.data);
        } else {
          console.log("Retirement window list message", result.message);
        }
      } catch (error) {
        console.log("Error retirement window list catch", error.message);
      }
    }
    retirementWindowData();
  }, []);

  const onSubmit = async () => {
    setLoading(true);
    let successionPlanCount = 0;
    let totalSuccessionPlan = (user?.number_of_succession_plan)?user?.number_of_succession_plan:0;
    if(user?.features && user?.features?.length>0){
      successionPlanCount = user?.features?.find(it => it?.code === 'succession-plans-per-year')?.inputValue; 
    }
    try {      
      let path = "";
      let payload = {};
      if (id && types === 1) {
        path = "assign/update-non-invited";
        payload = {
          id: id,
          name: name,
          designation: designation,
          company_name: user?.company_name,
          retirement_window:
            selectRetirementWindow !== "" ? selectRetirementWindow : null,
        };
        const result = await postData(path, payload);
        if (result.status && result.status === 200) {
          toastr.success(result.message);
          getActionValue({
            button_type: "edit",
            row_id: data?._id,
            current_status: "",
          });
          setIsOpen(false);
          setLoading(false);
        } else {
          toastr.error(result.message);
          setLoading(false);
        }
      } else if (id && types === 2) {
        path = "assign/make-invited-employee";
        payload = {
          id: id,
          email: email,
        };
        const result = await postData(path, payload);
        if (result.status && result.status === 200) {
          toastr.success(result.message);
          getActionValue({
            button_type: "edit",
            row_id: data?._id,
            current_status: "",
          });
          setIsOpen(false);
          setLoading(false);
        } else {
          toastr.error(result.message);
          setLoading(false);
        }
      } else {
        //if(successionPlanCount && successionPlanCount > totalSuccessionPlan){
          path = "assign/create-non-invited";
          payload = {
            name: name,
            designation: designation,
            company_name: user?.company_name,
            retirement_window: selectRetirementWindow !== "" ? selectRetirementWindow : null,
          };
          const result = await postData(path, payload);
          if (result.status && result.status === 200) {
            toastr.success(result.message);
            getActionValue({
              button_type: "edit",
              row_id: data?._id,
              current_status: "",
            });            
            const userData = await postData("verify-token", {}, localStorage.getItem('accessToken'));
            if(userData?.data){
              if(userData?.status && userData?.status === 200){
                dispatch( authLogin(userData?.data) );
              }else{
                dispatch( authLogout() );
                localStorage.removeItem('accessToken');
                navigate('/signin')
              }
            }
            setIsOpen(false);
            setLoading(false);
          } else {
            toastr.error(result.message);
            setLoading(false);
          }
        // }else{
        //   toastr.error(SuccessonPlanLimitMessage(successionPlanCount));
        //   setLoading(false);
        // }
      }      
    } catch (error) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data && data.type === 1) {
      setName(data?.name);
      setDesignation(data?.designation);
      setSelectRetirementWindow(data?.retirement_window);
    }
    setTypes(data?.type);
    setId(data?._id);
  }, [data]);

  useEffect(() => {
    if (Object.keys(actionValue).length > 0) {
      setPosition([...position, actionValue]);
      setDesignation(actionValue?._id);
    }
  }, [actionValue]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    {types === 2
                      ? t("make_invited_employee")
                      : id
                      ? t("edit_additional_plan")
                      : t("create_additional_plan")}
                  </Dialog.Title>
                  <div className="absolute top-2 right-2 z-10">
                    <Button
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={""}
                      buttonClasses={
                        "!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"
                      }
                      functions={() => {
                        setIsOpen(false);
                        setName(null);
                        setDesignation(null);
                        setSelectRetirementWindow(null);
                        setEmail(null);
                        setPosition(null);
                      }}
                      buttonHasLink={false}
                    />
                  </div>
                  {types === 1 ? (
                    <div className="relative mt-4">
                      <div className="relative mb-3">
                        <Input
                          label={t("full_name")}
                          labelClasses={"!text-xs"}
                          inputType={"text"}
                          inputPlaceholder={t("enter_full_name")}
                          inputValue={name}
                          setInput={setName}
                          requiredDisplay={true}
                        />
                      </div>
                      <div className="relative mb-3">
                        <Select
                          label={t("position")}
                          labelClasses={"!text-xs"}
                          xPlacement={"left"}
                          selectedValue={designation}
                          dropdownClass={"!w-60"}
                          dropdownData={[
                            { _id: "", label: t("select_position"), value: "" },
                            ...position.map((item) => ({
                              _id: item?._id,
                              label: item?.name,
                              value: item?._id,
                            })),
                            {
                              _id: "others",
                              label: t("other"),
                              value: "others",
                            },
                          ]}
                          requiredDisplay={true}
                          getSelectedValue={(e) => {
                            if (e._id === "others") {
                              setEnabledOtherPositionPopup(true);
                            }
                            setDesignation(e._id);
                          }}
                        />
                      </div>
                      <div className="relative mb-3">
                        <Select
                          label={t("retirement_window")}
                          labelClasses={"!text-xs"}
                          xPlacement={"left"}
                          selectedValue={selectRetirementWindow}
                          dropdownClass={"!w-60"}
                          requiredDisplay={true}
                          dropdownData={[
                            {
                              _id: "",
                              label: t("select_retirement_window"),
                              value: "",
                            },
                            ...retirementWindow.map((item) => {
                              return {
                                _id: item?._id,
                                label: item?.name
                                  .replace(/(Eliminates this candidate)/gi, "")
                                  .replace(/[{()}]/g, ""),
                                value: item?._id,
                              };
                            }),
                          ]}
                          getSelectedValue={(e) =>
                            setSelectRetirementWindow(e._id)
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="relative mt-4">
                      <div className="relative mb-3">
                        <Input
                          label={t("email")}
                          labelClasses={"!text-xs"}
                          inputType={"text"}
                          inputPlaceholder={t("enter_email")}
                          inputValue={email}
                          setInput={setEmail}
                          requiredDisplay={true}
                        />
                      </div>
                    </div>
                  )}
                  <div className="relative flex justify-center mb-3 mt-6 gap-4">
                    <Button
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={t("cancel")}
                      buttonClasses={
                        "bg-white border border-dark-teal-500 !text-dark-teal-500 ml-2"
                      }
                      buttonLabelClasses="text-dark-teal-500 "
                      functions={closeModal}
                      buttonHasLink={false}
                    />
                    <Button
                      buttonClasses="!bg-teal-500"
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={
                        loading
                          ? "fa-light fa-spinner fa-spin"
                          : "fa-light fa-check"
                      }
                      buttonIconPosition={"left"}
                      buttonLabel={
                        types === 2 ? t("send_email_invitation") : t("confirm")
                      }
                      functions={onSubmit}
                      buttonHasLink={false}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {enabledOtherPositionPopup && (
        <OtherPositionPopup
          isOpen={enabledOtherPositionPopup}
          setIsOpen={(val) => setEnabledOtherPositionPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></OtherPositionPopup>
      )}
    </>
  );
};

export default AdditionalPlanPopup;
