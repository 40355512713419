import React, { useEffect, useState } from "react";
import { postData } from "../../../services/api";
import { useTranslation } from "react-i18next";

const CountedCard = ({ type = "" ,reatingOptions}) => {
  const { t } = useTranslation();
  const [loading,setLoading] = useState(true);
  const [result,setResult] = useState(0);
  const [totalDataCount, setTotalDataCount] = useState(0);

  useEffect(() => {
    // Board Member List
    const loadBoardMembers = async () => {
      setLoading(true);
      try {
        let path = "";
        let payload = "";
        if (type === "executive") {
          path = "executive/list";
          payload = {
            type: type,
            outside_candidate: 2,
          };
        } else if (type === "boardmember") {
          path = "board-member/list";
          payload = {
            type: type,
            outside_candidate: 2,
          };
        }
        const result = await postData(path, payload);
        if (result.data) {
          let totalRecord = 0;
          let completeRecord = 0;
          result.data.map((item, index) =>{
            let totalQuestion = reatingOptions?.length;
            let filterAnswer;
            if(type === "boardmember"){
              filterAnswer = item?.ratingOptions?.length > 0 && item?.ratingOptions.filter((it) => (it?.answerId !== null || it?.ids?.length>0));
            }else if(type === "executive"){
              filterAnswer = item?.executiveAudits?.length > 0 &&
                  item?.executiveAudits.filter(
                    (it) => it?.answerId !== null || it?.ids?.length > 0
                  );
            }
            let totalAnswer = filterAnswer?.length;
            if(totalQuestion === totalAnswer){
              completeRecord = Number(completeRecord+1);
            }
            totalRecord = Number(totalRecord+1);
          })
          setResult(completeRecord);
          setTotalDataCount(totalRecord);
        }        
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadBoardMembers();
  }, [type,reatingOptions]);
  return (
    <div className="w-full flex rounded-md items-end justify-end mt-1">
      <div className="text-lg font-bold text-white bg-dark-teal-500 px-4 py-2 rounded-tl-xl shadow-lg rounded-br-xl leading-none">
        {result}/{totalDataCount} {t('completed')}
      </div>
    </div>
  );
};

export default CountedCard;
