import React, { useState, useRef, useEffect } from "react";
import { allowedImageExtensions } from "../../helpers";
import toastr from "toastr";
import moment from "moment";
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import { postData } from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { setTitle } from "../../helpers/MetaTag";
import Select from "../../components/form/Select";
import Textarea from "../../components/form/Textarea";
import ButtonFile from "../../components/form/ButtonFile";
import Image from "../../components/elements/Image";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";

import { useTranslation } from "react-i18next";

const ManageLeadTracking = () => {
  const { id } = useParams();
  if (id) {
    setTitle("Succession Planning Software for your Business with Succession Now | Edit Lead Tracking");
  } else {
    setTitle("Succession Planning Software for your Business with Succession Now | New Lead Tracking");
  }
  const navigate = useNavigate();

  const { i18n, t } = useTranslation();
  const flatPicker = useRef(null);
  const [sizes, setSizes] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companyAdministrator, setCompanyAdministrator] = useState("");
  const [companyAdministratorEmail, setCompanyAdministratorEmail] = useState("");
  const [verifyCompanyAdministratorEmail, setVerifyCompanyAdministratorEmail] = useState("");
  const [companyAdministratorPhone, setCompanyAdministratorPhone] = useState("");
  const [companyLocation, setCompanyLocation] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [referredBy, setReferredBy] = useState("");
  const [leadSource, setLeadSourcee] = useState("");
  const [contactDate, setContactDate] = useState("");
  const [firstResponseDate, setFirstResponseDate] = useState("");
  const [responseType, setResponseType] = useState("");
  const [responder, setResponder] = useState("");
  const [firstResponseNotes, setFirstResponseNotes] = useState("");
  const [decision, setDecision] = useState("");
  const [demoDate, setDemoDate] = useState("");
  const [demoNotes, setDemoNotes] = useState("");
  const [demoCompletion, setDemoCompletion] = useState("");
  const [secondDemoDate, setSecondDemoDate] = useState("");
  const [secondDemoNotes, setSecondDemoNotes] = useState("");
  const [secondDemoCompletion, setSecondDemoCompletion] = useState("");
  const [status, setStatus] = useState("");
  const [details, setDetails] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  useEffect(() => {
    const loadCompanySize = async () => {
      try {
        const result = await postData("filter-item/list", {
          sortQuery: { ordering: 1 },
          type: "company-size"
        });
        if (result.data) {
          setSizes(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadCompanySize();
    // Lead details
    const loadLeadTrackingDetailsPlans = async () => {
      try {
        const result = await postData("salesteam/lead-details", {
          sortQuery: { ordering: 1 },
          id: id,
        });
        if (result.data) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadLeadTrackingDetailsPlans();
  }, []);

  useEffect(() => {
    setCompanyName(details?.company_name);
    setCompanyAdministrator(details?.primary_contact_name);
    setCompanyAdministratorEmail(details?.primary_contact_email);
    setCompanyLocation(details?.company_location);
    setCompanyAdministratorPhone(details?.primary_contact_phone);
    setCompanySize(details?.company_size);
    setReferredBy(details?.referred_by);
    setLeadSourcee(details?.lead_source);
    setContactDate(details?.contact_date);
    setFirstResponseDate(details?.first_response_date);
    setResponseType(details?.response_type);
    setResponder(details?.responder);
    setFirstResponseNotes(details?.first_response_notes);
    setDecision(details?.decision);
    setDemoDate(details?.demo_date);
    setDemoNotes(details?.demo_notes);
    setDemoCompletion(details?.demo_completion);
    setSecondDemoDate(details?.second_demo_date);
    setSecondDemoNotes(details?.second_demo_notes);
    setSecondDemoCompletion(details?.second_demo_completion);
    setStatus(details?.status);
  }, [details]);

  const onSubmit = async () => {
    setIsUpdate(true);
    try {
      const result = await postData("salesteam/lead-update", {
        id: id,
        company_name: companyName,
        primary_contact_name: companyAdministrator,
        primary_contact_email: companyAdministratorEmail,
        verify_company_adminstrator_email: verifyCompanyAdministratorEmail,
        company_location: companyLocation,
        primary_contact_phone: companyAdministratorPhone,
        company_size: companySize,
        referred_by: referredBy,
        lead_source: leadSource,
        contact_date: contactDate,
        first_response_date: firstResponseDate,
        response_type: responseType,
        responder: responder,
        first_response_notes: firstResponseNotes,
        decision: decision,
        demo_date: demoDate,
        demo_notes: demoNotes,
        demo_completion: demoCompletion,
        second_demo_date: secondDemoDate,
        second_demo_notes: secondDemoNotes,
        second_demo_completion: secondDemoCompletion,
        status: status,
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsUpdate(false);
      } else {
        toastr.error(result.message);
        setIsUpdate(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsUpdate(false);
    }
  };

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex gap-3 items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-2xl font-bold">{t("edit_lead_tracking")}</div>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 flex flex-col">
            <div className="space-y-4 ">
              <div className="grid grid-cols-1 sm:grid-cols-12 gap-4">
                <div className="col-span-12 md:col-span-4">
                  <div className="relative w-full">
                    <Input label={t("company_name")} labelClasses={"!text-xs"} inputType={"input"} inputPlaceholder={t("company_name")} inputValue={companyName} setInput={setCompanyName} />
                  </div>
                </div>
                <div className="col-span-12 md:col-span-4">
                  <div className="relative w-full">
                    <Input label={t("primary_contact_name")} labelClasses={"!text-xs"} inputType={"input"} inputPlaceholder={t("enter_primary_contact_name")} inputValue={companyAdministrator} setInput={setCompanyAdministrator} />
                  </div>
                </div>
                <div className="col-span-12 md:col-span-4">
                  <div className="relative w-full">
                    <Input label={t("primary_contact_email_addres")} labelClasses={"!text-xs"} inputType={"email"} inputPlaceholder={t("enter_primary_contact_email_addres")} inputValue={companyAdministratorEmail} setInput={setCompanyAdministratorEmail} />
                  </div>
                </div>
                <div className="col-span-12 md:col-span-4">
                  <div className="relative w-full">
                    <Input label={t("company_location")} labelClasses={"!text-xs"} inputType={"input"} inputPlaceholder={t("enter_company_location")} inputValue={companyLocation} setInput={setCompanyLocation} />
                  </div>
                </div>
                <div className="col-span-12 md:col-span-4">
                  <div className="relative w-full">
                    <Input
                      label={t("primary_contact_phone_number")}
                      labelClasses={"!text-xs"}
                      inputType={"number"}
                      inputPlaceholder={t("enter_primary_contact_phone_number")}
                      inputValue={companyAdministratorPhone}
                      setInput={setCompanyAdministratorPhone}
                    //isDisabled={true}
                    />
                  </div>
                </div>
                <div className="col-span-12 md:col-span-4">
                  <div className="relative w-full">
                    <Select
                      label={t("company_size")}
                      labelClasses={"!text-xs"}
                      xPlacement={"left"}
                      selectedValue={companySize}
                      dropdownClass={"!w-full"}
                      dropdownData={[
                        { _id: " ", label: t("select_size"), value: "" },
                        ...sizes.map((boardyear_item) => ({
                          _id: boardyear_item?._id,
                          label: boardyear_item?.name,
                          value: boardyear_item?._id,
                        })),
                      ]}
                      getSelectedValue={(e) => {
                        setCompanySize(e._id);
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-12 md:col-span-4">
                  <div className="relative w-full">
                    <Input label={t("referred_by") + " (" + t("if_applicable") + ")"} labelClasses={"!text-xs"} inputType={"input"} inputPlaceholder={t("referred_by")} inputValue={companyLocation} setInput={setCompanyLocation} />
                  </div>

                </div>
                <div className="col-span-12 md:col-span-4">
                  <div className="relative w-full">
                    <Select
                      label={t("lead_source")}
                      labelClasses={"!text-xs"}
                      xPlacement={"left"}
                      selectedValue={leadSource}
                      dropdownClass={"!w-full"}
                      //dropdownButtonLabelClass={"!font-bold"}
                      dropdownData={[
                        { _id: "", label: t("select_lead_source"), value: "" },
                        { _id: "linkedin", label: "LinkedIn", value: "linkedin" },
                        { _id: "subscriber-referral", label: "Subscriber Referral", value: "subscriber-referral" },
                        { _id: "gartner", label: "Gartner", value: "gartner" },
                        { _id: "google-ads", label: "Google Ads", value: "google-ads" },
                        { _id: "facebook", label: "Facebook", value: "facebook" },
                        { _id: "web-search", label: "Web Search", value: "web-search" },
                      ]}
                      dropdownOptionBold={true}
                      getSelectedValue={(e) => setLeadSourcee(e._id)}
                    />
                  </div>
                </div>
                <div className="col-span-12 md:col-span-4">
                  <div className="relative w-full">
                    <div className={"font-medium text-slate-500 mb-1 !text-xs"}>{t("initial_contact_date")}</div>
                    <Flatpickr
                      ref={flatPicker}
                      className="w-full h-10 rounded bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200"
                      placeholder={t("enter_date")}
                      value={contactDate}
                      options={{
                        dateFormat: "m/d/Y",
                        //minDate:new Date()
                      }}
                      onChange={(selectedDates, dateStr, instance) => {
                        setContactDate(dateStr);
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-12 md:col-span-4">
                  <div className="relative w-full">
                    <div className={"font-medium text-slate-500 mb-1 !text-xs"}>{t("first_response_date")}</div>
                      <Flatpickr
                        ref={flatPicker}
                        className="w-full h-10 rounded bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200"
                        placeholder={t("enter_date")}
                        value={firstResponseDate}
                        options={{
                          dateFormat: "m/d/Y",
                          //minDate:new Date()
                        }}
                        onChange={(selectedDates, dateStr, instance) => {
                          setFirstResponseDate(dateStr);
                        }}
                      />
                  </div>

                </div>
                <div className="col-span-12 md:col-span-4">
                  <div className="relative w-full">
                    <Select
                      label={t("response_type")}
                      labelClasses={"!text-xs"}
                      xPlacement={"left"}
                      selectedValue={responseType}
                      dropdownClass={"!w-full"}
                      //dropdownButtonLabelClass={"!font-bold"}
                      dropdownData={[
                        { _id: "", label: t("select_response_type"), value: "" },
                        { _id: 1, label: t("phone"), value: 1 },
                        { _id: 2, label: t("email"), value: 2 },
                      ]}
                      dropdownOptionBold={true}
                      getSelectedValue={(e) => setResponseType(e._id)}
                    />
                  </div>
                </div>
                <div className="col-span-12 md:col-span-4">
                  <div className="relative w-full">
                    <Input label={t("responder")} labelClasses={"!text-xs"} inputType={"input"} inputPlaceholder={t("enter_responder")} inputValue={responder} setInput={setResponder} />
                  </div>
                </div>
                <div className="col-span-12 md:col-span-4">
                  <div className="relative w-full">
                    <Textarea label={t("first_response_notes")} inputClasses={"!h-32"} inputValue={firstResponseNotes} setTextarea={setFirstResponseNotes} />
                  </div>
                </div>
                <div className="col-span-12 md:col-span-4">
                  <div className="relative w-full">
                    <Select
                      label={t("decision")}
                      labelClasses={"!text-xs"}
                      xPlacement={"left"}
                      selectedValue={decision}
                      dropdownClass={"!w-full"}
                      //dropdownButtonLabelClass={"!font-bold"}
                      dropdownData={[
                        { _id: "", label: t("select_decision"), value: "" },
                        { _id: 1, label: t("demo_scheduled"), value: 1 },
                        { _id: 2, label: t("demo_declined"), value: 2 },
                        { _id: 3, label: t("no_response"), value: 3 },
                      ]}
                      dropdownOptionBold={true}
                      getSelectedValue={(e) => setDecision(e._id)}
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-12 gap-4 !mt-10">
                <div className="col-span-12">
                  <div className="relative text-center flex items-center justify-center">
                      <div className="w-64 h-px  bg-gray-200 border-0 dark:bg-gray-700 absolute z-[0]"></div>
                      <div className="px-3 font-medium text-white bg-gray-900 mx-auto relative z-[1]">{t("first_demo")}</div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-4">
                  <div className="relative w-full">
                    <div className={"font-medium text-slate-500 mb-1 !text-xs"}>{t("date")}</div>
                    <Flatpickr
                      ref={flatPicker}
                      className="w-full h-10 rounded bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200"
                      placeholder={t("enter_date")}
                      value={demoDate}
                      options={{
                        dateFormat: "m/d/Y",
                        //minDate:new Date()
                      }}
                      onChange={(selectedDates, dateStr, instance) => {
                        setDemoDate(dateStr);
                      }}
                    />
                  </div>

                </div>
                <div className="col-span-12 md:col-span-4">
                  <div className="relative w-full">
                    <Textarea label={t("notes")} inputClasses={"!h-32"} inputValue={demoNotes} setTextarea={setDemoNotes} />
                  </div>

                </div>
                <div className="col-span-12 md:col-span-4">
                  <div className="relative w-full">
                    <Select
                      label={t("completion")}
                      labelClasses={"!text-xs"}
                      xPlacement={"left"}
                      selectedValue={demoCompletion}
                      dropdownClass={"!w-full"}
                      dropdownButtonLabelClass={"!font-bold"}
                      dropdownData={[
                        { _id: "", label: t("select_completion"), value: "" },
                        { _id: 1, label: t("completed"), value: 1 },
                        { _id: 2, label: t("canceled"), value: 2 },
                        { _id: 3, label: t("rescheduled"), value: 3 },
                        { _id: 4, label: t("no_show"), value: 4 },
                      ]}
                      dropdownOptionBold={true}
                      getSelectedValue={(e) => setDemoCompletion(e._id)}
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-12 gap-4 !mt-10">
                <div className="col-span-12">
                    <div className="relative text-center flex items-center justify-center">
                          <div className="w-64 h-px  bg-gray-200 border-0 dark:bg-gray-700 absolute z-[0]"></div>
                          <div className="px-3 font-medium text-white bg-gray-900 mx-auto relative z-[1]">{t("second_demo")}</div>
                    </div>
                </div>
                <div className="col-span-12 md:col-span-4">
                  <div className="relative w-full">
                    <div className={"font-medium text-slate-500 mb-1 !text-xs"}>{t("date")}</div>
                    <Flatpickr
                      ref={flatPicker}
                      className="w-full h-10 rounded bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200"
                      placeholder={t("enter_date")}
                      value={secondDemoDate}
                      options={{
                        dateFormat: "m/d/Y",
                        //minDate:new Date()
                      }}
                      onChange={(selectedDates, dateStr, instance) => {
                        setSecondDemoDate(dateStr);
                      }}
                    />
                  </div>

                </div>
                <div className="col-span-12 md:col-span-4">
                  <div className="relative w-full">
                    <Textarea label={t("notes")} inputClasses={"!h-32"} inputValue={secondDemoNotes} setTextarea={setSecondDemoNotes} />
                  </div>

                </div>
                <div className="col-span-12 md:col-span-4">
                  <div className="relative w-full">
                    <Select
                      label={t("completion")}
                      labelClasses={"!text-xs"}
                      xPlacement={"left"}
                      selectedValue={secondDemoCompletion}
                      dropdownClass={"!w-full"}
                      dropdownButtonLabelClass={"!font-bold"}
                      dropdownData={[
                        { _id: "", label: t("select_completion"), value: "" },
                        { _id: 1, label: t("completed"), value: 1 },
                        { _id: 2, label: t("canceled"), value: 2 },
                        { _id: 3, label: t("rescheduled"), value: 3 },
                        { _id: 4, label: t("no_show"), value: 4 },
                      ]}
                      dropdownOptionBold={true}
                      getSelectedValue={(e) => setSecondDemoCompletion(e._id)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end gap-4">
              <Button buttonClasses="" buttonLabelClasses="" buttonType={"button"} buttonIcon={isUpdate ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"} buttonIconPosition={"left"} buttonLabel={t("send")} functions={onSubmit} buttonHasLink={false} buttonDisabled={isUpdate} />
            </div>
        </div>
      </div>
    </div>
  );
};

export default ManageLeadTracking;
