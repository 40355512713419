import React from 'react';
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
const Pagination = ({handlePageClick, noOfPage, startDataCount, endDataCount, count}) => {
  const { i18n, t } = useTranslation();
  return (
      <div className="flex flex-col sm:flex-row justify-center sm:justify-between my-4 gap-2">
          <div className="text-sm text-black text-center sm:text-left"><p>{t('showing')}: {startDataCount} to {endDataCount} of {count}</p></div>
          <ReactPaginate 
            breakLabel="..." 
            breakClassName="w-8 aspect-1 flex justify-center items-center rounded-full hover:bg-slate-200" 
            nextLabel={<i className="fa-regular fa-arrow-right text-teal-500"></i>} 
            nextClassName="w-8 aspect-1 flex justify-center items-center rounded-full hover:bg-slate-200" 
            onPageChange={handlePageClick} 
            pageRangeDisplayed={3} 
            pageCount={noOfPage} 
            containerClassName="flex justify-center items-center text-slate-80 text-sm font-medium  lg:mt-0 space-x-2" 
            activeClassName="hover:bg-teal-500 bg-teal-500 text-white" 
            pageLinkClassName="flex justify-center items-center w-full h-full" 
            previousLabel={<i className="fa-regular fa-arrow-left text-teal-500"></i>} 
            pageClassName="w-8 aspect-1 flex justify-center items-center rounded-full hover:bg-slate-200" 
            previousClassName="w-8 aspect-1 flex justify-center items-center rounded-full hover:bg-slate-200" 
            renderOnZeroPageCount={null} 
          />
      </div>
  );
};

export default Pagination;