import React from "react";
import SidebarMenuItem from "../navigation/SidebarMenuItem";
import SidebarSubMenuItem from "./SidebarSubMenuItem";
import useWindowDimensions from "../../helper/useWindowDimensions";
import { classNames } from "../../helper/classNames";
import Button from "../form/Button";

const SidebarMenuBlocks = ({ menuTitle, menuData, isMenuOpen, setIsMenuOpen, submenuToggleMenu,getActionValue = () => {}, ...props}) => {
  const { height, width } = useWindowDimensions();

  return (
    <>
      {
        !props.isHidden && 
        <div className={classNames(
          "relative py-4 space-y-2 transition-all duration-100",
          width > 1024 ? (isMenuOpen ? "px-2" : "px-4") : "px-2"
        )}>
          {width > 1024 ? 
          (!isMenuOpen && <div className={"text-xs uppercase text-slate-500 font-medium"}>{menuTitle}</div>) : 
          (<div className={"text-xs uppercase text-slate-500 font-medium"}>{menuTitle}</div>)
          }
          <div className="space-y-1">
              {/* {menuData.map((item, index) => (
                <SidebarMenuItem key={item._id} index={index} isStepMenu={item.isStepMenu} menuLink={item.menuLink} menuIcon={item.menuIcon} menuIconColor={item.menuIconColor} menuLabel={item.menuLabel} isMenuOpen={isMenuOpen} isLocked={item.isLocked}/>
              ))} */}
              {
                menuData.length>0 && menuData?.map((item, index) =>
                  item.menuIsLink ? (
                    <SidebarMenuItem key={item._id} index={index} isStepMenu={item.isStepMenu} menuLink={item.menuLink} menuIcon={item.menuIcon} menuIconColor={item.menuIconColor} menuLabel={item.menuLabel} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} isLocked={item.isLocked}/>
                  ) : (
                    item?.isButton?
                    <Button 
                    buttonType={"button"} 
                    buttonIcon={"fa-light "+item?.menuIcon} 
                    buttonClasses={"!w-full !h-12 blob"}
                    buttonIconPosition={"left"} 
                    buttonLabel={item?.menuLabel} 
                    buttonLabelClasses={"!whitespace-normal !text-left"}
                    buttonHasLink={false} 
                    //buttonLink={"/add-executive"} 
                    functions={(e) => getActionValue(e,item?.isButtonType)}
                  />
                      
                    :
                    <SidebarSubMenuItem key={item._id} isStepMenu={item.isStepMenu}  menuIcon={item.menuIcon}  menuIconColor={item.menuIconColor} submenuData={item.submenuData} menuLabel={item.menuLabel} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} isCollapse={item.isCollapse} submenuToggleMenu={submenuToggleMenu}/>
                  )
                ).filter((item) => item)
              }
            </div>         
        </div>
      }
      
    </>
  );
};

export default SidebarMenuBlocks;
