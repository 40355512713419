import React, { useState, useEffect, useCallback } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Select from "../components/form/Select";
import Search from "../components/form/Search";
import Table from "../components/elements/table/Table";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../helpers";
import toastr from "toastr";
import CrmTab from "../shared/CrmTab";
import { useSelector } from "react-redux";

import { postData } from "../services/api";
import { useTranslation } from "react-i18next";
import Pagination from "../components/Common/Pagination";
import ManagePasswordPopup from "../components/popup/ManagePasswordPopup";
import { Tooltip } from "react-tooltip";
import EditUserPopup from "../components/popup/EditUserPopup";

const Users = () => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Assign Persons"
  );
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");
  const [status, setStatus] = useState("");
  const [emailVerified, setEmailVerified] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [users, setUsers] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const [isAdditionalAdministrator, setIsAdditionalAdministrator] = useState(2);
  const othersAction = {
    type: "button",
    link: "/",
    label: t("resend_invitation"),
    icon: "fa-envelope",
    isVisabled: 2,
  };
  const [actionValue, setActionValue] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [enabledPasswordManagePopup, setEnabledPasswordManagePopup] =
    useState(false);
  const [passwordInfo, setPasswordInfo] = useState({});

  const [isOpen, setIsopen] = useState({
    _id: null,
    open: false,
  });

  const extraData = [
    //(isAdditionalAdministrator && (isAdditionalAdministrator === 1)) &&
    {
      _id: 1,
      label: t("edit"),
      icon: "fa-pen-to-square",
      link: "/",
      type: "button",
      standout: false,
      buttonType: "edit-user-info",
      ordering: 1,
      isVisabled: 1,
    },
    {
      _id: 2,
      label: t("make_administrators"),
      icon: "fa-arrows-repeat",
      link: "/",
      type: "button",
      standout: false,
      buttonType: "make-administrators",
      isVisabled: [1, 2],
      ordering: 2,
    },
    role && role === "customer-admin"
      ? {
          _id: 3,
          label: t("update_password"),
          icon: "fa-key",
          link: "/",
          type: "button",
          standout: false,
          buttonType: "update-password",
          isVisabled: 1,
          ordering: 3,
        }
      : "",
  ];

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      setIsAdditionalAdministrator(user?.confirm_additional_admin);
    }
  }, [user]);

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    if (actionValue && actionValue.button_type === "status") {
      const loadStatusChange = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("assign/status-change", {
            id: actionValue?.row_id,
            status: actionValue?.current_status,
          });
          if (statusData.data) {
            setUsers(
              users.map((it) => {
                if (it?._id === statusData.data?._id) {
                  return {
                    ...it,
                    status: statusData.data?.status,
                  };
                } else {
                  return it;
                }
              })
            );
            setIsUpdate(true);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadStatusChange();
    } else if (actionValue && actionValue.button_type === "delete") {
      if (user?._id === actionValue?.row_id) {
        toastr.warning("Your account cannot be deleted by yourself.");
      } else {
        const loadDeleteRecord = async () => {
          setIsUpdate(false);
          try {
            const statusData = await postData("assign/delete", {
              id: actionValue?.row_id,
            });
            if (statusData.data) {
              const index = users.findIndex(
                (item) => item._id === actionValue?.row_id
              );
              if (index !== -1) {
                users.splice(index, 1);
                setUsers(
                  users.map((it) => {
                    return it;
                  })
                );
              }
              setIsUpdate(true);
            } else {
              toastr.error(statusData.message);
            }
          } catch (error) {
            toastr.error(error.message);
          }
        };
        loadDeleteRecord();
      }
    } else if (actionValue && actionValue.button_type === "others") {
      const loadStatusChange = async () => {
        try {
          const othersData = await postData("assign/resend-mail", {
            id: actionValue?.row_id,
          });
          if (othersData.data) {
            toastr.success(
              "Email resent.Please make sure to check your spam email."
            );
          } else {
            toastr.error(othersData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadStatusChange();
    } else if (
      actionValue &&
      actionValue.button_type === "make-administrators"
    ) {
      const loadStatusChange = async () => {
        setIsUpdate(false);
        try {
          const othersData = await postData("assign/make-administrators", {
            id: actionValue?.row_id,
            status: actionValue?.current_status,
          });
          if (othersData.data) {
            toastr.success(othersData.message);
            setIsUpdate(true);
          } else {
            toastr.error(othersData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadStatusChange();
    } else if (actionValue && actionValue.button_type === "update-password") {
      setEnabledPasswordManagePopup(true);
      const index = users.findIndex((item) => item._id === actionValue?.row_id);
      setPasswordInfo({
        _id: actionValue?.row_id,
      });
      setIsUpdate(false);
    } else if (actionValue && actionValue.button_type === "edit-user-info") {
      setIsopen({ open: true, _id: actionValue?.row_id });
    }
  }, [actionValue]);

  const loadList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await postData("assign/list", {
        keyword: keyword,
        limit: limit,
        offset: offset,
        sortQuery: sortQuery,
        email_verified: emailVerified,
        status: status,
        segment: params.get("user"),
        is_invited: 1,
      });
      if (result.data) {
        setUsers(
          result.data.map((value, key) => {
            let isAdmin = value?.role_details.filter(
              (itm) =>
                itm?.code === "customer-subadmin" ||
                itm?.code === "customer-admin"
            );
            return {
              _id: value?._id,
              rowData: [
                // { _id:1, width:40, type:'user', data:value?.creator_name,image:value?.creator_image },
                // { _id:2, width:40, type:'text', data:value?.creator_email },
                {
                  _id: 1,
                  width: 40,
                  type: "user",
                  data: value?.name || "N/A",
                  shortName: value?.name || "N / A",
                },
                {
                  _id: 2,
                  width: 15,
                  type: "text",
                  data: value?.email || "N/A",
                },
                {
                  _id: 3,
                  width: 15,
                  type: "text",
                  data: value?.title || "N/A",
                },
                {
                  _id: 4,
                  width: 20,
                  type: "text",
                  data:
                    value && value?.created_at
                      ? moment(new Date(value?.created_at)).format("ll")
                      : "N/A",
                },
                {
                  _id: 5,
                  width: 15,
                  type: "text",
                  data: (
                    <>
                      <div
                        className="inline-flex items-center"
                        data-tooltip-id={`email${value?._id}`}
                        data-tooltip-place="right"
                      >
                        <div
                          className={`flex items-center text-[10px] font-medium leading-tight rounded py-1 px-2 ${
                            value?.email_verified === 1
                              ? "bg-green-100 text-green-600"
                              : "bg-amber-100 text-amber-600"
                          }`}
                        >
                          <i className="fa-regular fa-envelope mr-1"></i>
                          {value?.email_verified === 1
                            ? t("verified")
                            : t("not_verified")}
                        </div>
                        <Tooltip
                          id={`email${value?._id}`}
                          className="!text-xs !bg-dark-teal-600 z-50 shadow-lg rounded py-3 px-6"
                          render={() => (
                            <div className="gap-x-1">
                              <div className="font-normal leading-none flex items-center">
                                <div className="font-semibold">
                                  {value?.email_verified === 1
                                    ? "User that has accepted the email invitation"
                                    : "User that has not yet accepted the email invitation"}
                                </div>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </>
                  ),
                },
                {
                  _id: 6,
                  width: 15,
                  type: "status",
                  statusLabel:
                    value?.status && value?.status === 1
                      ? t("active")
                      : t("deactivated"),
                  statusType:
                    value?.status && value?.status === 1
                      ? "success"
                      : "warning",
                  isTooltip: true,
                  toolTipData: (
                    <div className="flex flex-col items-start justify-center text-white gap-1">
                      {value?.name && (
                        <div className="font-semibold text-slate-200">
                          User that can be login into the successionnow portal
                        </div>
                      )}
                    </div>
                  ),
                  data: { _id: value?._id },
                },
                {
                  _id: 7,
                  width: 15,
                  type: "status",
                  statusLabel:
                    value?.conclusion_details &&
                    value?.conclusion_details.length > 0
                      ? t("completed")
                      : t("incompleted"),
                  statusType:
                    value?.conclusion_details &&
                    value?.conclusion_details.length > 0
                      ? "success"
                      : "danger",
                },
                {
                  _id: 8,
                  width: 15,
                  type: "status",
                  statusLabel:
                    isAdmin && isAdmin.length > 0 ? t("yes") : t("no"),
                  statusType:
                    isAdmin && isAdmin.length > 0 ? "success" : "danger",
                },
                {
                  _id: 9,
                  width: 10,
                  type: "action",
                  data: [
                    {
                      others: value?.email_verified,
                    },
                    {
                      "make-administrators":
                        value?._id === user?._id
                          ? 3
                          : isAdmin && isAdmin.length > 0
                          ? 2
                          : 1,
                    },
                    { "update-password": value?.email_verified },
                    { "edit-user-info": 1 },
                  ],
                  statusLabel:
                    value?.status && value?.status === 1
                      ? t("active")
                      : t("deactivated"),
                  statusType:
                    value?.status && value?.status === 1
                      ? "success"
                      : "warning",
                },
              ],
            };
          })
        );
      }
      setTotalDataCount(result.count);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  }, [keyword, limit, offset, sortQuery, emailVerified, status, isUpdate]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  // useEffect(() => {
  //   // User List
  //   const loadUsers = async () => {
  //     setLoading(true);
  //     try {
  //       const result = await postData("assign/list", {
  //         keyword: keyword,
  //         limit: limit,
  //         offset: offset,
  //         sortQuery: sortQuery,
  //         email_verified: emailVerified,
  //         status: status,
  //         segment: params.get("user"),
  //         is_invited: 1,
  //       });
  //       if (result.data) {
  //         setUsers(
  //           result.data.map((value, key) => {
  //             let isAdmin = value?.role_details.filter(
  //               (itm) =>
  //                 itm?.code === "customer-subadmin" ||
  //                 itm?.code === "customer-admin"
  //             );
  //             return {
  //               _id: value?._id,
  //               rowData: [
  //                 // { _id:1, width:40, type:'user', data:value?.creator_name,image:value?.creator_image },
  //                 // { _id:2, width:40, type:'text', data:value?.creator_email },
  //                 {
  //                   _id: 1,
  //                   width: 40,
  //                   type: "user",
  //                   data: value?.name || "N/A",
  //                   shortName: value?.name || "N / A",
  //                 },
  //                 {
  //                   _id: 2,
  //                   width: 15,
  //                   type: "text",
  //                   data: value?.email || "N/A",
  //                 },
  //                 {
  //                   _id: 3,
  //                   width: 15,
  //                   type: "text",
  //                   data: value?.title || "N/A",
  //                 },
  //                 {
  //                   _id: 4,
  //                   width: 20,
  //                   type: "text",
  //                   data:
  //                     value && value?.created_at
  //                       ? moment(new Date(value?.created_at)).format("ll")
  //                       : "N/A",
  //                 },
  //                 {
  //                   _id: 5,
  //                   width: 15,
  //                   type: "text",
  //                   data: (
  //                     <>
  //                       <div
  //                         className="inline-flex items-center"
  //                         data-tooltip-id={`email${value?._id}`}
  //                         data-tooltip-place="right"
  //                       >
  //                         <div
  //                           className={`flex items-center text-[10px] font-medium leading-tight rounded py-1 px-2 ${
  //                             value?.email_verified === 1
  //                               ? "bg-green-100 text-green-600"
  //                               : "bg-amber-100 text-amber-600"
  //                           }`}
  //                         >
  //                           <i className="fa-regular fa-envelope mr-1"></i>
  //                           {value?.email_verified === 1
  //                             ? t("verified")
  //                             : t("not_verified")}
  //                         </div>
  //                         <Tooltip
  //                           id={`email${value?._id}`}
  //                           className="!text-xs !bg-dark-teal-600 z-50 shadow-lg rounded py-3 px-6"
  //                           render={() => (
  //                             <div className="gap-x-1">
  //                               <div className="font-normal leading-none flex items-center">
  //                                 <div className="font-semibold">
  //                                   {value?.email_verified === 1
  //                                     ? "User that has accepted the email invitation"
  //                                     : "User that has not yet accepted the email invitation"}
  //                                 </div>
  //                               </div>
  //                             </div>
  //                           )}
  //                         />
  //                       </div>
  //                     </>
  //                   ),
  //                 },
  //                 {
  //                   _id: 6,
  //                   width: 15,
  //                   type: "status",
  //                   statusLabel:
  //                     value?.status && value?.status === 1
  //                       ? t("active")
  //                       : t("deactivated"),
  //                   statusType:
  //                     value?.status && value?.status === 1
  //                       ? "success"
  //                       : "warning",
  //                   isTooltip: true,
  //                   toolTipData: (
  //                     <div className="flex flex-col items-start justify-center text-white gap-1">
  //                       {value?.name && (
  //                         <div className="font-semibold text-slate-200">
  //                           User that can be login into the successionnow portal
  //                         </div>
  //                       )}
  //                     </div>
  //                   ),
  //                   data: { _id: value?._id },
  //                 },
  //                 {
  //                   _id: 7,
  //                   width: 15,
  //                   type: "status",
  //                   statusLabel:
  //                     value?.conclusion_details &&
  //                     value?.conclusion_details.length > 0
  //                       ? t("completed")
  //                       : t("incompleted"),
  //                   statusType:
  //                     value?.conclusion_details &&
  //                     value?.conclusion_details.length > 0
  //                       ? "success"
  //                       : "danger",
  //                 },
  //                 {
  //                   _id: 8,
  //                   width: 15,
  //                   type: "status",
  //                   statusLabel:
  //                     isAdmin && isAdmin.length > 0 ? t("yes") : t("no"),
  //                   statusType:
  //                     isAdmin && isAdmin.length > 0 ? "success" : "danger",
  //                 },
  //                 {
  //                   _id: 9,
  //                   width: 10,
  //                   type: "action",
  //                   data: [
  //                     {
  //                       others: value?.email_verified,
  //                     },
  //                     {
  //                       "make-administrators":
  //                         value?._id === user?._id
  //                           ? 3
  //                           : isAdmin && isAdmin.length > 0
  //                           ? 2
  //                           : 1,
  //                     },
  //                     { "update-password": value?.email_verified },
  //                     { "edit-user-info": 1 },
  //                   ],
  //                   statusLabel:
  //                     value?.status && value?.status === 1
  //                       ? t("active")
  //                       : t("deactivated"),
  //                   statusType:
  //                     value?.status && value?.status === 1
  //                       ? "success"
  //                       : "warning",
  //                 },
  //               ],
  //             };
  //           })
  //         );
  //       }
  //       setTotalDataCount(result.count);
  //       setLoading(false);
  //     } catch (err) {
  //       setLoading(false);
  //       console.log(err.message);
  //     }
  //   };
  //   loadUsers();
  // }, [keyword, limit, offset, sortQuery, emailVerified, status, isUpdate]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  };

  const tableHeadData = [
    // {_id:1, width:40, name:'Creator', value:'creator_name', align:'left', isSort:true, isFilter:false},
    // {_id:2, width:40, name:'Creator Email', value:'creator_email', align:'left', isSort:true, isFilter:false},
    {
      _id: 1,
      width: 40,
      name: t("name"),
      value: "name",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    {
      _id: 2,
      width: 15,
      name: t("email"),
      value: "email",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    {
      _id: 3,
      width: 15,
      name: t("position"),
      value: "title",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    {
      _id: 4,
      width: 20,
      name: t("invitation_sent"),
      value: "created_at",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 5,
      width: 15,
      name: t("verified"),
      value: "email_verified",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 6,
      width: 15,
      name: t("status"),
      value: "status",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 7,
      width: 15,
      name: t("succession_plan"),
      value: "complete_plan",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 8,
      width: 15,
      name: t("admin"),
      value: "complete_plan",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 9,
      width: 10,
      name: t("action"),
      value: "",
      align: "right",
      isSort: false,
      isFilter: false,
    },
  ];

  const handleOnDragEnd = async (result) => {
    const items = reorder(users, result.source.index, result.destination.index);
    setUsers(items);

    const res = await postData("position/ordering", {
      items: items,
    });
    if (res.status && res.status === 200) {
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="flex justify-between items-center mb-3">
        <div className="text-2xl font-bold">
          {t(
            "the_following_individuals_have_been_invited_to_create_succession_plans"
          ) +
            " - " +
            user?.company_name}
        </div>
        <div className="flex space-x-2 items-center">
          {/* {
            (role && (role ==='customer-admin' || role ==='customer-subadmin')) && 
            <div className="">
              <Button 
              buttonType={'button'} 
              buttonIcon={'fa-light fa-plus'} 
              buttonIconPosition={'left'} 
              buttonLabel={'Create Succession Plan'} 
              buttonHasLink={true}
              buttonLink={'/assigns'}
              />
            </div>
          } */}
        </div>
      </div>
      <div className="flex space-x-2 items-center">
        {/* <div className="relative">
              <Select 
              xPlacement={'left'} 
              dropdownButtonClass={'!bg-white'} 
              selectedValue={limit} 
              dropdownData={[
                  { _id: 1000000, label: 'All Items',value: 1000000 }, 
                  ...[{ _id: 10, label: '10 Items', value: 10 },
                      { _id: 20, label: '20 Items', value: 20 },
                      { _id: 30, label: '30 Items', value: 30 },
                      { _id: 50, label: '50 Items', value: 50 },
                      { _id: 100, label: '100 Items', value: 100 }]
              ]}
              getSelectedValue={(e) => setLimit(e.value)}
              />
          </div> */}
        {/* <div className="relative ml-auto flex justify-end items-center gap-3">
              <div className="relative">
              <Search 
              placeholder={'Search'} 
              setInput={ setKeyword } 
              />
              </div>
              <div className="relative">
              <Select 
              xPlacement={'right'} 
              dropdownButtonClass={'!bg-white'} 
              transitionClass={'!w-auto'}
              selectedValue={emailVerified} 
              dropdownData={[
                  { _id: '', label: 'Select Email Verification' },
                  { _id: 1, label: 'Verified', value: 1 },
                  { _id: 2, label: 'Not Verified', value: 2 },
              ]}
              getSelectedValue={(e) => setEmailVerified(e.value)}
              />
              </div>
              <div className="relative">
              <Select 
              xPlacement={'right'} 
              dropdownButtonClass={'!bg-white'} 
              transitionClass={'!w-auto'}
              selectedValue={status} 
              dropdownData={[
                  { _id: '', label: 'Select Status' },
                  { _id: 1, label: 'Active', value: 1 },
                  { _id: 2, label: 'Deactivated', value: 2 },
              ]}
              getSelectedValue={(e) => setStatus(e.value)}
              />
              </div>
          </div> */}
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="w-full rounded-md shadow">
          <Table
            tableData={users}
            tableHeadData={tableHeadData}
            totalRecord={totalDataCount}
            loading={loading}
            getSortValue={setSortValue}
            getSortKey={setSortKey}
            // editUrl={"/edit-user"}
            isStatusUpdate={true}
            isDeleteEnabled={true}
            getActionValue={(obj) => setActionValue(obj)}
            othersAction={othersAction}
            loginAs={"/login-as"}
            isDraggable={false}
            extraData={extraData}
          />
        </div>
      </DragDropContext>
      {noOfPage > 1 ? (
        <Pagination
          handlePageClick={handlePageClick}
          noOfPage={noOfPage}
          startDataCount={startDataCount}
          endDataCount={endDataCount}
          count={totalDataCount}
        />
      ) : (
        <div className="flex justify-center sm:justify-between my-4">
          {totalDataCount > 0 ? (
            <div>
              <p>
                {t("showing")}: {startDataCount} to {endDataCount} of{" "}
                {totalDataCount}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      {enabledPasswordManagePopup && (
        <ManagePasswordPopup
          isOpen={enabledPasswordManagePopup}
          setIsOpen={(val) => setEnabledPasswordManagePopup(val)}
          data={passwordInfo}
          getActionValue={(obj) => {
            setActionValue(obj);
            setEnabledPasswordManagePopup(false);
          }}
        />
      )}
      {isOpen?.open && (
        <EditUserPopup
          isOpen={isOpen?.open}
          id={isOpen?._id}
          setIsOpen={setIsopen}
          loadList={loadList}
        />
      )}
    </div>
  );
};

export default Users;
