import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import ReactPaginate from "react-paginate";
import { postData } from "../services/api";
import HeadingManagePopup from "../components/popup/HeadingManagePopup";
import NoDataFound from "../components/NoDataFound";
import Searching from "../components/Searching";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TableSkeleton from "../components/loader/TableSkeleton";
import { Tooltip } from "react-tooltip";

const ConcernSettings = () => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Concern Settings"
  );
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(1);
  const [sortQuery, setSortQuery] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [concernHeadings, setConcernHeadings] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [actionValue, setActionValue] = useState({});
  const [enabledPopup, setEnabledPopup] = useState(false);
  const [editData, setEditData] = useState({});
  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    const loadPosition = async () => {
      setLoading(true);
      try {
        const result = await postData("heading/list", {
          keyword: keyword,
          limit: limit,
          offset: offset,
          sortQuery: { created_at: 1 },
          status: 1,
          type: "concern",
        });
        if (result.data) {
          setConcernHeadings(result.data);
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadPosition();
  }, [limit, offset, actionValue]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
  };

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">
          {t("concern_settings") + " - " + user?.company_name}
        </div>
      </div>

      <div className="w-full overflow-auto scroll-smooth scrollbar bg-white rounded-md shadow min-h-[480px]">
        <table className="min-w-max w-full table-auto">
          <thead>
            <tr className="text-white font-bold text-xs leading-none border-b border-slate-200">
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tl-md">
                {t("factor")}
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">
                {t("description")}
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tr-md">
                {t("action")}
              </th>
            </tr>
          </thead>
          <tbody className="text-slate-700 text-sm font-light bg-white">
            {totalDataCount > 0 ? (
              concernHeadings.map((item, index) => (
                <tr className="border-b border-slate-200" key={index}>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <div className="block">
                      <div className="text-sm font-semibold text-slate-600">
                        {item?.heading_name ? item?.heading_name : item?.name}
                      </div>
                    </div>
                  </td>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <div className="block">
                      <div className="text-xs text-slate-400">
                        {item?.heading_description
                          ? item?.heading_description
                          : item?.description}
                      </div>
                    </div>
                  </td>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <div
                      className=""
                      data-tooltip-id={`editButton${item?._id}`}
                      data-tooltip-place="right"
                    >
                      <Button
                        buttonClasses={
                          "!p-0 !flex !items-center !justify-center !text-[14px] !bg-transparent !text-dark-teal-600 !w-8 !h-8 !font-bold"
                        }
                        buttonType={"button"}
                        buttonIcon={"fa-regular fa-pencil"}
                        buttonIconPosition={"left"}
                        buttonHasLink={false}
                        functions={() => {
                          setEnabledPopup(true);
                          setEditData(item);
                        }}
                      />
                      {/* <Input
                      labelClasses={"!text-xs"}
                      inputClasses={"!text-red-500 font-semibold"}
                      inputType={"number"}
                      inputPlaceholder={"Enter Weight"}
                      inputValue={weightValue[index].value || ''}
                      setInput={(val) => handelUpdate(item._id, index, val)}
                    /> */}
                      <Tooltip
                        id={`editButton${item?._id}`}
                        className="!text-xs !bg-dark-teal-600 z-50 rounded py-3 px-6"
                        render={() => (
                          <div className="gap-x-1 gap-y-1">
                            <div className="text-[13px] font-normal leading-none flex items-center">
                              <div className="font-semibold">
                                {`Click To Edit Concern (${
                                  item?.heading_name
                                    ? item?.heading_name
                                    : item?.name
                                })`}
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="border-b border-slate-200">
                <td className="py-3 px-2 text-left" colSpan={3}>
                  {loading ? (
                    <TableSkeleton tableImage={false} />
                  ) : (
                    <NoDataFound label={t("no_data_found")} />
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {noOfPage > 1 && (
        <div className="flex justify-center sm:justify-end my-4">
          <ReactPaginate
            breakLabel="..."
            breakClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            nextLabel={<i className="fa-regular fa-fw fa-chevron-right"></i>}
            nextClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={noOfPage}
            containerClassName="flex justify-center items-center text-slate-80 text-sm font-medium mt-8 lg:mt-0 space-x-2"
            activeClassName="hover:bg-teal-500 bg-teal-500 text-white"
            pageLinkClassName="flex justify-center items-center w-full h-full"
            previousLabel={<i className="fa-regular fa-fw fa-chevron-left"></i>}
            pageClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            previousClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            renderOnZeroPageCount={null}
          />
        </div>
      )}
      {enabledPopup && (
        <HeadingManagePopup
          isOpen={enabledPopup}
          setIsOpen={(val) => setEnabledPopup(val)}
          data={editData}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
          type={"concern"}
        ></HeadingManagePopup>
      )}
    </>
  );
};

export default ConcernSettings;
