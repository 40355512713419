import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { classNames } from "../../helper/classNames";
import Button from "../../components/form/Button";
import Image from "../../components/elements/Image";
import { postData } from "../../services/api";
import Tabs from "../../components/elements/Tab";
import user from "../../assets/image/user.jpg";
import SubscriptionsList from "../../components/section/companyDetails.js/SubscriptionsList";
import AllEmployees from "../../components/section/companyDetails.js/AllEmployees";
import AllCompanyTransction from "../../components/section/companyDetails.js/AllCompanyTransction";
import { Tooltip } from "react-tooltip";
import AllUsersList from "../../components/section/companyDetails.js/AllUsersList";
import AllExecutivesList from "../../components/section/companyDetails.js/AllExecutivesList";
import CompanyPositionList from "../../components/section/companyDetails.js/CompanyPositionList";
import CompanyBoardMember from "../../components/section/companyDetails.js/CompanyBoardMember";

const ViewCompanyDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleReadMore = () => setIsExpanded(!isExpanded);

  const [details, setDetails] = useState({});

  useEffect(() => {
    const loadDetails = async () => {
      try {
        const result = await postData("usertype/details", {
          id: id,
        });
        if (result.data) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, []);

  const text = `
      Contrary to popular belief, Lorem Ipsum is not simply random text. 
      It has roots in a piece of classical Latin literature from 45 BC, 
      making it over 2000 years old. Richard McClintock, a Latin professor 
      at Hampden-Sydney College in Virginia, looked up one of the more 
      obscure Latin words, consectetur, from a Lorem Ipsum passage, and going 
      through the cites of the word in classical literature, discovered the 
      undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 
      of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by 
      Cicero, written in 45 BC. This book is a treatise on the theory of ethics, 
      very popular.
    `;

  const data = [
    {
      id: 1035,
      type: 2,
      role: "user",
      name: "Prokash Malo",
      email: "mailto:prokashmalo981@gmail.com",
      encode_password: "UHJva2FzaEAxMjM=",
      phone: 8965896589,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-27T07:18:20.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 1034,
      type: 2,
      role: "user",
      name: "Zara Hart",
      email: "mailto:zarahart@gmail.com",
      encode_password: "WmFyYUAzMjE=",
      phone: null,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-20T12:42:36.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 1033,
      type: 2,
      role: "user",
      name: "Smritikana Banerjee",
      email: "mailto:smritikana@symlinktech.com",
      encode_password: "QWRtaW5AMTIz",
      phone: 8282828282,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-12T09:52:30.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 1027,
      type: 2,
      role: "user",
      name: "Trayee Ghosh",
      email: "mailto:trayee@gmail.com",
      encode_password: "VXNlckAxMjM=",
      phone: null,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-08T09:50:23.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 1026,
      type: 2,
      role: "user",
      name: "Pilu Malo ",
      email: "mailto:pilu@gmail.com",
      encode_password: "VXNlckAxMjM=",
      phone: 785545454545,
      phonecode: null,
      phoneNumber: null,
      bday: null,
      active: 1,
      created_at: "2024-08-08T09:21:38.000Z",
      image_name_url:
        "https://plus.unsplash.com/premium_photo-1676660359316-273bb172a0df?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8d29tYW4lMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
  ];

  const tabs = [
    {
      _id: "tab1",
      name: "Users",
      component: <AllUsersList data={details} />,
    },
    {
      _id: "tab2",
      name: "Employees",
      component: <AllEmployees data={data} />,
    },
    {
      _id: "tab3",
      name: "Executives",
      component: <AllExecutivesList data={details} />,
    },
    {
      _id: "tab4",
      name: "Board Members",
      component: <CompanyBoardMember data={details} />,
    },
    {
      _id: "tab5",
      name: "Positions",
      component: <CompanyPositionList data={details} />,
    },
    {
      _id: "tab6",
      name: "Subscriptions",
      component: <SubscriptionsList />,
    },
    {
      _id: "tab7",
      name: "Transctions",
      component: <AllCompanyTransction />,
    },
  ];

  const patternAfter =
    "after:content-[''] after:absolute after:z-[-1] after:w-1/2 after:h-40 after:bg-amber-500/15 after:-bottom-1/2 after:-right-10 after:rotate-[-15deg]";
  const patternBefore =
    "before:content-[''] before:absolute before:z-[-1] before:w-60 before:h-80 before:bg-cyan-500/20 before:-top-20 before:-right-40 before:rotate-[-15deg]";

  const capitalizeString = (str) => {
    return str?.replace(/\b\w/g, function (char) {
      return char?.toUpperCase();
    });
  };

  const viewToolTip = (data) => {
    return (
      <>
        <Tooltip
          id={`toolTip${data?.id}`}
          className="!text-xs !bg-dark-teal-600 z-50 rounded px-6"
          render={() => (
            <div className="gap-x-1">
              <div className="text-[13px] font-normal leading-none  flex items-center">
                <div className="font-semibold">{data?.name}</div>
              </div>
            </div>
          )}
        />
      </>
    );
  };

  return (
    <div className="flex flex-row space-x-4 w-full pb-6">
      <div className="w-full relative z-0">
        <div className="w-full mx-auto space-y-10">
          <div
            className={classNames(
              "relative w-full md:flex block lg:space-x-10 md:space-x-5 space-x-0 bg-white lg:px-10 px-5 py-8 rounded-md shadow border border-slate-300 items-start z-0 overflow-hidden",
              patternBefore,
              patternAfter
            )}
          >
            <div className="flex justify-center">
              <div className="4xl:w-40 3xl:w-36 lg:w-44 md:w-36 w-32 !aspect-square overflow-hidden rounded-lg flex-shrink-0 flex border border-slate-200">
                <Image
                  src={details?.image}
                  effect={"blur"}
                  className="object-cover"
                />
              </div>
            </div>
            <div className="flex-shrink flex-grow space-y-4">
              <div className="md:flex block justify-between">
                <div className="flex gap-4 items-center lg:justify-start justify-center">
                  <div className="text-dark-teal-500 font-bold md:text-3xl text-2xl">
                    {details?.company_name || "N/A"}
                  </div>
                  <div
                    className={`text-[10px] font-medium leading-tight rounded py-1 px-2  ${
                      details?.account_type === "business"
                        ? "bg-violet-100 text-violet-600"
                        : "bg-orange-100 text-orange-600"
                    }`}
                  >
                    {details?.account_type &&
                      capitalizeString(details?.account_type)}
                  </div>
                </div>
                <div className="flex items-center mt-2 cursor-pointer">
                  <Button
                    buttonLabel={"Administrator Login"}
                    buttonClasses={`!w-full !flex !items-center !justify-start !py-2 !px-3 !gap-3  !rounded-md !text-base !font-medium !border !border-dark-teal-500 !bg-dark-teal-500 !text-slate-100`}
                    buttonType={"button"}
                    buttonIcon={"fa-solid fa-arrow-right-to-arc"}
                    buttonIconPosition={"left"}
                    buttonHasLink={false}
                    buttonLabelClasses={"!text-base !font-semibold"}
                    functions={() => navigate(`/login-as?user=${details?._id}`)}
                  />
                </div>
              </div>
              <div className="space-y-1">
                <div
                  className="flex items-start gap-2 w-full"
                  data-tooltip-id={`toolTip${1}`}
                  data-tooltip-place="left"
                >
                  <div className="text-base text-dark-teal-500 flex-shrink-0 ">
                    <i className="fa-regular fa-user"></i>
                  </div>
                  <div className="flex-shrink flex-grow">
                    <div className="text-sm text-slate-500">
                      {details?.name || "N/A"}
                    </div>
                  </div>
                  {viewToolTip({ id: 1, name: "Administrator Name" })}
                </div>
                <div
                  className="flex gap-2 items-start w-full"
                  data-tooltip-id={`toolTip${2}`}
                  data-tooltip-place="left"
                >
                  <div className="text-base text-dark-teal-500 flex-shrink-0">
                    <i class="fa-regular fa-fw fa-envelope"></i>
                  </div>
                  <div className="flex-shrink flex-grow">
                    <div className="text-sm mailto:text-slate-500">
                      {details?.email || "N/A"}
                    </div>
                  </div>
                  {viewToolTip({ id: 2, name: "Administrator Email" })}
                </div>
                <div
                  className="flex gap-2 items-start w-full"
                  data-tooltip-id={`toolTip${3}`}
                  data-tooltip-place="left"
                >
                  <div className="text-base text-dark-teal-500 flex-shrink-0">
                    <i class="fa-regular fa-fw fa-phone"></i>
                  </div>
                  <div className="flex-shrink flex-grow">
                    <div className="text-sm text-slate-500">
                      {" "}
                      {details?.phone || "N/A"}
                    </div>
                  </div>
                  {viewToolTip({ id: 3, name: "Administrator Phone No" })}
                </div>
                <div
                  className="w-full flex gap-2 items-center "
                  data-tooltip-id={`toolTip${4}`}
                  data-tooltip-place="left"
                >
                  <div className="text-base text-dark-teal-500 flex-shrink-0">
                    <i className="fa-regular fa-apartment"></i>
                  </div>
                  <div className="flex-shrink flex-grow">
                    <div className="text-sm text-slate-500">
                      {" "}
                      {details?.position_name || "N/A"}
                    </div>
                  </div>
                  {viewToolTip({ id: 4, name: "Administrator Position" })}
                </div>
                <div
                  className="w-full flex gap-2 items-center"
                  data-tooltip-id={`toolTip${5}`}
                  data-tooltip-place="left"
                >
                  <div className="text-base text-dark-teal-500 flex-shrink-0">
                    <i className="fa-solid fa-ticket"></i>
                  </div>
                  <div className="flex-shrink flex-grow">
                    <div className="text-sm text-slate-500">
                      {" "}
                      {details?.promo_code || "N/A"}
                    </div>
                  </div>
                  {viewToolTip({ id: 5, name: "Promo Code" })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-5 gap-6 mt-4">
          <div className="col-span-5 space-y-4">
            <div className="relative space-y-3">
              <Tabs
                tabs={tabs}
                navTabClass="w-full m-auto"
                divClass="w-full bg-transparent shadow-none gap-5"
                buttonClass="w-full rounded-md !text-dark-teal-500 border border-dark-teal-500"
                tabContentClass={"xs:!px-0 lg:!px-0 -mt-9"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCompanyDetails;
