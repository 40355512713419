import React, { useState, useEffect, useCallback } from "react";
import { setTitle } from "../helpers/MetaTag";
import Input from "../components/form/Input";
import Select from "../components/form/Select";
import Button from "../components/form/Button";
import Checkbox from "../components/form/Checkbox";
import Textarea from "../components/form/Textarea";
import toastr from "toastr";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { postData } from "../services/api";
import { useSelector } from "react-redux";
import OtherPositionPopup from "../components/popup/OtherPositionPopup";
import OtherStudyPopup from "../components/popup/OtherStudyPopup";
import OtherIndustryPopup from "../components/popup/OtherIndustryPopup";
import OtherAreaExpertisePopup from "../components/popup/OtherAreaExpertisePopup";
import SurveyPopup from "../components/popup/SurveyPopup";
import { PDFDownloadLink } from "@react-pdf/renderer";
import BoardMemberReport from "./pdf/BoardMemberReport";

import { useTranslation } from "react-i18next";

const Boardroom = () => {
  const { id } = useParams();
  if (id) {
    setTitle("Succession Now | Edit Boardroom");
  } else {
    setTitle("Succession Now | Add Boardroom");
  }
  const { i18n, t } = useTranslation();

  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(true);
  const [position, setPosition] = useState([]);
  const [boardYear, setBoardYear] = useState([]);
  const [education, setEducation] = useState([]);
  const [studies, setStudies] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [areaOfExpertise, setAreaOfExpertise] = useState([]);
  const [managementLevel, setManagementLevel] = useState([]);
  const [retirementWindow, setRetirementWindow] = useState([]);
  const [neededDevelopment, setNeededdevelopment] = useState([]);
  const [advaceDesire, setAdvanceDesire] = useState([]);
  const [enabledOtherPositionPopup, setEnabledOtherPositionPopup] =
    useState(false);
  const [enabledOtherStudyPopup, setEnabledOtherStudyPopup] = useState(false);
  const [enabledOtherIndustryPopup, setEnabledOtherIndustryPopup] =
    useState(false);
  const [enabledOtherAreaExpertisePopup, setEnabledOtherAreaExpertisePopup] =
    useState(false);
  const [actionValue, setActionValue] = useState({});
  const [isSave, setIsSave] = useState(false);
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [yearofboard, setYearofboard] = useState("");
  const [designation, setDesignation] = useState("");
  const [prevDesignation, setPrevDesignation] = useState("");
  const [prevDesignation2, setPrevDesignation2] = useState("");
  const [prevDesignation3, setPrevDesignation3] = useState("");
  const [edu, setEdu] = useState("");
  const [study, setStudy] = useState("");
  const [industry, setIndustry] = useState("");
  const [primaryExpertise, setPrimaryExpertise] = useState("");
  const [primaryExp, setPrimaryExp] = useState("");
  const [secondaryExpertise, setSecondaryExpertise] = useState("");
  const [secondaryExp, setSecondaryExp] = useState("");
  const [mLevel, setMLevel] = useState("");
  const [managementExp, setManagementExp] = useState("");
  const [retirement, setRetirement] = useState("");
  const [nDevelopment, setNDevelopment] = useState("");
  const [desire, setDesire] = useState("");
  const [email, setEmail] = useState("");
  const [isSendmail, setIsSendMail] = useState(2);
  const [isPositionNotApplicable, setIsPositionNotApplicable] = useState(2);

  const [positionType, setPositionType] = useState(0);
  const [areaExpertiseType, setAreaExpertiseType] = useState(0);

  const [boardMambers, setBoardMambers] = useState([]);
  const [boardMambersDetails, setBoardMambersDetails] = useState({});
  const [detailsId, setDetailsId] = useState("");
  const [reatingOptions, setReatingOptions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [enabledSurveyPopup, setEnabledSurveyPopup] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  const [autoSave, setAutoSave] = useState(false);

  useEffect(() => {
    if (role && (role === "customer-subadmin" || role === "customer-user")) {
      return navigate("/dashboard");
    }
    if (role && role === "customer-boardmember") {
      // Board Member List
      const loadBoardMemberDetails = async () => {
        setLoading(true);
        try {
          const result = await postData("board-member/details", {
            id: user?._id,
          });
          if (result.data) {
            setBoardMambersDetails(result.data);
          }
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.log(err.message);
        }
      };
      loadBoardMemberDetails();
    }

    async function employeeRatingOptionList() {
      try {
        const result = await postData("employee-rating-option/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          limit: 50,
          type: "boardmember",
        });
        if (result.data) {
          if (detailsId) {
            if (role && role !== "customer-boardmember") {
              setReatingOptions(result.data);
              setAnswers(
                result.data.map((value) => {
                  return {
                    questionId: value?.question?._id,
                    answerId: null,
                    answerLabel: null,
                    rating: 0,
                    comments: null,
                  };
                })
              );
            } else if (role && role === "customer-boardmember") {
              setReatingOptions(result.data);
              setAnswers([]);
            }
          } else if (role && role === "customer-boardmember") {
            setReatingOptions(result.data);
            setAnswers([]);
          } else {
            setReatingOptions([]);
            setAnswers([]);
          }
        } else {
          console.log("Advance desire message", result.message);
        }
      } catch (error) {
        console.log("Error advance desire catch", error.message);
      }
    }
    employeeRatingOptionList();
  }, [role]);

  useEffect(() => {
    if (Object.keys(boardMambersDetails).length > 0 && !autoSave) {
      const allRatingsAreZero = boardMambersDetails?.ratingOptions.every((item) => item.rating === 0);
      if (allRatingsAreZero && role === "customer-boardmember") {
        setEnabledSurveyPopup(true);
      }
    }
  }, [boardMambersDetails, answers,role]);

  // const popupAction = useCallback(() => {
  //   console.log({actionValue})
  //   if(actionValue && actionValue.button_type === "study_add"){
  //     setStudies([...studies,actionValue?.result]);
  //     setStudy(actionValue.row_id);
  //   }else if(actionValue && actionValue.button_type === "industry_add"){
  //     setIndustries([...industries,actionValue?.result]);
  //     setIndustry(actionValue.row_id);
  //   }else if(actionValue && actionValue.button_type === "area_add"){
  //     setAreaOfExpertise([...areaOfExpertise,actionValue?.result]);
  //     if(areaExpertiseType && areaExpertiseType === 1){
  //       setPrimaryExpertise(actionValue?.row_id)
  //     } else if(areaExpertiseType && areaExpertiseType === 2){
  //       setSecondaryExpertise(actionValue?.row_id);
  //     }
  //     setAreaExpertiseType(0);
  //   }else if(actionValue && actionValue.button_type === "update-survey"){
  //     setReatingOptions(actionValue.reatingOptions);
  //     setAnswers(actionValue.answers);
  //   } else if(actionValue){
  //     setPosition([...position,actionValue])
  //     if(positionType && positionType === 1){
  //       setDesignation(actionValue?._id)
  //     }else if(positionType && positionType === 2){
  //       setPrevDesignation(actionValue?._id);
  //     }else if(positionType && positionType === 3){
  //       setPrevDesignation2(actionValue?._id);
  //     }else if(positionType && positionType === 4){
  //       setPrevDesignation3(actionValue?._id);
  //     }
  //     setPositionType(0);
  //   }
  // }, [actionValue])

  // useEffect(() => {
  //   popupAction()
  // }, [actionValue])

  useEffect(() => {
    if (actionValue && actionValue.button_type === "study_add") {
      setStudies([...studies, actionValue?.result]);
      setStudy(actionValue.row_id);
    } else if (actionValue && actionValue.button_type === "industry_add") {
      setIndustries([...industries, actionValue?.result]);
      setIndustry(actionValue.row_id);
    } else if (actionValue && actionValue.button_type === "area_add") {
      setAreaOfExpertise([...areaOfExpertise, actionValue?.result]);
      if (areaExpertiseType && areaExpertiseType === 1) {
        setPrimaryExpertise(actionValue?.row_id);
      } else if (areaExpertiseType && areaExpertiseType === 2) {
        setSecondaryExpertise(actionValue?.row_id);
      }
      setAreaExpertiseType(0);
    } else if (actionValue && actionValue.button_type === "update-survey") {
      setAnswers(actionValue.answers);
      if (
        role === "customer-boardmember" &&
        actionValue.autoSave &&
        actionValue.autoSave === true
      ) {
        setAutoSave(actionValue.autoSave);
      } else {
        setReatingOptions(actionValue.reatingOptions);
        setIsCalling(true);
      }
    } else if (actionValue) {
      setPosition([...position, actionValue]);
      if (positionType && positionType === 1) {
        setDesignation(actionValue?._id);
      } else if (positionType && positionType === 2) {
        setPrevDesignation(actionValue?._id);
      } else if (positionType && positionType === 3) {
        setPrevDesignation2(actionValue?._id);
      } else if (positionType && positionType === 4) {
        setPrevDesignation3(actionValue?._id);
      }
      setPositionType(0);
    }
  }, [actionValue]);

  useEffect(() => {
    if (autoSave) {
      onSubmit();
    }
  }, [autoSave]);

  useEffect(() => {
    if (Object.keys(boardMambersDetails).length > 0) {
      setDetailsId(boardMambersDetails?._id);
      setLastname(boardMambersDetails?.last_name);
      setFirstname(boardMambersDetails?.first_name);
      setYearofboard(boardMambersDetails?.board_year);
      setDesignation(boardMambersDetails?.position);
      if (boardMambersDetails?.isPositionNotApplicable === 1) {
        setPrevDesignation("not_applicable");
      } else {
        setPrevDesignation(boardMambersDetails?.previous_position);
      }
      setPrevDesignation2(boardMambersDetails?.previous_position_two);
      setPrevDesignation3(boardMambersDetails?.previous_position_three);
      setEdu(boardMambersDetails?.education);
      setStudy(boardMambersDetails?.study);
      setIndustry(boardMambersDetails?.industry);
      setPrimaryExpertise(boardMambersDetails?.primary_expertise);
      setPrimaryExp(boardMambersDetails?.primary_experience);
      setSecondaryExpertise(boardMambersDetails?.secondary_expertise);
      setSecondaryExp(boardMambersDetails?.secondary_experience);
      setMLevel(boardMambersDetails?.management_level);
      setManagementExp(boardMambersDetails?.management_experience);
      setRetirement(boardMambersDetails?.retirement_window);
      setNDevelopment(boardMambersDetails?.needed_development);
      setDesire(boardMambersDetails?.desire);
      if (
        boardMambersDetails?.ratingOptions &&
        boardMambersDetails?.ratingOptions.length > 0
      ) {
        if (
          boardMambersDetails?.ratingOptions.filter((it) => !it.answerId)
            .length === boardMambersDetails?.ratingOptions.length
        ) {
          setReatingOptions([]);
          setAnswers([]);
        } else {
          setAnswers(
            reatingOptions.map((value) => {
              let _question = boardMambersDetails?.ratingOptions.filter(
                (it) => it?.questionId === value?.question?._id
              );
              if (_question.length > 0) {
                return {
                  ..._question[0],
                };
              } else {
                return {
                  questionId: value?.question?._id,
                  answerId: null,
                  answerLabel: null,
                  rating: 0,
                  comments: null,
                };
              }
            })
          );
        }
      } else {
        setAnswers(
          reatingOptions.map((value) => {
            return {
              questionId: value?.question?._id,
              answerId: null,
              answerLabel: null,
              rating: 0,
              comments: null,
            };
          })
        );
      }
      //setIsCalling(false);
      setIsPositionNotApplicable(boardMambersDetails?.isPositionNotApplicable);
    }
  }, [boardMambersDetails, isCalling]);

  useEffect(() => {
    async function positionData() {
      try {
        const result = await postData("position/list", {
          sortQuery: { ordering: 1 },
          isBoardMember: 1,
          status: 1,
        });
        if (result.data) {
          setPosition(result.data);
        } else {
          console.log("Position list message", result.message);
        }
      } catch (error) {
        console.log("Error position list catch", error.message);
      }
    }
    positionData();

    async function boardYearData() {
      try {
        const result = await postData("experience/list", {
          sortQuery: { ordering: -1 },
          type: 4,
          status: 1,
          is_visabled: 1,
        });
        if (result.data) {
          setBoardYear(result.data);
        } else {
          console.log("Retirement window list message", result.message);
        }
      } catch (error) {
        console.log("Error retirement window list catch", error.message);
      }
    }
    boardYearData();

    async function educationList() {
      try {
        const result = await postData("education/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          isBoardMember: 1,
        });
        if (result.data) {
          setEducation(result.data);
        } else {
          console.log("Education message", result.message);
        }
      } catch (error) {
        console.log("Error education catch", error.message);
      }
    }
    educationList();

    async function studyList() {
      try {
        const result = await postData("study/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setStudies(result.data);
        } else {
          console.log("Education message", result.message);
        }
      } catch (error) {
        console.log("Error education catch", error.message);
      }
    }
    studyList();

    async function industryList() {
      try {
        const result = await postData("industry/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setIndustries(result.data);
        } else {
          console.log("Industry message", result.message);
        }
      } catch (error) {
        console.log("Error education catch", error.message);
      }
    }
    industryList();

    async function areaExpertiseList() {
      try {
        const result = await postData("area-of-expertise/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setAreaOfExpertise(result.data);
        } else {
          console.log("Industry message", result.message);
        }
      } catch (error) {
        console.log("Error education catch", error.message);
      }
    }
    areaExpertiseList();

    async function managementLevelList() {
      try {
        const result = await postData("filter-item/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          type: "management-level",
        });
        if (result.data) {
          setManagementLevel(result.data);
        } else {
          console.log("Industry message", result.message);
        }
      } catch (error) {
        console.log("Error education catch", error.message);
      }
    }
    managementLevelList();

    async function retimentList() {
      try {
        const result = await postData("filter-item/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          type: "retirement-window",
        });
        if (result.data) {
          setRetirementWindow(result.data);
        } else {
          console.log("Industry message", result.message);
        }
      } catch (error) {
        console.log("Error education catch", error.message);
      }
    }
    retimentList();

    async function neededDevelopmentList() {
      try {
        const result = await postData("filter-item/list", {
          sortQuery: { created_at: 1 },
          type: "needed-development",
          status: 1,
        });
        if (result.data) {
          setNeededdevelopment(result.data);
        } else {
          console.log("Needed development message", result.message);
        }
      } catch (error) {
        console.log("Error needed development catch", error.message);
      }
    }
    neededDevelopmentList();

    async function advanceDesireList() {
      try {
        const result = await postData("desire/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          type: "boardmember",
        });
        if (result.data) {
          setAdvanceDesire(result.data);
        } else {
          console.log("Advance desire message", result.message);
        }
      } catch (error) {
        console.log("Error advance desire catch", error.message);
      }
    }
    advanceDesireList();

    // Board Member List
    const loadBoardMembers = async () => {
      try {
        const result = await postData("board-member/list", {
          sortQuery: { created_at: 1 },
          is_interim: 2,
        });
        if (result.data) {
          setBoardMambers(result.data);
        }
        //setTotalDataCount(result.count);
      } catch (err) {
        console.log(err.message);
      }
    };
    if (role && role !== "customer-boardmember") {
      loadBoardMembers();
    }
  }, []);

  const onSubmit = async (e) => {
    setIsSave(true);
    try {
      let path;
      let payload;
      if (detailsId) {
        path = "board-member/update-all";
        payload = {
          id: detailsId,
          first_name: firstname,
          last_name: lastname,
          board_year: yearofboard,
          position: designation !== "others" ? designation : null,
          previous_position:
            prevDesignation !== "others" ? prevDesignation : null,
          previous_position_two:
            prevDesignation2 !== "others" ? prevDesignation2 : null,
          previous_position_three:
            prevDesignation3 !== "others" ? prevDesignation3 : null,
          education: edu,
          study: study !== "others" ? study : null,
          industry: industry !== "others" ? industry : null,
          primary_expertise:
            primaryExpertise !== "others" ? primaryExpertise : null,
          primary_experience: primaryExp,
          secondary_expertise:
            secondaryExpertise !== "others" ? secondaryExpertise : null,
          secondary_experience: secondaryExp,
          management_level: mLevel,
          management_experience: managementExp,
          retirement_window: retirement,
          needed_development: nDevelopment,
          desire: desire,
          isPositionNotApplicable: isPositionNotApplicable,
          ratingOptions: answers,
        };
      } else {
        path = "board-member/create";
        payload = {
          first_name: firstname,
          last_name: lastname,
          board_year: yearofboard,
          position: designation !== "others" ? designation : null,
          previous_position:
            prevDesignation !== "others" ? prevDesignation : null,
          previous_position_two:
            prevDesignation2 !== "others" ? prevDesignation2 : null,
          previous_position_three:
            prevDesignation3 !== "others" ? prevDesignation3 : null,
          education: edu,
          study: study !== "others" ? study : null,
          industry: industry !== "others" ? industry : null,
          primary_expertise:
            primaryExpertise !== "others" ? primaryExpertise : null,
          primary_experience: primaryExp,
          secondary_expertise:
            secondaryExpertise !== "others" ? secondaryExpertise : null,
          secondary_experience: secondaryExp,
          management_level: mLevel,
          management_experience: managementExp,
          retirement_window: retirement,
          needed_development: nDevelopment,
          desire: desire,
          // email : email,
          // isSendmail : isSendmail,
          isPositionNotApplicable: isPositionNotApplicable,
          ratingOptions: answers,
        };
      }
      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsSave(false);
        setAutoSave(false);
        if (!detailsId) {
          return navigate("/board-members");
        }
      } else {
        toastr.error(result.message);
        setIsSave(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsSave(false);
    }
  };

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  const handelUpdate = async (question_id, answer_id, rating, label, index) => {
    setAnswers((answers) =>
      answers.map((item, i) => {
        if (item?.questionId === question_id) {
          if (answer_id === "comments") {
            return {
              ...item,
              comments: rating,
            };
          } else {
            return {
              ...item,
              answerId: answer_id,
              answerLabel: label,
              rating: rating,
            };
          }
        } else {
          return item;
        }
      })
    );
  };

  return (
    <>
      <div className="relative d-flex justify-between items-center mb-4">
        <div className="text-3xl text-slate-800 font-bold">
          {id ? t("edit_boardroom") : t("board_member")}{" "}
        </div>
      </div>
      <div className="relative grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <div className="relative bg-white rounded shadow divide-y divide-slate-200">
            <div className="py-8 px-6 flex flex-col">
              <div className="space-y-4 mt-4">
                {/* {
                !detailsId &&
                <>
                  <div className="flex items-start gap-4">
                    <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                      <Input 
                      label={t('email')} 
                      labelClasses={'!text-xs !font-semibold'} 
                      inputType={'text'}                   
                      inputPlaceholder={t('enter_email')}
                      inputValue={email}  
                      setInput={setEmail} 
                      />
                    </div>
                    <div className="relative w-1/4 max-w-[calc(25%-1rem)] mt-6">
                      <Checkbox 
                        checkboxLabel={t('invite_board_member')}
                        checkboxLableClass={""}
                        isChecked={(isSendmail && isSendmail ===1)?true:false}
                        checkboxValue={'send_mail'}
                        getCheckboxValue={(code,event) => {
                          if(event.target.checked === true ){
                            setIsSendMail(1)                            
                          }else{
                            setIsSendMail(2)
                          }                            
                        }}
                      />
                    </div>
                  </div>  
                  <hr className='mt-3'></hr>
                </>
              }*/}
                <div className="flex items-start gap-4">
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Input
                      label={t("last_name")}
                      labelClasses={"!text-xs !font-semibold"}
                      inputType={"text"}
                      inputPlaceholder={t("enter_last_name")}
                      inputValue={lastname}
                      setInput={setLastname}
                    />
                  </div>
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Input
                      label={t("first_name")}
                      labelClasses={"!text-xs !font-semibold"}
                      inputType={"text"}
                      inputPlaceholder={t("enter_first_name")}
                      inputValue={firstname}
                      setInput={setFirstname}
                    />
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Select
                      label={t("years_on_the_board")}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={yearofboard}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        {
                          _id: " ",
                          label: t("select_years_on_the_board"),
                          value: "",
                        },
                        ...boardYear.map((item) => ({
                          _id: item?._id,
                          label: item?.name,
                          value: item?._id,
                        })),
                      ].filter((itm) => itm)}
                      getSelectedValue={(e) => {
                        setYearofboard(e._id);
                      }}
                    />
                  </div>
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Select
                      label={t("board_retirement_window")}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={retirement}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        {
                          _id: " ",
                          label: t("select_retirement_window"),
                          value: "",
                        },
                        ...retirementWindow.map((item) => ({
                          _id: item?._id,
                          label: item?.name,
                          value: item?._id,
                        })),
                      ].filter((itm) => itm)}
                      getSelectedValue={(e) => {
                        setRetirement(e._id);
                      }}
                    />
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Select
                      label={t("current_board_position_held")}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={designation}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        { _id: " ", label: t("select_position"), value: "" },
                        ...position.map((item) => ({
                          _id: item?._id,
                          label: item?.name,
                          value: item?._id,
                        })),
                        { _id: "others", label: t("other"), value: "others" },
                      ].filter((itm) => itm)}
                      getSelectedValue={(e) => {
                        if (e._id === "others") {
                          setEnabledOtherPositionPopup(true);
                          setPositionType(1);
                        }
                        setDesignation(e._id);
                      }}
                    />
                  </div>
                  <div className={"relative w-1/4 max-w-[calc(25%-1rem)] "}>
                    <Select
                      label={t("previous_board_position_held")}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={prevDesignation}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        { _id: " ", label: t("select_position"), value: "" },
                        ...position.map((item) => ({
                          _id: item?._id,
                          label: item?.name,
                          value: item?._id,
                        })),
                        {
                          _id: "not_applicable",
                          label: t("not_applicable"),
                          value: "not_applicable",
                        },
                        { _id: "others", label: t("other"), value: "others" },
                      ].filter((itm) => itm)}
                      getSelectedValue={(e) => {
                        if (e._id === "others") {
                          setEnabledOtherPositionPopup(true);
                          setPositionType(2);
                        }
                        if (e._id === "not_applicable") {
                          setIsPositionNotApplicable(1);
                        } else {
                          setIsPositionNotApplicable(2);
                        }
                        setPrevDesignation(e._id);
                      }}
                    />
                  </div>
                  {/* <div className="relative w-1/4 max-w-[calc(25%-1rem)] mt-6">
                  <Checkbox 
                    checkboxLabel={t('not_applicable')}
                    checkboxLableClass={""}
                    isChecked={(isPositionNotApplicable && isPositionNotApplicable ===1)?true:false}
                    checkboxValue={'not_applicable'}
                    getCheckboxValue={(code,event) => {
                      if(event.target.checked === true ){
                        setIsPositionNotApplicable(1)                            
                      }else{
                        setIsPositionNotApplicable(2)
                      }                            
                    }}
                  />
                </div>                 */}
                </div>
                <div className="flex items-start gap-4">
                  <div
                    className={
                      "relative w-1/4 max-w-[calc(25%-1rem)] " +
                      (isPositionNotApplicable && isPositionNotApplicable === 1
                        ? "pointer-events-none opacity-40"
                        : "")
                    }
                  >
                    <Select
                      label={t("previous_board_position_held") + " - 2"}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={prevDesignation2}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        { _id: " ", label: t("select_position"), value: "" },
                        ...position.map((item) => ({
                          _id: item?._id,
                          label: item?.name,
                          value: item?._id,
                        })),
                        { _id: "others", label: t("other"), value: "others" },
                      ].filter((itm) => itm)}
                      getSelectedValue={(e) => {
                        if (e._id === "others") {
                          setEnabledOtherPositionPopup(true);
                          setPositionType(3);
                        }
                        setPrevDesignation2(e._id);
                      }}
                    />
                  </div>
                  <div
                    className={
                      "relative w-1/4 max-w-[calc(25%-1rem)] " +
                      (isPositionNotApplicable && isPositionNotApplicable === 1
                        ? "pointer-events-none opacity-40"
                        : "")
                    }
                  >
                    <Select
                      label={t("previous_board_position_held") + " - 3"}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={prevDesignation3}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        { _id: " ", label: t("select_position"), value: "" },
                        ...position.map((item) => ({
                          _id: item?._id,
                          label: item?.name,
                          value: item?._id,
                        })),
                        { _id: "others", label: t("other"), value: "others" },
                      ].filter((itm) => itm)}
                      getSelectedValue={(e) => {
                        if (e._id === "others") {
                          setEnabledOtherPositionPopup(true);
                          setPositionType(4);
                        }
                        setPrevDesignation3(e._id);
                      }}
                    />
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Select
                      label={t("education")}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={edu}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        { _id: " ", label: t("select_education"), value: "" },
                        ...education.map((item) => ({
                          _id: item?._id,
                          label: item?.name,
                          value: item?._id,
                        })),
                      ].filter((itm) => itm)}
                      getSelectedValue={(e) => {
                        setEdu(e._id);
                      }}
                    />
                  </div>
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Select
                      label={t("field_of_study")}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={study}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        { _id: " ", label: t("select_study"), value: "" },
                        ...studies.map((item) => ({
                          _id: item?._id,
                          label: item?.name,
                          value: item?._id,
                        })),
                        { _id: "others", label: t("other"), value: "others" },
                      ].filter((itm) => itm)}
                      getSelectedValue={(e) => {
                        if (e._id === "others") {
                          setEnabledOtherStudyPopup(true);
                        }
                        setStudy(e._id);
                      }}
                    />
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <div className="relative w-1/2 max-w-[calc(50%-1rem)]">
                    <Select
                      label={t("industry_experience")}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={industry}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        { _id: " ", label: t("select_industry"), value: "" },
                        ...industries.map((item) => ({
                          _id: item?._id,
                          label: item?.name,
                          value: item?._id,
                        })),
                        { _id: "others", label: t("other"), value: "others" },
                      ].filter((itm) => itm)}
                      getSelectedValue={(e) => {
                        if (e._id === "others") {
                          setEnabledOtherIndustryPopup(true);
                        }
                        setIndustry(e._id);
                      }}
                    />
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Select
                      label={t("primary_area_of_expertise")}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={primaryExpertise}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        {
                          _id: " ",
                          label: t("select_primary_area_of_expertise"),
                          value: "",
                        },
                        ...areaOfExpertise.map((item) => ({
                          _id: item?._id,
                          label: item?.name,
                          value: item?._id,
                        })),
                        { _id: "others", label: t("other"), value: "others" },
                      ].filter((itm) => itm)}
                      getSelectedValue={(e) => {
                        if (e._id === "others") {
                          setEnabledOtherAreaExpertisePopup(true);
                          setAreaExpertiseType(1);
                        }
                        setPrimaryExpertise(e._id);
                      }}
                    />
                  </div>
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Select
                      label={t("years_of_primary_experience")}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={primaryExp}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        {
                          _id: "",
                          label: t("select_years_of_primary_experience"),
                          value: "",
                        },
                        ...Array(30)
                          .fill()
                          .map((_, index) => ({
                            _id: Number(index + 1),
                            label:
                              Number(index + 1) > 1
                                ? Number(index + 1) + " Years"
                                : Number(index + 1) + " Year",
                            value: Number(index + 1),
                          })),
                      ]
                        .filter((itm) => itm)
                        .sort((a, b) => b.value - a.value)}
                      getSelectedValue={(e) => {
                        setPrimaryExp(e._id);
                      }}
                    />
                  </div>
                </div>
                {/* <div className="flex items-start gap-4">                
                <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                  <Select 
                    label={t('secondary_area_of_expertise')}
                    labelClasses={'!text-xs !font-semibold'}
                    xPlacement={'left'} 
                    selectedValue={secondaryExpertise} 
                    dropdownClass={'!w-60'} 
                    dropdownData={
                        [
                          { _id: ' ', label: t('select_secondary_area_of_expertise'),value:''} ,
                          ...areaOfExpertise.map((item) => (
                              { _id: item?._id, label: item?.name, value: item?._id }
                          )),
                          { _id: "others", label: t('other'), value: "others" },
                        ].filter((itm) => itm)                             
                    }
                    getSelectedValue={(e) => {
                      if(e._id === 'others'){
                        setEnabledOtherAreaExpertisePopup(true);
                        setAreaExpertiseType(2)
                      }
                      setSecondaryExpertise(e._id)
                    }}
                  />
                </div>
                <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                  <Select 
                    label={t('years_of_secondary_experience')}
                    labelClasses={'!text-xs !font-semibold'}
                    xPlacement={'left'} 
                    selectedValue={secondaryExp} 
                    dropdownClass={'!w-60'} 
                    dropdownData={
                        [
                          { _id: ' ', label: t('select_years_of_secondary_experience'),value:''} ,
                          ...Array(30).fill().map((_, index) => (
                              { _id: Number(index+1), label: (Number(index+1)>1)?Number(index+1) +' Years':Number(index+1) +' Year', value: Number(index+1) }
                          )),
                        ].filter((itm) => itm).sort((a, b) => b.value - a.value)                             
                    }
                    getSelectedValue={(e) => {setSecondaryExp(e._id)}}
                  />
                </div>
              </div>
              <div className="flex items-start gap-4">
                <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                  <Select 
                    label={t('management_level')}
                    labelClasses={'!text-xs !font-semibold'}
                    xPlacement={'left'} 
                    selectedValue={mLevel} 
                    dropdownClass={'!w-60'} 
                    dropdownData={
                        [
                          { _id: ' ', label: t('select_management_level'),value:''} ,
                          ...managementLevel.map((item) => (
                              { _id: item?._id, label: item?.name, value: item?._id }
                          )),
                        ].filter((itm) => itm)                             
                    }
                    getSelectedValue={(e) => {setMLevel(e._id)}}
                  />
                </div>
                <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                  <Select 
                    label={t('years_of_management_experience')}
                    labelClasses={'!text-xs !font-semibold'}
                    xPlacement={'left'} 
                    selectedValue={managementExp} 
                    dropdownClass={'!w-60'} 
                    dropdownData={
                        [
                          { _id: ' ', label: t('select_years_of_management_experience'),value:''} ,
                          ...boardYear.map((item) => (
                              { _id: item?._id, label: item?.name, value: item?._id }
                          )),
                        ].filter((itm) => itm)                             
                    }
                    getSelectedValue={(e) => {setManagementExp(e._id)}}
                  />
                </div>
                
              </div> */}
                <div className="flex items-start gap-4">
                  {/* <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                  <Select 
                    label={t('needed_development')}
                    labelClasses={'!text-xs !font-semibold'}
                    xPlacement={'left'} 
                    selectedValue={nDevelopment} 
                    dropdownClass={'!w-60'} 
                    dropdownData={
                        [
                          { _id: ' ', label: t('select_needed_development'),value:''} ,
                          ...neededDevelopment.map((item) => (
                              { _id: item?._id, label: item?.name, value: item?._id }
                          )),
                        ].filter((itm) => itm)                             
                    }
                    getSelectedValue={(e) => {setNDevelopment(e._id)}}
                  />
                </div> */}
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Select
                      label={t("desire_for_additional_responsibilities")}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={desire}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        {
                          _id: " ",
                          label: t("select_advancement_desire"),
                          value: "",
                        },
                        ...advaceDesire.map((item) => ({
                          _id: item?._id,
                          label: item?.name,
                          value: item?._id,
                        })),
                      ].filter((itm) => itm)}
                      getSelectedValue={(e) => {
                        setDesire(e._id);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {
              role && role === "customer-boardmember" &&
              <div className="relative flex justify-between items-center px-5">
                <h5 className="text-black rounded-none w-full font-semibold text-xl px-5 py-3">
                  {t("board_effectiveness")}
                </h5>
                <div className="">
                  <Button
                    buttonType={"button"}
                    buttonIcon={"fa-light fa-clock"}
                    buttonIconPosition={"left"}
                    buttonLabel={t("start_survey")}
                    buttonHasLink={false}
                    functions={() => {
                      setEnabledSurveyPopup(true);
                      // setReatingOptions([]);
                      // setAnswers([]);
                    }}
                  />
                </div>
              </div>
            }
            
            {/* <div className="py-3 px-3 border-t border-slate-200 space-y-4">
            {
              (reatingOptions && reatingOptions.length>0) &&
              reatingOptions.map((item,index) => {
                let q_idx = -1;
                let ans_id = "";
                let ans_idx = -1;  
                let comment = '';
                if(answers && answers.length>0){
                  q_idx = answers.findIndex((p) => p.questionId === item?.question?._id);
                  ans_id = (answers[q_idx]?.answerId)?answers[q_idx]?.answerId:'';
                  ans_idx = item?.question?.answers?.findIndex((op) => op._id === ans_id);
                  comment = (answers[q_idx]?.comments)?answers[q_idx]?.comments:'';
                }
                return (
                  <div className='border-b border-slate-200 py-4 px-5 space-y-5'>
                    <div className="grid grid-cols-3 gap-4">
                      <div className="col-span-2 flex items-center justify-start">
                        <div className="text-sm font-medium text-slate-500">{Number(index+1)}.{' '+item?.question?.name}</div>
                      </div>
                      <div className="col-span-1">
                        <Select
                          xPlacement={"left"}
                          selectedValue={(index === q_idx && item?.question?.answers[ans_idx]?._id === ans_id )? item?.question?.answers[ans_idx]?._id:''}
                          dropdownClass={"!w-60"}
                          dropdownButtonLabelClass={"!font-bold"}
                          dropdownData={[
                            { _id: "", label: "Select", value: "" },
                            ...item?.question?.answers.map((it,idx) => ({
                              _id: it?._id,
                              label: it?.value+' '+((it?.label)?'('+it?.label+')':''),
                              value: it?.value,
                              title : it?.label
                            })),
                          ]}
                          dropdownOptionBold={true}
                          getSelectedValue={(e) => handelUpdate(item?.question?._id,e._id,e.value,e.title,index)}
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <Textarea 
                        label={''} 
                        inputClasses={"!h-16"} 
                        labelClasses={"!text-xs"} 
                        inputValue={comment} 
                        inputPlaceholder={t("enter_comments")} 
                        setTextarea={(val) => handelUpdate(item?.question?._id,'comments', val)} 
                      />
                    </div>
                  </div>
                )
              })
            }
          </div>       */}
            <div className="py-3 px-3 border-t border-slate-200">
              <div className="flex items-center justify-end gap-4">
                <Button
                  buttonClasses=""
                  buttonLabelClasses=""
                  buttonType={"button"}
                  buttonIcon={
                    isSave ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"
                  }
                  buttonIconPosition={"left"}
                  buttonLabel={t("submit")}
                  functions={onSubmit}
                  buttonHasLink={false}
                  buttonDisabled={isSave}
                />
                {role && role !== "customer-boardmember" && (
                  <button
                    type={"button"}
                    className={
                      "flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-lg h-10 py-0 px-3 transition-all duration-200 cursor-pointer "
                    }
                    onClick={(e) => getDownload(e)}
                  >
                    <i className={"fa-fw fa-light fa-download"}></i>
                    <div className={"text-sm font-medium whitespace-nowrap"}>
                      <PDFDownloadLink
                        document={
                          <BoardMemberReport
                            boardMembers={boardMambers}
                            user={user}
                          />
                        }
                        fileName="profile_report.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? t("loading_document") : t("report")
                        }
                      </PDFDownloadLink>
                    </div>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {enabledOtherPositionPopup && (
        <OtherPositionPopup
          isOpen={enabledOtherPositionPopup}
          setIsOpen={(val) => setEnabledOtherPositionPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
          data={{ isBoardMember: 1 }}
        ></OtherPositionPopup>
      )}
      {enabledOtherStudyPopup && (
        <OtherStudyPopup
          isOpen={enabledOtherStudyPopup}
          setIsOpen={(val) => setEnabledOtherStudyPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></OtherStudyPopup>
      )}
      {enabledOtherIndustryPopup && (
        <OtherIndustryPopup
          isOpen={enabledOtherIndustryPopup}
          setIsOpen={(val) => setEnabledOtherIndustryPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></OtherIndustryPopup>
      )}
      {enabledOtherAreaExpertisePopup && (
        <OtherAreaExpertisePopup
          isOpen={enabledOtherAreaExpertisePopup}
          setIsOpen={(val) => setEnabledOtherAreaExpertisePopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></OtherAreaExpertisePopup>
      )}
      {enabledSurveyPopup && (
        <SurveyPopup
          isOpen={enabledSurveyPopup}
          setIsOpen={(val) => setEnabledSurveyPopup(val)}
          data={answers}
          surveyFor={"boardmember"}
          isConfirmation={
            role && role === "customer-boardmember" ? true : false
          }
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></SurveyPopup>
      )}
    </>
  );
};

export default Boardroom;
