import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Select from "../components/form/Select";
import Search from "../components/form/Search";
import Table from "../components/elements/table/Table";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../helpers";
import toastr from "toastr";
import CmsTab from "../shared/CmsTab";
import { useSelector } from "react-redux";

import { postData } from "../services/api";
import { useTranslation } from "react-i18next";

const PositionAlt = () => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Positions"
  );
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("ordering");
  const [sortValue, setSortValue] = useState(1);
  const [sortQuery, setSortQuery] = useState("");
  const [status, setStatus] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [positions, setPositions] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const [actionValue, setActionValue] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    if (actionValue && actionValue.button_type === "status") {
      const loadStatusChange = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("position/status-change", {
            id: actionValue?.row_id,
            status: actionValue?.current_status,
          });
          if (statusData.data) {
            setPositions(
              positions.map((it) => {
                if (it?._id === statusData.data?._id) {
                  return {
                    ...it,
                    status: statusData.data?.status,
                  };
                } else {
                  return it;
                }
              })
            );
            setIsUpdate(true);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadStatusChange();
    } else if (actionValue && actionValue.button_type === "delete") {
      const loadDeleteRecord = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("position/delete", {
            id: actionValue?.row_id,
          });
          if (statusData.data) {
            const index = positions.findIndex(
              (item) => item._id === actionValue?.row_id
            );
            if (index !== -1) {
              positions.splice(index, 1);
              setPositions(
                positions.map((it) => {
                  return it;
                })
              );
            }
            setIsUpdate(true);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadDeleteRecord();
    }
    //console.log('actionValue',actionValue)
  }, [actionValue]);

  useEffect(() => {
    // Position List
    const loadPosition = async () => {
      setLoading(true);
      try {
        const result = await postData("company-position/list", {
          keyword: keyword,
          limit: limit,
          offset: keyword ? 0 : offset,
          sortQuery: sortQuery,
          status: status,
        });
        if (result.data) {
          setPositions(
            result.data.map((value, key) => {
              return {
                _id: value?._id,
                rowData: [
                  { _id: 1, width: 40, type: "text", data: value?.name },
                  { _id: 2, width: 15, type: "text", data: value?.short_name },
                  {
                    _id: 3,
                    width: 20,
                    type: "text",
                    data:
                      value && value?.created_at
                        ? moment(new Date(value?.created_at)).format(
                            "Do MMM YYYY"
                          )
                        : "N/A",
                  },
                  {
                    _id: 4,
                    width: 15,
                    type: "status",
                    statusLabel:
                      value?.status && value?.status === 1
                        ? t("active")
                        : t("deactivated"),
                    statusType:
                      value?.status && value?.status === 1
                        ? "success"
                        : "warning",
                  },
                  value?.company_id && value?.company_id === user?._id
                    ? {
                        _id: 5,
                        width: 10,
                        type: "action",
                        statusLabel:
                          value?.status && value?.status === 1
                            ? t("active")
                            : t("deactivated"),
                        statusType:
                          value?.status && value?.status === 1
                            ? "success"
                            : "warning",
                      }
                    : "",
                ],
              };
            })
          );
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadPosition();
  }, [keyword, limit, offset, sortQuery, status, isUpdate]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
    if (keyword) {
      setOffset(0);
    }
  }, [limit, totalDataCount, keyword]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  };

  const tableHeadData = [
    {
      _id: 1,
      width: 40,
      name: t("name"),
      value: "name",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    {
      _id: 2,
      width: 15,
      name: t("short_name"),
      value: "short_name",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    {
      _id: 3,
      width: 20,
      name: t("created_date"),
      value: "created_at",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 4,
      width: 15,
      name: t("status"),
      value: "status",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 5,
      width: 10,
      name: t("action"),
      value: "",
      align: "right",
      isSort: false,
      isFilter: false,
    },
  ];

  const handleOnDragEnd = async (result) => {
    //console.log("result",result);
    const items = reorder(
      positions,
      result.source.index,
      result.destination.index
    );
    setPositions(items);

    const res = await postData("position/ordering", {
      items: items,
    });
    if (res.status && res.status === 200) {
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  //console.log('positions',positions);

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">
          {t("positions") + " - " + user?.company_name}
        </div>
        <div className="sm:ml-auto flex justify-end items-center space-x-4">
          <Button
            buttonType={"button"}
            buttonIcon={"fa-light fa-plus"}
            buttonIconPosition={"left"}
            buttonLabel={t("add_positions")}
            buttonHasLink={true}
            buttonLink={"/add-position"}
          />
        </div>
      </div>
      {/* <div className="relative flex justify-between items-center mb-4">
          <div className="relative">
              <Select 
              xPlacement={'left'} 
              dropdownButtonClass={'!bg-white'} 
              selectedValue={limit} 
              dropdownData={[
                  { _id: 1000000, label: 'All Items',value: 1000000 }, 
                  ...[{ _id: 10, label: '10 Items', value: 10 },
                      { _id: 20, label: '20 Items', value: 20 },
                      { _id: 30, label: '30 Items', value: 30 },
                      { _id: 50, label: '50 Items', value: 50 },
                      { _id: 100, label: '100 Items', value: 100 }]
              ]}
              getSelectedValue={(e) => setLimit(e.value)}
              />
          </div>
          <div className="relative ml-auto flex justify-end items-center gap-3">
              <div className="relative">
              <Search 
              placeholder={'Search'} 
              setInput={ setKeyword } 
              />
              </div>
              <div className="relative">
              <Select 
              xPlacement={'right'} 
              dropdownButtonClass={'!bg-white'} 
              transitionClass={'!w-auto'}
              selectedValue={status} 
              dropdownData={[
                  { _id: '', label: 'All Status' },
                  { _id: 1, label: 'Active', value: 1 },
                  { _id: 2, label: 'Deactivated', value: 2 },
              ]}
              getSelectedValue={(e) => setStatus(e.value)}
              />
              </div>
          </div>
        </div> */}
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="w-full bg-white rounded-md shadow overflow-hidden">
          <Table
            tableData={positions}
            tableHeadData={tableHeadData}
            totalRecord={totalDataCount}
            loading={loading}
            getSortValue={setSortValue}
            getSortKey={setSortKey}
            editUrl={"/edit-position"}
            tableImage={false}
            isStatusUpdate={true}
            isDeleteEnabled={true}
            isDraggable={true}
            getActionValue={(obj) => setActionValue(obj)}
          />
        </div>
      </DragDropContext>
      {noOfPage > 1 ? (
        <div className="flex justify-center sm:justify-between my-4">
          <div>
            <p>
              {t("showing")}: {startDataCount} to {endDataCount} of{" "}
              {totalDataCount}
            </p>
          </div>
          <ReactPaginate
            breakLabel="..."
            breakClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            nextLabel={<i className="fa-regular fa-fw fa-chevron-right"></i>}
            nextClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={noOfPage}
            containerClassName="flex justify-center items-center text-slate-80 text-sm font-medium mt-8 lg:mt-0 space-x-2"
            activeClassName="hover:bg-teal-500 bg-teal-500 text-white"
            pageLinkClassName="flex justify-center items-center w-full h-full"
            previousLabel={<i className="fa-regular fa-fw fa-chevron-left"></i>}
            pageClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            previousClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            renderOnZeroPageCount={null}
          />
        </div>
      ) : (
        <div className="flex justify-center sm:justify-between my-4">
          {totalDataCount > 0 ? (
            <div>
              <p>
                {t("showing")}: {startDataCount} to {endDataCount} of{" "}
                {totalDataCount}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};

export default PositionAlt;
