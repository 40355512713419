import React from 'react';
import Th from './Th';

const Thead = ({
    tableHeadData,
    getSortValue=() => {},
    getSortKey=() => {},
}) => {

  return (
    <>
      <thead>
        <tr className="text-white text-xs leading-none border-b border-slate-200">
          { tableHeadData.map((item) => (
            <Th 
            key={item?._id} 
            width={item?.width}
            name={item?.name} 
            value={item?.value}
            nameAlign={item?.align} 
            isSort={item?.isSort} 
            isFilter={item?.isFilter}
            getSortValue={getSortValue}
            functions={(val) => getSortKey(val)}
            />
          ))}
        </tr>
      </thead>
    </>
  );
};

export default Thead;