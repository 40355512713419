import React,{ useState, useEffect,Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../form/Button';
import Select from '../form/Select';
import { useSelector } from 'react-redux';
import { postData } from '../../services/api';
import toastr from 'toastr';
import moment from "moment/moment";
import Searching from '../Searching';
import NoDataFound from '../NoDataFound';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useTranslation } from "react-i18next";
import { API_URL } from '../../config/host';
import RatingHistoryReport from '../../pages/pdf/RatingHistoryReport';

const SurveyHistoryPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  surveyFor,
  getActionValue = () => {}
}) => {   
  const { i18n,t } = useTranslation();
  const user = useSelector((state) => state.auth?.user);
  const role = localStorage.getItem("role");
  const [loading,setLoading] = useState(false); 
  const [reatingOptions,setReatingOptions] = useState([]);
  const [surveyDetails,setSurveyDetails] = useState([]);

  const closeModal = () =>{ setIsOpen(false) } 

  useEffect(() => {
    if(surveyFor === 'executive'){
        setSurveyDetails(data?.executiveAudits);
    }else{
        setSurveyDetails(data?.ratingOptions)
    }

    async function employeeRatingOptionList() {
        setLoading(true);
        try {
          const result = await postData("employee-rating-option/list", {
              sortQuery:{'created_at':1},
              status:1,
              limit :50,
              type: surveyFor
          });
          if (result.data) {
            setReatingOptions(result.data);
            setLoading(false);
          } else {
            console.log('Employee rating message',result.message)
            setLoading(false);
          }
        } catch (error) {
          console.log('Error employee rating catch',error.message)
          setLoading(false);
        }
    }
    employeeRatingOptionList();
  },[data,surveyFor]) 

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, '_blank');
    }
  }

  return (
    <>
    <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={()=>{}}>
        <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Dialog.Panel className="w-full max-w-5xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 text-center">
                {(surveyFor === 'executive')?t('executive_audit_history'):t('executive_audit_history')}
                </Dialog.Title>  
                <div className="absolute top-2 right-2 z-10">
                    <Button 
                    buttonLabelClasses=''
                    buttonType={'button'} 
                    buttonIcon={'fa-light fa-times'} 
                    buttonIconPosition={'left'} 
                    buttonLabel={''} 
                    buttonClasses={'!bg-transparent !text-black !w-8 !h-8 !p-0 !rounded-full shadow-lg border border-slate-100'} 
                    functions={closeModal}
                    buttonHasLink={false}
                    />
                </div>
                <div className="relative flex justify-end items-center my-4">
                    <button type={'button'} className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-lg h-10 py-0 px-3 transition-all duration-200 cursor-pointer "} onClick={(e) => getDownload(e)} >
                        <i className={"fa-fw fa-light fa-download"}></i>
                        <div className={"text-sm font-medium whitespace-nowrap"}>
                            <PDFDownloadLink document={
                            <RatingHistoryReport 
                            details={surveyDetails} 
                            reatingOptions={reatingOptions}
                            user={user} 
                            companyLogo={API_URL+user?.image}
                            />} fileName="profile_report.pdf">
                            {({ blob, url, loading, error }) => (
                                loading ? t('loading_document') : t('report'))}
                            </PDFDownloadLink>
                        </div>
                    </button>
                </div>
                <div className="relative overflow-x-auto mt-4 flex items-center justify-center h-full">
                    <div className="col-span-4 space-y-4">
                        <div className="relative bg-white rounded shadow divide-y divide-slate-200">
                            <div className="py-8 px-6 flex flex-col">
                            {/* <div className="text-xl font-bold text-slate-900">{t('board_effectiveness')}</div>             */}
                                <dl className="space-y-5 mt-5">
                                {
                                    (reatingOptions && reatingOptions.length>0)?( 
                                    reatingOptions.map((item,index) => {
                                    let ratingRowAnswers = item?.question?.answers;
                                    const ratedBoardMember = surveyDetails && surveyDetails.filter((row) => row.questionId === item?.question?._id);                                    
                                    let totalRating = 0;
                                    let ansLabel = ''; 
                                    let comment = '';
                                    let _ans = [];
                                    if(ratedBoardMember && ratedBoardMember.length>0){
                                        totalRating =  ratedBoardMember[0]?.rating;
                                        ansLabel =  ratedBoardMember[0]?.answerLabel;
                                        comment = ratedBoardMember[0]?.comments;
                                        _ans = ratedBoardMember[0]?.ids;
                                    } 
                                    if(ratingRowAnswers && ratingRowAnswers.length>0){
                                        ratingRowAnswers.forEach((obj) => {
                                        if (obj?.company_id === user?._id) {
                                            _ans.push(obj?._id);
                                        }
                                        });
                                    }                                                
                                    return (
                                        <div className=' rounded-md shadow-md px-3 py-3 space-y-5' key={index}>
                                            <div className="px-4  flex items-start">
                                                <dt className="w-4/5 text-sm font-medium leading-6 text-gray-900 flex-shrink">{Number(index+1)}.{' '+item?.question?.name}</dt>
                                                <dd className="ml-auto text-sm font-semibold leading-6 text-teal-600 sm:col-span-2 sm:mt-0 text-right">{((totalRating>0)?totalRating:'')+((ansLabel)?' ('+ansLabel+')':'')}</dd>
                                            </div> 
                                            {
                                                (_ans && _ans.length>0) && 
                                                <div className='relative'>
                                                {
                                                    [...new Set(_ans)].map((an,i) =>{
                                                    let rowData = ratingRowAnswers.filter((row) => row._id === an);
                                                    if(rowData && rowData.length>0){
                                                        return (
                                                        <div className="px-4 flex items-center gap-2">
                                                            <div className='w-2 h-2 rounded-full overflow-hidden bg-slate-600'></div>
                                                            <div className='text-slate-600 text-sm'>{rowData[0]?.description}</div>
                                                        </div>
                                                        )
                                                    }
                                                    })
                                                }
                                                </div>
                                            }
                                            {
                                                comment && 
                                                <div className="px-4  flex items-start whitespace-pre-wrap">
                                                <i className="fa-solid fa-comment mr-1"></i>
                                                <dt className="w-full text-sm font-medium leading-6 text-gray-900 flex-shrink-0 ">{' '+comment}</dt>
                                                </div> 
                                            }                        
                                        </div> 
                                    )                    
                                    })
                                    ):(
                                        loading ? (<Searching label={t("searching")} />) : (<NoDataFound label={t("no_data_found")} />)
                                    )
                                }                                
                                </dl>
                            </div> 
                        </div> 
                    </div>
                </div>                 
                </Dialog.Panel>
            </Transition.Child>
            </div>
        </div>
        </Dialog>
    </Transition>   
    </>
  );
};

export default SurveyHistoryPopup;
