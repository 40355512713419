import React from 'react';

const DashboardPlanTrackingSkeleton = () => {
  return (
    <div className="animate-pulse space-y-4 p-4">
      <div className="h-6 bg-gray-200 rounded w-1/4"></div>

      {/* Table skeleton */}
      <div className="space-y-2">
        <div className="grid grid-cols-7 gap-4">
          <div className="h-4 bg-gray-200 rounded col-span-2"></div> 
          <div className="h-4 bg-gray-200 rounded col-span-1"></div> 
          <div className="h-4 bg-gray-200 rounded col-span-1"></div> 
          <div className="h-4 bg-gray-200 rounded col-span-1"></div> 
          <div className="h-4 bg-gray-200 rounded col-span-1"></div> 
          <div className="h-4 bg-gray-200 rounded col-span-1"></div> 
          <div className="h-4 bg-gray-200 rounded col-span-1"></div> 
        </div>

        {/* Repeat skeleton rows for additional entries */}
        {[...Array(5)].map((_, i) => (
          <div key={i} className="grid grid-cols-7 gap-4">
            <div className="h-4 bg-gray-200 rounded col-span-2"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardPlanTrackingSkeleton;
