import React from "react";
import { Document, Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";
import moment from "moment/moment";

import { useTranslation } from "react-i18next";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const CompanyReport = ({ 
  successors, 
  user, 
  educations, 
  creditUnion, 
  experiences, 
  retirementWindow,
  creditUnionAvgLabel, 
  experienceAvgLabel,
  retirementAvgLabel,
  companyLogo
}) => {  
  const { i18n,t } = useTranslation();
  const profileHeading = [
    {
      name :t('education_summary')
    },
    {
      name :t('year_of_service')
    },
    {
      name :t('senior_management_experience')
    },
    {
      name :t('retirement_window')
    }
  ]
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: 'RobotoRegular',
      lineHeight: 1.2,
      fontSize: 9,
      color:'#334155'
    },
    tableHeading: {
      fontSize: 12,
      width: "20%",
      maxWidth: '100%',
      padding: 8,

    },
    tableData: {
      fontSize: 10,
      width: "20%",
      maxWidth: '100%',
      padding: 8,
    },

    image: {
      width: 200,
      height: 40,
      objectFit: "contain",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tableOtherData: {
      fontSize: 10
    },
    imageSignature: {
      width: 100,
      height: 60,
      objectFit: "contain",
    },
    section: { textAlign: "center" },
  });

  return (
    <Document>
      <Page size="A4"  orientation='landscape' style={styles.page}>
        <View style={{ paddingBottom: 20, width: "100%", margin: "0 auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <View
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontSize: 16,
              alignItems:"center",
              position:'relative',
              width:'100%',
            }}
          >
            <Image
              style={styles.image}
              src={companyLogo}
            />
            <View style={{position:'absolute', right:0, top:0,}}>
              <Image
                style={{width: 120, height: 24, objectFit: "contain",}}
                src={logo}
              />
            </View>
          </View>
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoBold", fontSize: 20 , textTransform:'capitalize'}]}>Company Name: {user?.company_name}</Text>
          </View>
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 16 , textTransform:'capitalize'}]}>Succession Plan for {user?.title}</Text>
          </View>
          <View style={[ { display: "flex", flexDirection:'row', marginTop: 5, textAlign: "center", justifyContent: "center", }]}>
            <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center",marginRight: 12 }]}>
              <Text style={[{ display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 10, textAlign: "center", fontSize: 10 }]}>Created by:</Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>
                {user?.name}({user?.title})
              </Text>
            </View>
            <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 5 }]}>
              <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 10 }]}>Date:</Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>{moment(new Date()).format("MM/DD/YYYY")}</Text>
            </View>
          </View>
        </View>
        <View style={{position:'relative',marginTop:2}}>
          <View style={{display:"flex", flexDirection:"row", justifyContent:"space-between", width:"100%"}}>
            <View style={{width:'33%'}}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginBottom: 30,
                }}
              >
                <View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "#0f172a",
                      color: "#ffffff",
                      overflow: "hidden",
                      borderTopLeftRadius:6,
                      borderTopRightRadius:6,
                      borderBottomRightRadius:0,
                      borderBottomLeftRadius:0,
                    }}
                  >
                    <View
                      style={[
                        styles.tableHeading,
                        {
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        },
                      ]}
                    >
                      <Text
                        style={{
                          width: "100%",
                          fontFamily: "RobotoMedium",
                          fontSize: 10,
                          textAlign:"center"
                        }}
                      >
                        {t("average_years_of_service")}
                      </Text>
                    </View>
                  </View>
                  <View style={{ margin:"0 auto", width:"100%", border:"1px solid #e2e8f0"}}>
                    <View
                      style={[
                        styles.tableData,
                        {          
                          width:"100%",         
                          display: "flex",
                          flexDirection: "column",
                          alignItems:"center",
                          justifyContent:"center"
                        },
                      ]}
                    >
                      {/* <Text
                        style={[
                          {
                            textAlign:"center",
                            fontWeight: 500,
                            fontSize: 10,
                            lineHeight: 1,
                            whiteSpace: "nowrap",
                          },
                        ]}
                      >
                        {totalEffectivenessAvg}
                      </Text> */}
                      <Text
                        style={[
                          {
                            textAlign:"center",
                            fontWeight: 400,
                            fontSize: 10,
                            lineHeight: 1,
                            whiteSpace: "nowrap",
                          },
                        ]}
                      >
                        {creditUnionAvgLabel}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={{width:'33%'}}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginBottom: 30,
                }}
              >
                <View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "#0f172a",
                      color: "#ffffff",
                      overflow: "hidden",
                      borderTopLeftRadius:6,
                      borderTopRightRadius:6,
                      borderBottomRightRadius:0,
                      borderBottomLeftRadius:0,
                    }}
                  >
                    <View
                      style={[
                        styles.tableHeading,
                        {
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        },
                      ]}
                    >
                      <Text
                        style={{
                          width: "100%",
                          fontFamily: "RobotoMedium",
                          fontSize: 10,
                          textAlign:"center"
                        }}
                      >
                        {t("management_experience_average")}
                      </Text>
                    </View>
                  </View>
                  <View style={{ margin:"0 auto", width:"100%", border:"1px solid #e2e8f0"}}>
                    <View
                      style={[
                        styles.tableData,
                        {          
                          width:"100%",         
                          display: "flex",
                          flexDirection: "column",
                          alignItems:"center",
                          justifyContent:"center"
                        },
                      ]}
                    >
                      {/* <Text
                        style={[
                          {
                            textAlign:"center",
                            fontWeight: 500,
                            fontSize: 10,
                            lineHeight: 1,
                            whiteSpace: "nowrap",
                          },
                        ]}
                      >
                        {totalEffectivenessAvg}
                      </Text> */}
                      <Text
                        style={[
                          {
                            textAlign:"center",
                            fontWeight: 400,
                            fontSize: 10,
                            lineHeight: 1,
                            whiteSpace: "nowrap",
                          },
                        ]}
                      >
                        {experienceAvgLabel}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={{width:'33%'}}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginBottom: 30,
                }}
              >
                <View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "#0f172a",
                      color: "#ffffff",
                      overflow: "hidden",
                      borderTopLeftRadius:6,
                      borderTopRightRadius:6,
                      borderBottomRightRadius:0,
                      borderBottomLeftRadius:0,
                    }}
                  >
                    <View
                      style={[
                        styles.tableHeading,
                        {
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        },
                      ]}
                    >
                      <Text
                        style={{
                          width: "100%",
                          fontFamily: "RobotoMedium",
                          fontSize: 10,
                          textAlign:"center"
                        }}
                      >
                        {t("average_retirement_window")}
                      </Text>
                    </View>
                  </View>
                  <View style={{ margin:"0 auto", width:"100%", border:"1px solid #e2e8f0"}}>
                    <View
                      style={[
                        styles.tableData,
                        {          
                          width:"100%",         
                          display: "flex",
                          flexDirection: "column",
                          alignItems:"center",
                          justifyContent:"center"
                        },
                      ]}
                    >
                      <Text
                        style={[
                          {
                            textAlign:"center",
                            fontWeight: 400,
                            fontSize: 10,
                            lineHeight: 1,
                            whiteSpace: "nowrap",
                          },
                        ]}
                      >
                        {retirementAvgLabel}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        {successors && successors.length > 0 &&
        successors.map((row,key) => {          
        return (
        <>
        {(row.successors_details && row.successors_details.length > 0) && 
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", columnGap:20, marginTop: 5,  marginBottom:5, }]} key={key}>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 12 }]}>{row.position} : {row.name}</Text>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 12 }]}>Retirement Window : {(row?.retirement)?row?.retirement?.replace(/(Eliminates this candidate)/gi,'').replace(/[{()}]/g, ''):''}</Text>
          </View>
        }
         {row.successors_details && row.successors_details.length > 0 &&
          <View style={{ border: "1px solid #e2e8f0", borderRadius: 6, overflow: "hidden", marginBottom:30 }} key={key}>
            <View style={{ display: "flex", flexDirection: "row", backgroundColor: "#0f172a", color:'#ffffff', overflow: "hidden" }}>
              <Text style={[styles.tableHeading, { fontFamily: 'RobotoMedium', fontSize: 10 }]}>&nbsp;</Text>
              {row.successors_details.map((item,index) => (
                <View key={index} style={[styles.tableHeading, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                  <Text style={{ width: "100%", fontFamily: 'RobotoMedium', fontSize: 10 }}>{item.name}</Text>
                  <Text style={{ fontSize: 8, marginTop: 2 }}>{item?.position_details? item?.position_details[0]?.short_name: "N/A"}</Text>
                </View>
              ))}
            </View>          
            { profileHeading.map((items, index) => (
              <View key={index} style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0" }}>
                <View style={[styles.tableData, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                  <Text style={[{ fontWeight: 500, width: '100%', fontSize: 10, lineHeight: 1 }]}>{items.name}</Text>
                </View>
                {row.successors_details.map((item,index2) => (
                  <View key={index+'_'+index2} style={[styles.tableData, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                    {index === 0 &&
                      <Text style={{ fontSize: 9 }}>
                        {(item?.education_details && item?.education_details.length > 0)?item?.education_details[0]?.name:"N/A"}
                      </Text>
                    }
                    {index === 1 &&
                      <Text style={{ fontSize: 9 }}>
                        {(item?.cu_details && item?.cu_details.length > 0)?item?.cu_details[0]?.name:"N/A"}
                      </Text>
                    }
                    {index === 2 &&
                      <Text style={{ fontSize: 9 }}>
                        {(item?.experience_details && item?.experience_details.length > 0)?item?.experience_details[0]?.name:"N/A"}
                      </Text>
                    }
                    {index === 3 &&
                      ((item.retirement_details.length > 0 && item?.retirement_details[0].isPass)?(
                        <Text style={{ fontSize: 9 }}>{(Array.isArray(item?.retirement_details) && item?.retirement_details.length > 0)?
                      (item?.retirement_details[0]?.isPass)?'Pass: ':'Fail: ': ""}
                        {item?.retirement_details &&
                        item?.retirement_details.length > 0
                          ? item?.retirement_details[0]?.name
                          : "N/A"}</Text>
                      ):(
                        <Text style={{ fontSize: 9, color: 'red' }}>{(Array.isArray(item?.retirement_details) && item?.retirement_details.length > 0)?
                      (item?.retirement_details[0]?.isPass)?'Pass: ':'Fail: ': ""}
                        {item?.retirement_details &&
                        item?.retirement_details.length > 0
                          ? item?.retirement_details[0]?.name
                          : "N/A"}</Text>
                      ))
                    }
                  </View>
                ))}

              </View>
            )) }

          </View>
        }
        </>
        )
        })
        }
        <View style={{position:'relative',marginTop:10}}>
          <View style={{display:"flex", flexDirection:"row", justifyContent:"space-between", width:"100%"}}>
            <View style={{width:'25%'}}>
              <View
                style={{
                  border: "1px solid #e2e8f0",
                  borderRadius: 6,
                  overflow: "hidden",
                  marginBottom: 30,
                }}
              >
                <Text style={{fontSize:12, fontWeight:"bold", color:"#000", paddingVertical:8, paddingHorizontal:6 }}>{t('education_summary')}</Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#0f172a",
                    color: "#ffffff",
                    overflow: "hidden",
                  }}
                >
                  <View
                    style={[
                      styles.tableHeading,
                      {
                        width: "90%",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      },
                    ]}
                  >
                    <Text
                      style={{
                        width: "100%",
                        fontFamily: "RobotoMedium",
                        fontSize: 10,
                      }}
                    >
                      {t("level_of_education")}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.tableHeading,
                      {
                        width: "10%",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      },
                    ]}
                  >
                    <Text
                      style={{
                        width: "100%",
                        fontFamily: "RobotoMedium",
                        fontSize: 10,
                      }}
                    >
                      &nbsp;
                    </Text>
                  </View>                      
                </View>
                <View>
                  {educations &&
                    educations.length > 0 &&
                    educations.map((item, index) => {
                      const isEmptyObject = obj => Object.keys(obj).length === 0 && obj.constructor === Object;
                      let finalSuccessor = successors.filter(obj => !isEmptyObject(obj));
                      let educationCount = finalSuccessor.length >0 && finalSuccessor.map(person => person.successors_details).flat().map(successor => successor.education_details).flat().filter(education => education._id === item?._id).length;                      
                      return (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          <View
                            key={index}
                            style={[
                              styles.tableData,
                              {
                                width: "70%",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text
                              style={[
                                {
                                  fontWeight: 500,
                                  fontSize: 10,
                                  lineHeight: 1,
                                  whiteSpace: "nowrap",
                                },
                              ]}
                            >
                              {item?.name}
                            </Text>
                            <Text
                              style={[
                                {
                                  fontWeight: 300,
                                  fontSize: 6,
                                  lineHeight: 1,
                                  color: "#9CA38F",
                                },
                              ]}
                            >
                              {item?.option}
                            </Text>
                          </View>
                          <View
                            key={index}
                            style={[
                              styles.tableData,
                              {
                                width: "30%",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text
                              style={[
                                {
                                  fontWeight: 500,
                                  width: "100%",
                                  fontSize: 10,
                                  lineHeight: 1,
                                  textAlign:"right"
                                },
                              ]}
                            >
                              {(educationCount)?educationCount:0}
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                </View>
              </View>
            </View>
            <View style={{width:'24%'}}>
              <View
                style={{
                  border: "1px solid #e2e8f0",
                  borderRadius: 6,
                  overflow: "hidden",
                  marginBottom: 30,
                }}
              >
                <Text style={{fontSize:12, fontWeight:"bold", color:"#000", paddingVertical:8, paddingHorizontal:6 }}>{t('years_of_service')}</Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#0f172a",
                    color: "#ffffff",
                    overflow: "hidden",
                  }}
                >
                  <View
                    style={[
                      styles.tableHeading,
                      {
                        width: "70%",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      },
                    ]}
                  >
                    <Text
                      style={{
                        width: "100%",
                        fontFamily: "RobotoMedium",
                        fontSize: 10,
                      }}
                    >
                      {t("years_with_company")}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.tableHeading,
                      {
                        width: "30%",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      },
                    ]}
                  >
                    <Text
                      style={{
                        width: "100%",
                        fontFamily: "RobotoMedium",
                        fontSize: 10,
                      }}
                    >
                      &nbsp;
                    </Text>
                  </View>                      
                </View>
                <View>
                  {creditUnion &&
                    creditUnion.length > 0 &&
                    creditUnion?.sort((a, b) => b.ordering - a.ordering)?.map((item, index) => {
                      const _isEmptyObject = obj => Object.keys(obj).length === 0 && obj.constructor === Object;
                      let _finalSuccessor = successors.filter(obj => !_isEmptyObject(obj));
                      let yearCount = _finalSuccessor.length >0 && _finalSuccessor.map(person => person.successors_details).flat().map(successor => successor.cu_details).flat().filter(credit => credit._id === item?._id).length;               
                      return (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          <View
                            key={index}
                            style={[
                              styles.tableData,
                              {
                                width: "70%",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text
                              style={[
                                {
                                  fontWeight: 500,
                                  fontSize: 10,
                                  lineHeight: 1,
                                  whiteSpace: "nowrap",
                                },
                              ]}
                            >
                              {item?.name}
                            </Text>
                            <Text
                              style={[
                                {
                                  fontWeight: 300,
                                  fontSize: 6,
                                  lineHeight: 1,
                                  color: "#9CA38F",
                                },
                              ]}
                            >
                              {item?.option}
                            </Text>
                          </View>
                          <View
                            key={index}
                            style={[
                              styles.tableData,
                              {
                                width: "30%",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text
                              style={[
                                {
                                  fontWeight: 500,
                                  width: "100%",
                                  fontSize: 10,
                                  lineHeight: 1,
                                  textAlign:"right"
                                },
                              ]}
                            >
                              {(yearCount)?yearCount:0}
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                </View>
              </View>
            </View>
            <View style={{width:'24%'}}>
              <View
                style={{
                  border: "1px solid #e2e8f0",
                  borderRadius: 6,
                  overflow: "hidden",
                  marginBottom: 30,
                }}
              >
                <Text style={{fontSize:12, fontWeight:"bold", color:"#000", paddingVertical:8, paddingHorizontal:6 }}>{t('management_experience')}</Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#0f172a",
                    color: "#ffffff",
                    overflow: "hidden",
                  }}
                >
                  <View
                    style={[
                      styles.tableHeading,
                      {
                        width: "80%",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      },
                    ]}
                  >
                    <Text
                      style={{
                        width: "100%",
                        fontFamily: "RobotoMedium",
                        fontSize: 10,
                      }}
                    >
                      {t("management_experience")}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.tableHeading,
                      {
                        width: "20%",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent:"flex-end"
                      },
                    ]}
                  >
                    <Text
                      style={{
                        width: "100%",
                        fontFamily: "RobotoMedium",
                        fontSize: 10,
                        textAlign:"right"
                      }}
                    >
                      &nbsp;
                    </Text>
                  </View>                      
                </View>
                <View>
                  {experiences &&
                    experiences.length > 0 &&
                    experiences.map((item, index) => {
                      const _isEmptyObjectExp = obj => Object.keys(obj).length === 0 && obj.constructor === Object;
                      let _finalExpSuccessor = successors.filter(obj => !_isEmptyObjectExp(obj));
                      let expCount = _finalExpSuccessor.length >0 && _finalExpSuccessor.map(person => person.successors_details).flat().map(successor => successor.experience_details).flat().filter(experience => experience._id === item?._id).length;  
                      return (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          <View
                            key={index}
                            style={[
                              styles.tableData,
                              {
                                width: "40%",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text
                              style={[
                                {
                                  fontWeight: 500,
                                  fontSize: 10,
                                  lineHeight: 1,
                                  whiteSpace: "nowrap",
                                },
                              ]}
                            >
                              {item?.name}
                            </Text>
                            <Text
                              style={[
                                {
                                  fontWeight: 300,
                                  fontSize: 6,
                                  lineHeight: 1,
                                  color: "#9CA38F",
                                },
                              ]}
                            >
                              {item?.option}
                            </Text>
                          </View>
                          <View
                            key={index}
                            style={[
                              styles.tableData,
                              {
                                width: "60%",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text
                              style={[
                                {
                                  fontWeight: 500,
                                  width: "100%",
                                  fontSize: 10,
                                  lineHeight: 1,
                                  textAlign:"right"
                                },
                              ]}
                            >
                              {(expCount)?expCount:0}
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                </View>
              </View>
            </View>
            <View style={{width:'25%'}}>
              <View
                style={{
                  border: "1px solid #e2e8f0",
                  borderRadius: 6,
                  overflow: "hidden",
                  marginBottom: 30,
                }}
              >
                <Text style={{fontSize:12, fontWeight:"bold", color:"#000", paddingVertical:8, paddingHorizontal:6 }}>{t('retirement_summary')}</Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#0f172a",
                    color: "#ffffff",
                    overflow: "hidden",
                  }}
                >
                  <View
                    style={[
                      styles.tableHeading,
                      {
                        width: "80%",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      },
                    ]}
                  >
                    <Text
                      style={{
                        width: "100%",
                        fontFamily: "RobotoMedium",
                        fontSize: 10,
                      }}
                    >
                      {t("retirement_window")}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.tableHeading,
                      {
                        width: "20%",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent:"flex-end"
                      },
                    ]}
                  >
                    <Text
                      style={{
                        width: "100%",
                        fontFamily: "RobotoMedium",
                        fontSize: 10,
                        textAlign:"right"
                      }}
                    >
                      &nbsp;
                    </Text>
                  </View>                      
                </View>
                <View>
                  {retirementWindow &&
                    retirementWindow.length > 0 &&
                    retirementWindow.map((item, index) => {
                      const _isEmptyObjectExp = obj => Object.keys(obj).length === 0 && obj.constructor === Object;
                      let _finalRetirementSuccessor = successors.filter(obj => !_isEmptyObjectExp(obj));
                      let retirementCount = _finalRetirementSuccessor.length >0 && _finalRetirementSuccessor.map(person => person.successors_details).flat().map(successor => successor.retirement_details).flat().filter(retirement => retirement._id === item?._id).length; 
                      let isPass = item?.isPass; 
                      return (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          <View
                            key={index}
                            style={[
                              styles.tableData,
                              {
                                width: "80%",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            {
                              (isPass?(
                                <Text style={[{fontWeight: 500,fontSize: 10,lineHeight: 1,whiteSpace: "nowrap"}]}>
                                  {item?.name}
                                </Text>
                              ):(
                                <Text style={[{fontWeight: 500,fontSize: 10,lineHeight: 1,whiteSpace: "nowrap",color:'red'}]}>
                                  {item?.name}
                                </Text>
                              ))
                            }                            
                            <Text
                              style={[
                                {
                                  fontWeight: 300,
                                  fontSize: 6,
                                  lineHeight: 1,
                                  color: "#9CA38F",
                                },
                              ]}
                            >
                              {item?.option}
                            </Text>
                          </View>
                          <View
                            key={index}
                            style={[
                              styles.tableData,
                              {
                                width: "20%",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            {
                              (isPass?(
                                <Text style={[{fontWeight: 500,width: "100%",fontSize: 10,lineHeight: 1,textAlign:"right"}]}>
                                  {(retirementCount)?retirementCount:0}
                                </Text>
                              ):(
                                <Text style={[{fontWeight: 500,width: "100%",fontSize: 10,lineHeight: 1,textAlign:"right",color:'red'}]}>
                                  {(retirementCount)?retirementCount:0}
                                </Text>
                              ))
                            }
                          </View>
                        </View>
                      );
                    })}
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={{position:'relative',marginTop:2}}>
          <View style={{display:"flex", flexDirection:"row", justifyContent:"space-between", width:"100%"}}>
            <View style={{width:'33%'}}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginBottom: 30,
                }}
              >
                <View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "#0f172a",
                      color: "#ffffff",
                      overflow: "hidden",
                      borderTopLeftRadius:6,
                      borderTopRightRadius:6,
                      borderBottomRightRadius:0,
                      borderBottomLeftRadius:0,
                    }}
                  >
                    <View
                      style={[
                        styles.tableHeading,
                        {
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        },
                      ]}
                    >
                      <Text
                        style={{
                          width: "100%",
                          fontFamily: "RobotoMedium",
                          fontSize: 10,
                          textAlign:"center"
                        }}
                      >
                        {t("average_years_of_service")}
                      </Text>
                    </View>
                  </View>
                  <View style={{ margin:"0 auto", width:"100%", border:"1px solid #e2e8f0"}}>
                    <View
                      style={[
                        styles.tableData,
                        {          
                          width:"100%",         
                          display: "flex",
                          flexDirection: "column",
                          alignItems:"center",
                          justifyContent:"center"
                        },
                      ]}
                    >
                      {/* <Text
                        style={[
                          {
                            textAlign:"center",
                            fontWeight: 500,
                            fontSize: 10,
                            lineHeight: 1,
                            whiteSpace: "nowrap",
                          },
                        ]}
                      >
                        {totalEffectivenessAvg}
                      </Text> */}
                      <Text
                        style={[
                          {
                            textAlign:"center",
                            fontWeight: 400,
                            fontSize: 10,
                            lineHeight: 1,
                            whiteSpace: "nowrap",
                          },
                        ]}
                      >
                        {creditUnionAvgLabel}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={{width:'33%'}}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginBottom: 30,
                }}
              >
                <View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "#0f172a",
                      color: "#ffffff",
                      overflow: "hidden",
                      borderTopLeftRadius:6,
                      borderTopRightRadius:6,
                      borderBottomRightRadius:0,
                      borderBottomLeftRadius:0,
                    }}
                  >
                    <View
                      style={[
                        styles.tableHeading,
                        {
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        },
                      ]}
                    >
                      <Text
                        style={{
                          width: "100%",
                          fontFamily: "RobotoMedium",
                          fontSize: 10,
                          textAlign:"center"
                        }}
                      >
                        {t("management_experience_average")}
                      </Text>
                    </View>
                  </View>
                  <View style={{ margin:"0 auto", width:"100%", border:"1px solid #e2e8f0"}}>
                    <View
                      style={[
                        styles.tableData,
                        {          
                          width:"100%",         
                          display: "flex",
                          flexDirection: "column",
                          alignItems:"center",
                          justifyContent:"center"
                        },
                      ]}
                    >
                      {/* <Text
                        style={[
                          {
                            textAlign:"center",
                            fontWeight: 500,
                            fontSize: 10,
                            lineHeight: 1,
                            whiteSpace: "nowrap",
                          },
                        ]}
                      >
                        {totalEffectivenessAvg}
                      </Text> */}
                      <Text
                        style={[
                          {
                            textAlign:"center",
                            fontWeight: 400,
                            fontSize: 10,
                            lineHeight: 1,
                            whiteSpace: "nowrap",
                          },
                        ]}
                      >
                        {experienceAvgLabel}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={{width:'33%'}}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginBottom: 30,
                }}
              >
                <View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "#0f172a",
                      color: "#ffffff",
                      overflow: "hidden",
                      borderTopLeftRadius:6,
                      borderTopRightRadius:6,
                      borderBottomRightRadius:0,
                      borderBottomLeftRadius:0,
                    }}
                  >
                    <View
                      style={[
                        styles.tableHeading,
                        {
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        },
                      ]}
                    >
                      <Text
                        style={{
                          width: "100%",
                          fontFamily: "RobotoMedium",
                          fontSize: 10,
                          textAlign:"center"
                        }}
                      >
                        {t("average_retirement_window")}
                      </Text>
                    </View>
                  </View>
                  <View style={{ margin:"0 auto", width:"100%", border:"1px solid #e2e8f0"}}>
                    <View
                      style={[
                        styles.tableData,
                        {          
                          width:"100%",         
                          display: "flex",
                          flexDirection: "column",
                          alignItems:"center",
                          justifyContent:"center"
                        },
                      ]}
                    >
                      <Text
                        style={[
                          {
                            textAlign:"center",
                            fontWeight: 400,
                            fontSize: 10,
                            lineHeight: 1,
                            whiteSpace: "nowrap",
                          },
                        ]}
                      >
                        {retirementAvgLabel}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CompanyReport;
