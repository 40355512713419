import React, { useRef } from "react";
import Image from "../Image";
import Select from "../../form/Select";
import Button from "../../form/Button";
import Textarea from "../../form/Textarea";
import Input from "../../form/Input";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import TableAction from "./TableAction";
import Checkbox from "../../form/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { PDFDownloadLink, Document } from "@react-pdf/renderer";
import InformationReport from "../../../pages/pdf/InformationReport";

import { useTranslation } from "react-i18next";
import { classNames } from "../../../helper/classNames";
import Indentification from "../Indentification";
import { initialsValue } from "../../../helpers";
import { Tooltip } from "react-tooltip";

const Td = ({
  type,
  width,
  data,
  value,
  image,
  tdClasses,
  statusLabel,
  statusType,
  isDropdoen,
  statusClasses,
  inputType,
  inputClasses,
  inputPlaceholder,
  rowId,
  userIds,
  editUrl,
  isStatusUpdate,
  isDeleteEnabled,
  dragHandleProps,
  getActionValue = () => {},
  isUser,
  detailsUrl,
  othersAction = () => {},
  loginAs,
  isColor = "",
  extraData,
  shortName,
  isTooltip,
  toolTipData,
  updateCheckboxHandler = () => {},
  ...props
}) => {
  const user = useSelector((state) => state.auth?.user);
  const navigate = useNavigate();
  const path = window.location.pathname.replace(/[/]/g, "").replace(/\s/g, "");
  const { i18n, t } = useTranslation();
  const flatPicker = useRef(null);
  const status = {
    success: "bg-green-100 text-green-600",
    danger: "bg-rose-100 text-rose-600",
    warning: "bg-amber-100 text-amber-600",
    info: "bg-sky-100 text-sky-600",
    "same-success": "bg-lime-100 text-lime-600",
    "": "bg-slate-100 text-slate-600",
  };

  const getDownload = async (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  const salesReportHeading = [
    {
      name: t("company_name"),
      option: "",
    },
    {
      name: t("company_administrator"),
      option: "",
    },
    {
      name: t("administrator_position"),
      option: "",
    },
    {
      name: t("company_administrator_email"),
      option: "",
    },
    {
      name: t("company_administrator_phone"),
      option: "",
    },
    {
      name: t("company_size"),
      option: "",
    },
    {
      name: t("price"),
      option: "",
    },
    {
      name: t("source"),
      option: "",
    },
    {
      name: t("price_note"),
      option: "",
    },
    {
      name: t("salesperson"),
      option: "",
    },
    {
      name: "Proposed Contract Length",
      option: "",
    },
    {
      name: "Payment Terms",
      option: "",
    },
    {
      name: t("additional_notes"),
      option: "",
    },
    {
      name: t("purchase_agreement_received"),
      option: "",
    },
    {
      name: t("account_setup_completed"),
      option: "",
    },
    {
      name: t("invoice_and_w_9_sent"),
      option: "",
    },
    {
      name: t("payment_received"),
      option: "",
    },
    {
      name: t("training_scheduled"),
      option: "",
    },
    {
      name: t("training_completed"),
      option: "",
    },
  ];

  return (
    <td
      className={classNames(
        "py-2 px-2 text-left max-w-[240px] border-b border-slate-200 break-all",
        tdClasses
      )}
      style={{ minWidth: width + "%" }}
      {...dragHandleProps}
    >
      {type === "checkbox" && props?.isCheckbox ? (
        <Checkbox
          checkboxName={rowId}
          isChecked={data && data === 1 ? true : false}
          checkboxValue={value}
          getCheckboxValue={(code, event) => {
            updateCheckboxHandler({
              id: rowId,
              checkboxId: data,
              code: props?.code ? props?.code : "",
              isEnabled: event.target.checked,
            });
          }}
        />
      ) : value && value === 2 ? (
        <Checkbox
          checkboxName={rowId}
          //isChecked={(data)?true:false}
          checkboxValue={value}
          getCheckboxValue={(code, event) => {
            updateCheckboxHandler({
              id: rowId,
              checkboxId: data,
              code: props?.code ? props?.code : "",
              isEnabled: event.target.checked,
            });
          }}
          //isDisabled={true:false}
        />
      ) : (
        ""
      )}
      {type === "link" && (
        <Link
          to={value}
          className="flex text-sm font-normal text-slate-600   hover:translate-x-2 transition-all duration-200"
          target="_blank"
        >
          {data}
        </Link>
      )}
      {type === "text" && (
        <div className={"text-sm font-normal text-slate-600 " + isColor}>
          {data}
        </div>
      )}
      {type === "user" && (
        <>
          {!isTooltip ? (
            <div
              className="relative flex items-center cursor-pointer"
              onClick={()=>navigate(data?.link)}
            >
              {/* <div className="w-8 h-8 min-w-[32px] overflow-hidden rounded-full border border-slate-200">
              <Image src={image} alt={'data'} width={'100%'} height={'100%'} effect={'blur'} classes={'object-cover'}/>
            </div> */}
              <Indentification
                initial={initialsValue(shortName)}
                image={image}
                alt={shortName}
                fill={true}
                className={""}
                size={"sm"}
              />
              <div
                className={"text-sm font-normal text-slate-600 mx-2 " + isColor}
              >
                {data}
              </div>
            </div>
          ) : (
            <div
              className="relative inline-flex items-center cursor-pointer"
              data-tooltip-id={`userName${data?._id}`}
              data-tooltip-place="right"
              onClick={()=>navigate(data?.link)}
            >
              <Indentification
                initial={initialsValue(shortName)}
                image={image}
                alt={shortName}
                fill={true}
                className={""}
                size={"sm"}
              />
              <div
                className={"text-sm font-normal text-slate-600 mx-2 " + isColor}
              >
                {data?.name}
              </div>
              <Tooltip
                id={`userName${data?._id}`}
                className="!text-xs !bg-dark-teal-600 z-50 shadow-lg rounded py-3 px-6"
                render={() => toolTipData}
              />
            </div>
          )}
        </>
      )}
      {type === "text-tooltip" && (
        <div
          className="relative inline-flex items-center"
          data-tooltip-id={`statusTooltip${data?._id}`}
          data-tooltip-place="right"
        >
          <div className={"text-sm font-normal text-slate-600 mx-2 " + isColor}>
            {data?.name}
          </div>
          {isTooltip && (
            <Tooltip
              id={`statusTooltip${data?._id}`}
              className="!text-xs !bg-dark-teal-600 z-50 shadow-lg rounded py-3 px-6"
              render={() => toolTipData}
            />
          )}
        </div>
      )}
      {type === "image" && (
        <div className="w-8 h-8 min-w-[32px] overflow-hidden rounded-full border border-slate-200">
          <Image
            src={image}
            alt={"data"}
            width={"100%"}
            height={"100%"}
            effect={"blur"}
            classes={"object-cover"}
          />
        </div>
      )}

      {type === "textImage" && (
        <div className="w-8 h-8 overflow-hidden bg-slate-200 text-slate-600 rounded-full relative flex items-center justify-center border border-white font-semibold text-xs">
          {data}
        </div>
      )}

      {type === "status" && (
        <div
          className="flex items-center"
          data-tooltip-id={`textTooltip${data?._id}`}
          data-tooltip-place="right"
        >
          <div
            className={
              "text-[10px] font-medium leading-tight rounded py-1 px-2 " +
              status[statusType]
            }
          >
            {statusLabel}
          </div>
          {toolTipData && (
            <Tooltip
              id={`textTooltip${data?._id}`}
              className="!text-xs !bg-dark-teal-600 z-50 shadow-lg rounded py-3 px-6"
              render={() => toolTipData}
            />
          )}
        </div>
      )}
      {type === "button" && !props?.isExist && (
        <Button
          buttonLabel={props?.buttonLabel}
          buttonClasses={
            "!bg-amber-50 !text-amber-600  !text-xs !gap-1 py-1 px-2 !h-auto"
          }
          buttonLabelClasses={"!text-xs !leading-tight"}
          //buttonClasses={"!h-auto !p-0 !bg-transparent !text-slate-500 !text-xs"}
          buttonType={"button"}
          buttonIcon={"fa-regular " + props?.buttonIcon}
          buttonIconPosition={"left"}
          buttonHasLink={props?.buttonHasLink}
          functions={() => {
            getActionValue({
              button_type: "others",
              row_id: rowId,
              current_status: 1,
            });
          }}
        />
      )}
      {type === "action" && (
        <div className="flex items-center justify-end">
          {!isDropdoen && isDropdoen !== undefined ? (
            <Button
              buttonLabel={t("edit")}
              buttonClasses={"!text-xs"}
              //buttonClasses={"!h-auto !p-0 !bg-transparent !text-slate-500 !text-xs"}
              buttonType={"button"}
              buttonIcon={"fa-regular fa-pen-to-square"}
              buttonIconPosition={"left"}
              buttonHasLink={false}
              functions={() => {
                getActionValue({
                  button_type: "others",
                  row_id: rowId,
                  current_status: 1,
                });
              }}
            />
          ) : (
            <TableAction
              xPlacement={"right"}
              rowId={rowId}
              userIds={userIds}
              editUrl={editUrl}
              isStatusUpdate={isStatusUpdate}
              isDeleteEnabled={isDeleteEnabled}
              isStatusLabel={statusLabel}
              isStatusType={statusType}
              getActionValue={(obj) => getActionValue(obj)}
              isUser={isUser}
              detailsUrl={detailsUrl}
              othersAction={othersAction}
              data={data}
              loginAs={loginAs}
              isDropdoen={isDropdoen}
              extraData={extraData}
            />
          )}
        </div>
      )}
      {type === "report" && (
        <button
          type={"button"}
          className={
            "text-[10px] font-medium leading-tight rounded py-1 px-2 bg-green-100 text-green-600 flex gap-1 items-center"
          }
          onClick={(e) => getDownload(e)}
        >
          <i className={"fa-fw fa-regular fa-circle-info"}></i>
          <div className={"text-xs font-semibold whitespace-nowrap"}>
            <PDFDownloadLink
              document={
                <InformationReport
                  details={props?.info}
                  user={user}
                  heading={salesReportHeading}
                />
              }
              fileName="sales-info .pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? t("loading_document") : t("info")
              }
            </PDFDownloadLink>
          </div>
        </button>
      )}
      {type === "select" && (
        <Select
          xPlacement={"left"}
          dropdownClass={"!w-full"}
          dropdownData={[
            { _id: -1, label: "Select", value: 0 },
            ...[
              { _id: 1, label: "Yes", value: 1 },
              { _id: 2, label: "No", value: 2 },
            ],
          ]}
        />
      )}
      {type === "date" && (
        <Flatpickr
          ref={flatPicker}
          className="w-full h-10 rounded bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200"
          placeholder={""}
        />
      )}
      {type === "input" && <Input inputType={"text"} />}
      {type === "textarea" && <Textarea />}
    </td>
  );
};

export default Td;
