import React, { useState, useRef, useEffect, Fragment } from "react";
import toastr from "toastr";
import { useNavigate, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { postData } from "../../services/api";
import Input from "../form/Input";
import Button from "../form/Button";
import { setTitle } from "../../helpers/MetaTag";
import Select from "../form/Select";

const AddEditEducationPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  loadList = () => {},
  id,
}) => {
  if (id) {
    setTitle(
      "Succession Planning Software for your Business with Succession Now | Edit Education"
    );
  } else {
    setTitle(
      "Succession Planning Software for your Business with Succession Now | Add Education"
    );
  }
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const [name, setName] = useState("");
  const [point, setPoint] = useState();
  const [isManage, setIsManage] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  useEffect(() => {
    // Position details
    const loadDetails = async () => {
      try {
        const result = await postData("education/details", {
          id: id,
        });
        if (result.data) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, [id]);

  useEffect(() => {
    setName(details?.name);
    setPoint(details?.point);
  }, [details]);

  const onCancel = async (e) => {
    setIsCancel(true);
    if (id) {
      setName(details?.name);
      setPoint(details?.point);
    } else {
      setName("");
      setPoint("");
    }
    setIsCancel(false);
  };

  const onSubmit = async (e) => {
    setIsManage(true);
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "education/update";
        payload = {
          id: id,
          name: name,
          point: point,
        };
      } else {
        path = "education/create";
        payload = {
          name: name,
          point: point,
        };
      }

      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsManage(false);
        loadList();
        setIsOpen({ open: false, _id: null });
      } else {
        toastr.error(result.message);
        setIsManage(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsManage(false);
    }
  };
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 text-center"
                >
                  {id ? t("edit_education") : t("add_education")}
                </Dialog.Title>
                <div className="absolute top-2 right-2 z-10">
                  <Button
                    buttonLabelClasses=""
                    buttonType={"button"}
                    buttonIcon={"fa-light fa-times"}
                    buttonIconPosition={"left"}
                    buttonLabel={""}
                    buttonClasses={
                      "!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"
                    }
                    functions={() => {
                      setIsOpen({ _id: null, open: false });
                      setDetails("");
                      setName("");
                      setPoint("");
                    }}
                    buttonHasLink={false}
                  />
                </div>
                <div className="relative mt-4 border bg-white border-gray-200 p-4  rounded-xl">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12 flex flex-col">
                      <div className="space-y-4">
                        <div className="grid sm:grid-cols-1 gap-4">
                          <div className="relative w-full">
                            <Input
                              label={t("name")}
                              labelClasses={"!text-xs"}
                              inputType={"text"}
                              inputPlaceholder={t("enter_name")}
                              inputValue={name}
                              setInput={setName}
                            />
                          </div>
                          <div className="relative w-full">
                            <Select
                              label={t("point")}
                              labelClasses={"!text-xs"}
                              xPlacement={"left"}
                              dropdownButtonClass={"!bg-white"}
                              selectedValue={point}
                              dropdownData={[
                                {
                                  _id: "",
                                  label: t("select_point"),
                                  value: "",
                                },
                                ...[
                                  {
                                    _id: 1,
                                    label: "1 " + t("point"),
                                    value: 1,
                                  },
                                  {
                                    _id: 2,
                                    label: "2 " + t("points"),
                                    value: 2,
                                  },
                                  {
                                    _id: 3,
                                    label: "3 " + t("points"),
                                    value: 3,
                                  },
                                  {
                                    _id: 4,
                                    label: "4 " + t("points"),
                                    value: 4,
                                  },
                                  {
                                    _id: 5,
                                    label: "5 " + t("points"),
                                    value: 5,
                                  },
                                ],
                              ]}
                              getSelectedValue={(e) => setPoint(e.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-end gap-4 mt-5">
                        <Button
                          buttonClasses={
                            "bg-white border border-dark-teal-500 !text-dark-teal-500 ml-2"
                          }
                          buttonLabelClasses="text-dark-teal-500 "
                          buttonType={"button"}
                          buttonIcon={"fa-solid fa-xmark"}
                          buttonIconPosition={"left"}
                          buttonLabel={t("cancel")}
                          functions={() => {
                            setIsOpen({ _id: null, open: false });
                            setDetails("");
                            setName("");
                            setPoint("");
                          }}
                          buttonHasLink={false}
                        />
                        <Button
                          buttonClasses=""
                          buttonLabelClasses=""
                          buttonType={"button"}
                          buttonIcon={
                            isManage
                              ? "fa-light fa-spinner fa-spin"
                              : "fa-light fa-floppy-disk"
                          }
                          buttonIconPosition={"left"}
                          buttonLabel={t("save")}
                          functions={onSubmit}
                          buttonHasLink={false}
                          buttonDisabled={isManage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddEditEducationPopup;
