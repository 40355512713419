import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Input from "../components/form/Input";
import Image from "../components/elements/Image";
import ButtonFile from "../components/form/ButtonFile";
import Textarea from "../components/form/Textarea";
import toastr from "toastr";
import { useNavigate } from "react-router";

import { allowedImageExtensions } from "../helpers";
import { postData } from "../services/api";
import { useParams } from "react-router-dom";
import { languages } from "../helpers";

import { useTranslation } from "react-i18next";
const ManageFaq = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const [question, setQuestion] = useState({});
  const [answer, setAnswer] = useState({});
  //const [answer, setAnswer] = useState([''])
  const [isManage, setIsManage] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const { id } = useParams();
  const allLang = languages();
  const [activeTab, setActiveTab] = useState(0);
  if (id) {
    setTitle("Succession Planning Software for your Business with Succession Now | Edit FAQ");
  } else {
    setTitle("Succession Planning Software for your Business with Succession Now | Add FAQ");
  }

  useEffect(() => {
    // Faq details
    const loadDetails = async () => {
      try {
        const result = await postData("faq/details", {
          id: id,
        });
        if (result.data) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, []);

  useEffect(() => {
    if (Object.keys(details).length > 0) {
      setQuestion(details?.question);
      setAnswer(details?.answer);
    }
  }, [details]);

  const onCancel = async (e) => {
    return navigate("/faq");
  };

  const onSubmit = async (e) => {
    setIsManage(true);
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "faq/update";
        payload = {
          id: id,
          question: question,
          answer: answer,
        };
      } else {
        path = "faq/create";
        payload = {
          question: question,
          answer: answer,
        };
      }
      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsManage(false);
        return navigate("/faq");
      } else {
        toastr.error(result.message);
        setIsManage(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsManage(false);
    }
  };

  // const addRow = async () => {
  //   setAnswer(answer => [ ...answer, '' ])
  // }
  // const removeRow = async (index) => {
  //   setAnswer(answer => answer.filter((item, i) => i !== index));
  // }

  // const handelUpdate = async (val,index) => {
  //   setAnswer(answer => answer.map((item, i) => i !== index ? item : val));
  // }

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
       <div className="relative flex gap-3 items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4">
          <div className="text-2xl font-bold">{id ? t("edit_faq") : t("add_faq")}</div>
      </div>
      <div className="mb-4">
        <ul className="flex flex-wrap text-sm font-medium text-center border-b border-slate-200 gap-4" id="default-tab" data-tabs-toggle="#default-tab-content" role="tablist">
          {allLang &&
            allLang.length > 0 &&
            allLang.map((item, index) => (
              <li className="" role="presentation" key={index}>
                <button className={"inline-block px-4 py-2 rounded-t-lg text-sm font-medium text-center " + (index === activeTab ? " bg-teal-100 text-teal-600" : "text-gray-500")} id={"tab-" + item?.value} data-tabs-target={"#" + item?.value} type="button" role="tab" aria-controls={item?.value} aria-selected="false" onClick={() => setActiveTab(index)}>
                  {item?.label}
                </button>
              </li>
            ))}
        </ul>
      </div>
      <div id="default-tab-content">
        {allLang &&
          allLang.length > 0 &&
          allLang.map((item, index) => (
            <div className={index === activeTab ? "show" : "hidden"} id={item?.value} role="tabpanel" aria-labelledby={"tab-" + item?.value} key={index}>
              <div className="relative grid grid-cols-6 gap-4">
                <div className="col-span-6">
                <div className="space-y-4">
                        <div className="flex items-start gap-4">
                          <div className="relative w-full">
                            <Input
                              label={t("title")}
                              labelClasses={"!text-xs"}
                              inputType={"text"}
                              inputPlaceholder={t("enter_title")}
                              inputValue={question?.[item?.value]}
                              setInput={(val) =>
                                setQuestion({
                                  ...question,
                                  [item?.value]: val,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="flex items-end gap-4">
                          <div className="relative w-full">
                            <Textarea
                              label={t("additional_information")}
                              labelClasses={"!text-xs"}
                              inputType={"text"}
                              inputClasses={"!h-32"}
                              inputPlaceholder={t("enter_aditional_information")}
                              inputValue={answer?.[item?.value]}
                              setTextarea={
                                (val) =>
                                  setAnswer({
                                    ...answer,
                                    [item?.value]: val,
                                  })
                                //handelUpdate(val,key)
                              }
                            />
                          </div>
                        </div>
                        {/* { 
                            answer?.[item?.value]?.map((val, key) => { 
                              return(
                              <div className="flex items-end gap-4" key={key}>
                                <div className="relative w-full">
                                  <Textarea
                                    label={(key===0)?"Additional Information":""}
                                    labelClasses={"!text-xs"}
                                    inputType={"text"}
                                    inputClasses={"!h-32"}
                                    inputPlaceholder={"Enter Aditional Information"}
                                    inputValue={val}
                                    setTextarea={(val) => handelUpdate(val,key)}
                                  />
                                </div>
                                {
                                (key===0)?
                                <Button
                                  buttonIcon={"fa-light fa-plus"}
                                  buttonIconPosition={"left"}
                                  buttonClasses={"!h-10 !w-10 !min-w-[24px] !text-sm !p-0"}
                                  buttonType={"button"}
                                  functions={(e) => addRow()}
                                  buttonHasLink={false}
                                />
                                :<Button
                                  buttonIcon={"fa-light fa-minus"}
                                  buttonIconPosition={"left"}
                                  buttonClasses={"!h-10 !w-10 !min-w-[24px] !text-sm !p-0 !bg-rose-500"}
                                  buttonType={"button"}
                                  functions={(e) => removeRow(key)}
                                  buttonHasLink={false}
                                />
                                }
                              </div>
                              )
                            })
                          } */}
                      </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="flex items-center justify-end gap-4 mt-5">
          <Button buttonLabelClasses="" buttonType={"button"} buttonIcon={isCancel ? "fa-light fa-spinner fa-spin" : "fa-light fa-times"} buttonIconPosition={"left"} buttonLabel={t("cancel")} buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"} functions={onCancel} buttonHasLink={false} buttonDisabled={isCancel} />
          <Button buttonClasses="" buttonLabelClasses="" buttonType={"button"} buttonIcon={isManage ? "fa-light fa-spinner fa-spin" : "fa-light fa-floppy-disk"} buttonIconPosition={"left"} buttonLabel={t("save")} functions={onSubmit} buttonHasLink={false} buttonDisabled={isManage} />
        </div>
    </div>
  );
};

export default ManageFaq;
