import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "./form/Button";

import toastr from "toastr";

import { useNavigate } from "react-router-dom";
import { classNames } from "../helper/classNames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const BackConfirmationPopup = ({ isOpen, setIsOpen = () => {} }) => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const closeModal = () => {
    setIsOpen(false);
  };

  const handleBack = () => {
    navigate("/login-as?back=true");
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50  w-full" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto flex items-center justify-center">
            <div className="flex items-center justify-center text-center m-auto w-full md:p-0 p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-full bg-white rounded-3xl h-auto text-left shadow-2xl transform transition-all sm:my-8 p-0 sm:max-w-sm">
                  <div className={classNames("md:py-6 py-4 md:px-6 px-4")}>
                    <div className="flex flex-col justify-center items-center gap-2">
                      <div className="flex justify-start w-full items-center">
                        <i className="fa-regular fa-exclamation-triangle text-yellow-600 rounded-full mr-2 text-2xl"></i>
                        <div className="text-lg font-bold pl-1">
                          Back To Admin
                        </div>
                      </div>
                      <hr className="w-full border-1 border-slate-400"></hr>
                      <div className="pt-6 text-base text-gray-500 font-semibold text-center">
                        Are you sure about switching to the
                        <br />
                        <span className="text-dark-teal-500 font-bold">
                          Admin Panel
                        </span>
                        ?
                      </div>

                      <div className="flex items-center gap-4 mt-3">
                        <Button
                          buttonLabelClasses=""
                          buttonType={"button"}
                          buttonIcon={"fa-solid fa-xmark"}
                          buttonIconPosition={"left"}
                          buttonLabel={t("cancel")}
                          buttonClasses={
                            "!bg-white !border !border-dark-teal-500 !rounded-lg !text-dark-teal-500"
                          }
                          functions={closeModal}
                          buttonHasLink={false}
                        />
                        <Button
                          buttonClasses={
                            "!bg-dark-teal-500 !border-dark-teal-500 hover:!bg-dark-teal-500/80 !rounded-lg"
                          }
                          buttonLabelClasses=""
                          buttonType={"button"}
                          buttonIcon={"fa-solid fa-check"}
                          buttonIconPosition={"left"}
                          buttonLabel={t("Confirm")}
                          functions={handleBack}
                          buttonHasLink={false}
                        />
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
export default BackConfirmationPopup;
