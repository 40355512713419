import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../../../helpers";

import toastr from "toastr";
import { useParams } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setTitle } from "../../../helpers/MetaTag";
import Select from "../../form/Select";
import Search from "../../form/Search";
import Pagination from "../../Common/Pagination";
import { postData } from "../../../services/api";
import TransactionReport from "../../../pages/pdf/TransactionReport";
import Table from "../../elements/table/Table";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import "flatpickr/dist/l10n/default";


const AllCompanyTransction = () => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Transactions"
  );
  const { i18n, t } = useTranslation();
  const { id } = useParams();
  const flatPicker = useRef(null);
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");
  const [status, setStatus] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [subscriptions, setSubscriptions] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const [date, setDate] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [type, setType] = useState("");


  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    // Transactions List
    const loadTransactions = async () => {
      setLoading(true);
      try {
        const result = await postData("transaction/list", {
          keyword: keyword,
          limit: limit,
          offset: offset,
          sortQuery: sortQuery,
          status: status,
          companyId: id,
          date: date,
          isCommission: type,
        });
        if (result?.data) {
          setSubscriptions(
            result.data.map((value, key) => {
              let details;
              if (value.isCommission === 1) {
                let staffCode = "";
                if (value?.staff_code && value?.staff_code === "sales-staff") {
                  staffCode = t("sales_person");
                } else if (
                  value?.staff_code &&
                  value?.staff_code === "consultant"
                ) {
                  staffCode = t("consultant");
                } else if (
                  value?.staff_code &&
                  value?.staff_code === "appointment-setter"
                ) {
                  staffCode = t("appointment_setter");
                } else if (
                  value?.staff_code &&
                  value?.staff_code === "sales-admin"
                ) {
                  staffCode = t("sales_admin");
                }
                details =
                  t("commission_has_been_given_to") +
                  " " +
                  value?.staff_name +
                  " " +
                  t("as_a") +
                  " " +
                  staffCode;
              }
              return {
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 40,
                    type: "user",
                    isTooltip: true,
                    shortName: value?.company_name || "N / A",
                    toolTipData: (
                      <div className="flex flex-col items-start justify-center text-white gap-1">
                        {value?.company_details?.company_name && (
                          <h4 className="font-semibold">
                            <span className="text-slate-200">
                              Company Name:{" "}
                            </span>
                            {value?.company_details?.company_name}
                          </h4>
                        )}

                        {value?.company_details?.name && (
                          <h4 className="font-medium">
                            <span className="text-slate-200">
                              Administrator Name:{" "}
                            </span>
                            {value?.company_details?.name}
                          </h4>
                        )}

                        {value?.company_details?.email && (
                          <h4 className="font-medium">
                            <span className="text-slate-200">
                              Administrator Email:{" "}
                            </span>
                            {value?.company_details?.email}
                          </h4>
                        )}
                        {value?.company_details?.phone && (
                          <h4 className="font-medium">
                            <span className="text-slate-200">
                              Administrator Phone:{" "}
                            </span>
                            {value?.company_details?.phone}
                          </h4>
                        )}

                        {value?.subscription_details?._id && (
                          <>
                            <h4 className="font-bold ">
                              <span className="text-red-300">
                                Subscription Details:
                              </span>
                            </h4>
                            <div className="ml-2 w-full">
                              <h4 className="font-medium">
                                <span className="text-slate-200">
                                  Orginal Price:{" "}
                                </span>
                                {value?.subscription_details?.original_price}
                              </h4>
                              <h4 className="font-medium">
                                <span className="text-slate-200">
                                  Subscription Type:{" "}
                                </span>
                                {value?.subscription_details?.subscription_type}
                              </h4>
                            </div>
                          </>
                        )}
                      </div>
                    ),
                    data: {
                      _id: value?._id,
                      name: value?.company_name || "N/A",
                      link: `company/${value?._id}`,
                    },
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data: value?.invoice_number || "N/A",
                  },
                  {
                    _id: 3,
                    width: 40,
                    type: "text",
                    data: "$" + value?.price.toFixed(2),
                  },
                  {
                    _id: 4,
                    width: 15,
                    type: "text",
                    data:
                      value && value?.start_date
                        ? moment(new Date(value?.start_date)).format(
                            "MMM Do YYYY"
                          )
                        : "N/A",
                  },
                  {
                    _id: 5,
                    width: 20,
                    type: "text",
                    data:
                      value && value?.end_date
                        ? moment(new Date(value?.end_date)).format(
                            "MMM Do YYYY"
                          )
                        : "N/A",
                  },
                  {
                    _id: 6,
                    width: 40,
                    type: "text",
                    data:
                      value.isCommission === 1
                        ? details
                        : value?.purpose
                        ? value?.purpose
                        : "N/A",
                  },
                  {
                    _id: 7,
                    width: 40,
                    type: "text",
                    data: value?.email ? value?.email : "N/A",
                  },
                  {
                    _id: 8,
                    width: 40,
                    type: "text",
                    data: value?.name ? value?.name : "N/A",
                  },
                  {
                    _id: 9,
                    width: 15,
                    type: "status",
                    statusLabel:
                      value?.status && value?.status === 1
                        ? t("active")
                        : t("expire"),
                    statusType:
                      value?.status && value?.status === 1
                        ? "success"
                        : "warning",
                  },
                  // { _id:6, width:10, type:'action' }
                ],
              };
            })
          );
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadTransactions();
  }, [keyword, limit, offset, sortQuery, status, id, date, type]);

  useEffect(() => {
    // Transactions Report List
    const loadReportData = async () => {
      setLoading(true);
      try {
        const result = await postData("transaction/list", {
          keyword: keyword,
          sortQuery: sortQuery,
          status: status,
          subscription_id: id,
        });
        if (result.data) {
          setTransactions(
            result.data.map((value, key) => {
              let details;
              if (value.isCommission === 1) {
                let staffCode = "";
                if (value?.staff_code && value?.staff_code === "sales-staff") {
                  staffCode = t("sales_person");
                } else if (
                  value?.staff_code &&
                  value?.staff_code === "consultant"
                ) {
                  staffCode = t("consultant");
                } else if (
                  value?.staff_code &&
                  value?.staff_code === "appointment-setter"
                ) {
                  staffCode = t("appointment_setter");
                } else if (
                  value?.staff_code &&
                  value?.staff_code === "sales-admin"
                ) {
                  staffCode = t("sales_admin");
                }
                details =
                  t("commission_has_been_given_to") +
                  " " +
                  value?.staff_name +
                  " " +
                  t("as_a") +
                  " " +
                  staffCode;
              }
              return {
                ...value,
                details: details,
              };
            })
          );
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadReportData();
  }, [keyword, sortQuery, status, id]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  };

  const header = [
    {
      name: t("invoice"),
    },
    {
      name: t("price"),
    },
    {
      name: t("start_date"),
    },
    {
      name: t("end_date"),
    },
    {
      name: t("purpose"),
    },
    {
      name: t("email"),
    },
    {
      name: t("name"),
    },
    {
      name: t("status"),
    },
  ];

  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      name: t("company"),
      value: "company_name",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 2,
      width: 15,
      name: t("invoice"),
      value: "invoice",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 3,
      width: 40,
      name: t("price"),
      value: "price",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 4,
      width: 15,
      name: t("start_date"),
      value: "start_date",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 5,
      width: 20,
      name: t("end_date"),
      value: "end_date",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 6,
      width: 40,
      name: t("purpose"),
      value: "purpose",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 7,
      width: 40,
      name: t("email"),
      value: "email",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 8,
      width: 40,
      name: t("name"),
      value: "name",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 9,
      width: 15,
      name: t("status"),
      value: "status",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    // {_id:6, width:10, name:'Action',value:'', align:'right', isSort:false, isFilter:false},
  ];

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  const handleReady = (selectedDates, dateStr, instance) => {
    const clearButton = document.createElement("div");
    clearButton.className = "clear-button";
    clearButton.textContent = "Clear";
    clearButton.addEventListener("click", () => {
      instance.clear();
    });
    instance.calendarContainer.appendChild(clearButton);
  };

  const handleClear = () => {
    setDate(""); // Clear date state
    setSelectedDates([]); // Clear selectedDates state
    flatPicker.current.flatpickr.clear(); // Reset Flatpickr input
  };

  return (
    <div className="rounded-xl">
      <div className="relative flex gap-3 items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-2xl font-bold">{t("transactions")}</div>
        <div className="sm:ml-auto flex justify-end items-center">
          <button
            type={"button"}
            className={
              "flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 cursor-pointer"
            }
            onClick={(e) => getDownload(e)}
          >
            <i class="fa-regular fa-file-export"></i>
            <div className={"text-xs sm:text-sm font-medium whitespace-nowrap"}>
              <PDFDownloadLink
                document={
                  <TransactionReport
                    records={transactions}
                    header={header}
                    user={user}
                  />
                }
                fileName="tranasction.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? t("loading_document") : t("export_data")
                }
              </PDFDownloadLink>
            </div>
          </button>
        </div>
      </div>
      <div className="relative flex gap-3 items-center mb-3 flex-wrap">
        <div className="relative">
          <Select
            xPlacement={"left"}
            dropdownButtonClass={"!bg-white"}
            selectedValue={limit}
            dropdownClass={"w-auto"}
            dropdownData={[
              { _id: 1000000, label: t("all_items"), value: 1000000 },
              ...[
                { _id: 10, label: "10 " + t("items"), value: 10 },
                { _id: 20, label: "20 " + t("items"), value: 20 },
                { _id: 30, label: "30 " + t("items"), value: 30 },
                { _id: 50, label: "50 " + t("items"), value: 50 },
                { _id: 100, label: "100 " + t("items"), value: 100 },
              ],
            ]}
            getSelectedValue={(e) => setLimit(e.value)}
          />
        </div>
        <div className="relative w-48">
          <Search placeholder={t("search")} setInput={setKeyword} />
        </div>
        <div className="relative w-48">
          <Select
            xPlacement={"right"}
            dropdownButtonClass={"!bg-white"}
            transitionClass={"!w-auto"}
            dropdownClass={"w-48"}
            selectedValue={status}
            dropdownData={[
              { _id: "", label: t("select_status") },
              { _id: 1, label: t("active"), value: 1 },
              { _id: 2, label: t("expire"), value: 2 },
            ]}
            getSelectedValue={(e) => setStatus(e.value)}
          />
        </div>
        <div className="relative">
        </div>
        <div className="relative">
          <div className="min-w-[192px] max-w-fit h-10 flex items-center rounded-md bg-transparent border border-slate-200  !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200 overflow-hidden">
            <Flatpickr
              ref={flatPicker}
              className="px-3  text-[13px] text-slate-500 font-medium whitespace-nowrap truncate border-0 h-full"
              placeholder={t("select_date")}
              value={date}
              options={{
                mode: "range",
                dateFormat: "Y-m-d",
                onClose: (selectedDates) => {
                  setSelectedDates(selectedDates);
                },
              }}
              onChange={(selectedDates, dateStr, instance) => {
                setDate(dateStr);
              }}
              onReady={handleReady}
            />
            {date && (
              <button
                type="button"
                onClick={handleClear}
                className="h-full p-2 text-[16px] text-slate-400 font-medium"
              >
                <i className="fa-solid fa-xmark "></i>
              </button>
            )}
          </div>
        </div>
        <div className="relative w-48">
          <Select
            xPlacement={"left"}
            dropdownButtonClass={"!bg-white"}
            selectedValue={type}
            dropdownClass={"w-48"}
            dropdownData={[
              { _id: "", label: t("Select Type") },
              { _id: 1, label: "Commission", value: 1 },
              { _id: 2, label: "Subscription", value: 2 },
            ]}
            getSelectedValue={(e) => setType(e.value)}
          />
        </div>
      </div>

      <div className="w-full bg-white rounded-md shadow">
        <Table
          tableData={subscriptions}
          tableHeadData={tableHeadData}
          totalRecord={totalDataCount}
          loading={loading}
          getSortValue={setSortValue}
          getSortKey={setSortKey}
          detailsUrl={"/transactions"}
          isDraggable={false}
          tableClasses={"!overflow-[inherit]"}
        />
      </div>

      {noOfPage > 1 && (
        <Pagination
          handlePageClick={handlePageClick}
          noOfPage={noOfPage}
          count={totalDataCount}
          startDataCount={startDataCount}
          endDataCount={endDataCount}
        />
      )}
    </div>
  );
};

export default AllCompanyTransction;
