import React, { useEffect, useState } from "react";
import Table from "../../components/elements/table/Table";
import Button from "../../components/form/Button";
import Search from "../../components/form/Search";
import toastr from "toastr";
import moment from "moment";
import { postData } from "../../services/api";

import ReactPaginate from "react-paginate";
import { setTitle } from "../../helpers/MetaTag";
import { reorder } from "../../helpers";
import Select from "../../components/form/Select";

import { useTranslation } from "react-i18next";
import Pagination from "../../components/Common/Pagination";

export default function BoardEffectiveness({}) {
  setTitle("Succession Planning Software for your Business with Succession Now | Board Effectiveness");
  const { i18n, t } = useTranslation();
  const [id, setId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [clientData, setClientData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [actionValue, setActionValue] = useState({});
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");
  const [status, setStatus] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount,setStartDataCount] = useState(0);
  const [endDataCount,setEndDataCount] = useState(0);

  const tableHeadData = [
    { _id: 1, width: 40, name: t("title"), value: "name", align: "left", isSort: true, isFilter: false },
    { _id: 2, width: 20, name: t("answer_field_type"), value: "file_type", align: "left", isSort: false, isFilter: false },
    { _id: 3, width: 20, name: t("created_date"), value: "created_at", align: "left", isSort: false, isFilter: false },
    { _id: 4, width: 10, name: t("status"), value: "status", align: "left", isShort: false, isFilter: false },
    { _id: 5, width: 10, name: t("action"), value: "action", align: "right", isShort: false, isFilter: false },
  ];

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    const loadDetails = async () => {
      setLoading(true);
      const result = await postData("employee-rating-option/list", {
        keyword: keyword,
        limit: limit,
        offset: offset,
        sortQuery: sortQuery,
        status: status,
        type: "boardmember"
      });
      if (result.data) {
        setClientData(
          result.data.map((value) => {
            return {
              _id: value?._id,
              rowData: [
                { _id: 1, width: 40, type: "text", data: value?.question?.name },
                { _id: 2, width: 20, type: "text", data: (value?.question?.fieldType)?capitalizeFirstLetter(value?.question?.fieldType):'' },
                { _id: 3, width: 20, type: "text", data: value && value?.created_at ? moment(new Date(value?.created_at)).format("Do MMM YYYY") : "N/A" },
                {
                  _id: 4,
                  width: 10,
                  type: "status",
                  statusLabel: value?.status && value?.status === 1 ? t("active") : t("deactivated"),
                  statusType: value?.status && value?.status === 1 ? "success" : "warning",
                },
                {
                  _id: 5,
                  width: 10,
                  type: "action",
                  statusLabel: value?.status && value?.status === 1 ? t("active") : t("deactivated"),
                  statusType: value?.status && value?.status === 1 ? "success" : "warning",
                },
              ],
            };
          })
        );

        setTotalDataCount(result.count);
        setLoading(false);
      }
    };
    loadDetails();
  }, [keyword, limit, offset, sortQuery, status, isUpdate]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number((totalDataCount>0)?1:0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem)
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem)
  };

  const handleOnDragEnd = async (result) => {
    const items = reorder(clientData, result.source.index, result.destination.index);
    setClientData(items);

    const res = await postData("", {
      items: items,
    });
    if (res.status && res.status === 200) {
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  //status and delete
  useEffect(() => {
    if (actionValue && actionValue.button_type === "status") {
      const loadStatusChange = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("employee-rating-option/status-change", {
            id: actionValue.row_id,
            status: actionValue.current_status,
          });
          if (statusData.data) {
            setClientData(
              clientData.map((item) => {
                if (item?._id === statusData.data?._id) {
                  return {
                    ...item,
                    status: statusData.data?.status,
                  };
                } else {
                  return item;
                }
              })
            );
            setIsUpdate(true);
            toastr.success(statusData.message);
          } else {
            toastr.error(statusData.message);
          }
        } catch (err) {
          toastr.error(err.message);
        }
      };
      loadStatusChange();
    } else if (actionValue?.row_id && actionValue?.button_type === "delete") {
      const deleteClient = async () => {
        const statusData = await postData("employee-rating-option/delete", {
          id: actionValue?.row_id,
        });
        if (statusData.status === 200) {
          setClientData((clientData) => clientData.filter((item) => item._id !== actionValue.row_id));
          toastr.success(statusData.message);
        } else {
          toastr.error(statusData.message);
        }
      };
      deleteClient();
    }
  }, [actionValue]);

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex justify-between items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-2xl font-bold">{t("board_effectiveness")}</div>
        <div className="sm:ml-auto flex justify-end items-center">
          <Button buttonType={"button"} buttonIcon={"fa-light fa-plus"} buttonIconPosition={"left"} buttonLabel={t("add")} buttonHasLink={true} buttonLink={"/add-board-effectiveness"} />
        </div>
      </div>
      <div className="relative flex gap-3 items-center mb-3 flex-wrap">
        <div className="relative">
          <Select
            xPlacement={"left"}
            dropdownButtonClass={"!bg-white"}
            selectedValue={limit}
            dropdownClass={"w-auto"}
            dropdownData={[
              { _id: 1000000, label: t("all_items"), value: 1000000 },
              ...[
                { _id: 10, label: "10 " + t("items"), value: 10 },
                { _id: 20, label: "20 " + t("items"), value: 20 },
                { _id: 30, label: "30 " + t("items"), value: 30 },
                { _id: 50, label: "50 " + t("items"), value: 50 },
                { _id: 100, label: "100 " + t("items"), value: 100 },
              ],
            ]}
            getSelectedValue={(e) => setLimit(e.value)}
          />
        </div>
        <div className="relative w-48">
            <Search placeholder={t("search")} setInput={setKeyword} />
          </div>
          <div className="relative w-48">
            <Select
              xPlacement={"right"}
              dropdownButtonClass={"!bg-white"}
              transitionClass={"!w-auto"}
              dropdownClass={"w-48"}
              selectedValue={status}
              dropdownData={[
                { _id: "", label: t("select") },
                { _id: 1, label: t("active"), value: 1 },
                { _id: 2, label: t("deactivated"), value: 2 },
              ]}
              getSelectedValue={(e) => setStatus(e.value)}
            />
          </div>
      </div>

      <div className="w-full bg-white rounded-md shadow overflow-hidden">
        <Table tableData={clientData} tableHeadData={tableHeadData} loading={loading} totalRecord={totalDataCount} getSortValue={setSortValue} getSortKey={setSortKey} editUrl={"/edit-board-effectiveness"} isDraggable={false} isDeleteEnabled isStatusUpdate={true} getActionValue={(obj) => setActionValue(obj)} />
      </div>
      {noOfPage > 1 ? (
        <Pagination handlePageClick={handlePageClick} noOfPage={noOfPage} startDataCount={startDataCount} endDataCount={endDataCount} count={totalDataCount}/>
      ):(
        <div className="flex justify-center sm:justify-between my-4 text-sm text-black text-center sm:text-left">
          {(totalDataCount > 0) ? <div><p>{t('showing')}: {startDataCount} to {endDataCount} of {totalDataCount}</p></div>:''}          
        </div>
      )}
    </div>
  );
}
