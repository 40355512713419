import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import { postData } from "../services/api";
import Searching from "../components/Searching";
import NoDataFound from "../components/NoDataFound";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { PDFDownloadLink, Document } from "@react-pdf/renderer";
import SuccessionPlanReport from "./pdf/SuccessionPlanReport";
import moment from "moment";
import Select from "../components/form/Select";
import DropdownCheckbox from "../components/form/DropdownCheckbox";
import { API_URL } from "../config/host";
import OptionRatingHistoryReport from "./pdf/OptionRatingHistoryReport";

import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import CountedCard from "../components/section/countedCard/CountedCard";
import TableSkeleton from "../components/loader/TableSkeleton";

const BoardStrengthHistory = () => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Board Strength History"
  );
  const { i18n, t } = useTranslation();
  const user = useSelector((state) => state.auth?.user);
  const role = localStorage.getItem("role");
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(1);
  const [sortQuery, setSortQuery] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [positions, setPositions] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const [histories, setHistories] = useState([]);
  const [reatingOptions, setReatingOptions] = useState([]);

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    async function employeeRatingOptionList() {
      try {
        const result = await postData("employee-rating-option/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          limit: 50,
          type: "boardmember",
        });
        if (result.data) {
          setReatingOptions(result.data);
        } else {
          console.log("Employee rating message", result.message);
        }
      } catch (error) {
        console.log("Error employee rating catch", error.message);
      }
    }
    employeeRatingOptionList();
  }, []);

  const loadSuccessors = async (SelectedPosition = []) => {
    setLoading(true);
    try {
      const result = await postData("plan-history/groupby-list", {
        limit: limit,
        offset: offset,
        position: SelectedPosition,
        type: "boardmember",
      });
      if (result.data) {
        const uniqueData = result?.data?.map((data) => ({
          ...data,
          noOfServey: data?.entries?.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.user_id === item.user_id)
          ),
        }));
        setHistories(uniqueData);
      }
      setTotalDataCount(result.data.length);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    }
  };

  useEffect(() => {
    let SelectedPosition = [];
    // eslint-disable-next-line array-callback-return
    if (positions) {
      positions.map((usr) => {
        if (usr.isEnabled) {
          SelectedPosition.push(usr._id);
        }
      });
    }

    if (SelectedPosition.length > 0) {
      loadSuccessors(SelectedPosition);
    } else {
      loadSuccessors();
    }
  }, [positions]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  };

  const getDownload = async (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">
          {t("board_strength_history") + " - " + user?.company_name}
        </div>
      </div>
      <div className="relative flex gap-5 items-center mb-4">
        <div className="relative">
          <Select
            xPlacement={"left"}
            dropdownButtonClass={"!bg-white"}
            selectedValue={limit}
            dropdownData={[
              { _id: 1000000, label: "All Items", value: 1000000 },
              ...[
                { _id: 10, label: "10 Items", value: 10 },
                { _id: 20, label: "20 Items", value: 20 },
                { _id: 30, label: "30 Items", value: 30 },
                { _id: 50, label: "50 Items", value: 50 },
                { _id: 100, label: "100 Items", value: 100 },
              ],
            ]}
            getSelectedValue={(e) => setLimit(e.value)}
          />
        </div>
        {role && role === "customer-admin" && (
          <CountedCard type="boardmember" reatingOptions={reatingOptions} />
        )}
        {/* <div className="relative">
          {
            (role && role === 'customer-admin') && 
            <div className="relative z-20">
              <DropdownCheckbox data={positions} functions={positionSelect} />
            </div>
          }
        </div> */}
      </div>
      <div className="w-full overflow-auto scroll-smooth scrollbar bg-white rounded-md shadow min-h-[480px]">
        <table className="min-w-max w-full table-auto">
          <thead>
            <tr className="text-white font-bold text-xs leading-none border-b border-slate-200">
              {/* <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tl-md">
                {t("created_by")}
              </th> */}
              <th className="py-4 px-2 text-center max-w-[240px] sticky -top-6 z-10 bg-slate-900">
                {t("total_responses")}
              </th>
              <th className="py-4 px-2 text-center max-w-[240px] sticky -top-6 z-10 bg-slate-900">
                {t("total_invitations_sent")}
              </th>
              {/* <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">
                {t("created")}
              </th> */}
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tr-md">
                {t("report")}
              </th>
            </tr>
          </thead>
          <tbody className="text-slate-700 text-sm font-light bg-white">
            {totalDataCount && totalDataCount > 0 ? (
              histories.map((item, index) => {
                return (
                  <tr className="border-b border-slate-200" key={index}>
                    {/* <td className="py-3 px-2 text-left max-w-[240px]">
                      <div className="block">
                        <div className="text-xs text-slate-400">
                          {item?.creator}
                        </div>
                      </div>
                    </td> */}
                    <td className="py-3 px-2 text-center max-w-[240px]">
                      <div
                        className="inline-block"
                        data-tooltip-id={`servey${index}`}
                        data-tooltip-place="right"
                      >
                        {Array.isArray(item?.noOfServey) &&
                          item?.noOfServey.length > 0 && (
                            <>
                              <div className="text-xs text-slate-800">
                                {item?.noOfServey.length}
                              </div>
                              <Tooltip
                                id={`servey${index}`}
                                className="!text-xs !bg-dark-teal-600 z-50 rounded py-3 px-6"
                                render={() => (
                                  <div className="">
                                    <span className="text-slate-200">
                                      Name:
                                    </span>{" "}
                                    {item?.noOfServey.map((value, ix) => (
                                      <h4 className="font-medium" key={ix}>
                                        {value?.name}
                                      </h4>
                                    ))}
                                  </div>
                                )}
                              />
                            </>
                          )}
                      </div>
                    </td>
                    <td className="py-3 px-2 text-center max-w-[240px]">
                      {item?.totalCount}
                    </td>
                    {/* <td className="py-3 px-2 text-left max-w-[240px]">
                      {item && item?.created_at
                        ? moment(new Date(item?.created_at)).format("lll")
                        : "N/A"}
                    </td> */}
                    <td className="py-3 px-2 text-center max-w-[240px]">
                      <button
                        type={"button"}
                        className={
                          "flex justify-center items-center gap-2 bg-transparent text-teal-500 rounded text-sm h-8 py-0 px-2 transition-all duration-200 cursor-pointer "
                        }
                        onClick={(e) => getDownload(e)}
                      >
                        <i className={"fa-fw fa-regular fa-download"}></i>
                        <div
                          className={"text-xs font-semibold whitespace-nowrap"}
                        >
                          <PDFDownloadLink
                            document={
                              <Document>
                                <OptionRatingHistoryReport
                                  history={item?.entries}
                                  reatingOptions={reatingOptions}
                                  title={"Board Strength Reports"}
                                  headingTitle={"Board Strength"}
                                  user={user}
                                  companyLogo={API_URL + user?.image}
                                />
                              </Document>
                            }
                            fileName="scorecard.pdf"
                          >
                            {({ blob, url, loading, error }) =>
                              loading
                                ? t("loading_document")
                                : t("download_succession_plan")
                            }
                          </PDFDownloadLink>
                        </div>
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className="border-b border-slate-200">
                <td className="py-3 px-2 text-left" colSpan={4}>
                  {loading ? (
                     <TableSkeleton tableImage={false} />
                  ) : (
                    <NoDataFound label={t("no_data_found")} />
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {noOfPage > 1 ? (
        <div className="flex justify-center sm:justify-between my-4">
          <div>
            <p>
              {t("showing")}: {startDataCount} to {endDataCount} of{" "}
              {totalDataCount}
            </p>
          </div>
          <ReactPaginate
            breakLabel="..."
            breakClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            nextLabel={<i className="fa-regular fa-fw fa-chevron-right"></i>}
            nextClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={noOfPage}
            containerClassName="flex justify-center items-center text-slate-80 text-sm font-medium mt-8 lg:mt-0 space-x-2"
            activeClassName="hover:bg-teal-500 bg-teal-500 text-white"
            pageLinkClassName="flex justify-center items-center w-full h-full"
            previousLabel={<i className="fa-regular fa-fw fa-chevron-left"></i>}
            pageClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            previousClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            renderOnZeroPageCount={null}
          />
        </div>
      ) : (
        <div className="flex justify-center sm:justify-between my-4">
          {totalDataCount > 0 ? (
            <div>
              <p>
                {t("showing")}: {startDataCount} to {endDataCount} of{" "}
                {totalDataCount}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};

export default BoardStrengthHistory;
